import { Action } from '@ngrx/store';
import { GenSettingModel,GenSettingEnum } from './gen-setting.model';

const INSERT = 'insert2GenSettingmodel';


export class GenSettingAction implements Action {
    readonly type = INSERT;
    constructor(public payload: GenSettingModel) { }
}


export function  SendLoginSuccess(store:any) {
  store.dispatch(new GenSettingAction({typeof: 'GenSettingModel', action:GenSettingEnum.loginSuccessful}));
}
export function  SendLogout(store:any) {
  store.dispatch(new GenSettingAction({typeof: 'GenSettingModel', action:GenSettingEnum.logout}));
}
export function  SendDisableSubscription(store:any) {
  store.dispatch(new GenSettingAction({typeof: 'GenSettingModel', action:GenSettingEnum.disableSubscription}));
}
export function  SendEnableSubscription(store:any) {
  store.dispatch(new GenSettingAction({typeof: 'GenSettingModel', action:GenSettingEnum.enableSubscription}));
}
export function  SendUserHaveCredit(store:any) {
  store.dispatch(new GenSettingAction({typeof: 'GenSettingModel', action:GenSettingEnum.userHaveCredit}));
}


export const  LoginSuccessful = (data:GenSettingModel) => data.action == GenSettingEnum.loginSuccessful;
export const  UserHaveCredit = (data:GenSettingModel) => data.action == GenSettingEnum.userHaveCredit;


export function GenSettingReducer(State = {}, action: GenSettingAction) {
      return action.type==INSERT? action.payload: {};
};
