import { Component, OnInit, OnDestroy, Input,Output, EventEmitter} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
// import { CommonListAnimations} from '../../shared/animations/mobile-animations';
import {InsertCardsDataUnit, MobileDrawCardDataUnit,MobileDrawCardEnum } from '../../store/cards.model';
import {trigger,state, style,transition,animate,query,stagger,keyframes} from '@angular/animations';
import { globalVars,imageDims, getImage, calcCardSpeed} from '../../../services/global.service';
import {MobileCountCardAction, MobileCardsDrawnAction, MobileCardsPeerErase,MobileCardBadgeBothAction,
        MobileCardBadgeCardAction,MobileCardBadgeBadgeAction } from '../../store/cards.action.reducer';
import {MobileSendKeyRoute} from '../../store/bottom.action.reducer';
import { FlashButtons, FilterType, START_FLASH,  FADEOUT, FADEIN,nFlash} from '../../../services/common.service';
import { DealerService, CardCount } from '../../../services/deck.service';
// import { MobileDrawCardDataUnit, MobileCountCardDataUnit, MobileDrawCardEnum} from '../../store/cards.model';
import {   DEALER_DISPLAY_ID} from '../../../store/switchcards.model';
const DROP='drop-cards';
@Component({
  selector: 'mobile-base-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['../../styles.scss'],
  animations : [
    // CommonListAnimations.mcards,
    // CommonListAnimations.flash,

    trigger('flash', [
      transition(`* => ${FADEIN}`, [
        style({ opacity: 0 }),
        animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
      ]),
      transition(`* => ${FADEOUT}`, [
        animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
      ])
    ]),
    trigger('blist', [
          transition('* => *', [
             // Initially the all cards are not visible
             query(':enter', style({ opacity: 0 }), { optional: true }),
               query(':enter', stagger('300ms', [
                 animate('200ms ease-in', keyframes([
                   style({ opacity:  0, transform: 'translateX(-10px)', offset: 0 }),
                   style({ opacity:  0.5, transform: 'translateX(10px)', offset: 0.3 }),
                   style({ opacity:  1, transform: 'translateX(0)', offset: 1 }),
                 ]))]), { optional:  true }),

             // query(':leave', stagger('300ms', [
             //   animate('1s ease-in', keyframes([
             //     style({   opacity:  1, transform: 'translateX(50px)', offset: 0 }),
             //     style({  opacity: .2, transform: 'translateX(300px)', offset: 0.7}),
             //     style({  opacity: 0, transform: 'translateX(400px)', offset: 1}),
             //
             //   ]))]), { optional:  true }),
             ])
     ]),
    trigger('list', [
          state('in', style({
                 opacity: 1,
                 transform: 'translateX(0)'
               })), // end of state
          transition('void => *', [
             style({
                     opacity: 0,
                     transform: 'translateX(-100px)'
                   }),
                      animate(300)
                        ]),
          // transition(
          //     '* => void', [
          //         animate(500, style({
          //              transform: 'translateX(100px)',
          //              //transform: 'rotate(180deg)',
          //               opacity: 0
          //         }))
          // ])
    ]),


  ]

})

export class MobileBaseCardsComponent implements OnInit, OnDestroy  {

subscriptions=[];
config = [
  {enabled: false, list:[]},
  {enabled: false, list:[]},
  {enabled: false, list:[]},
  {enabled: false, list:[]}

];

badges=[];
cardsMargin='margin-right-';
savedList=[];
offset={height:0, width:0};
display=0;
vars:any;
marginLeft=0;
cardCount=0;
reduced={enabled:false, badge:'4l', top:0, left:0, cls:''};
nflash=new nFlash();
mobileDevice=false;

@Input('id') id: number;
@Input() label=''
@Input('mode') mode='flat';
@Output() event = new EventEmitter<{}>();
labelClass='';
ilocation={};
dealerBadgeMargin=0;
// bgClass="cards-makeup";
bgClass="";
image={width:0, height:0};
cols=0;

// bgClass="";

constructor(public dealerService: DealerService, public store: Store<any>) {  }

  ngOnInit():void {
    // endable the down arrow, disable up-arrow
    // MobileSendKeyRoute(this.store,[      {key:"switch"}    ]);

  this.cardsMargin += globalVars['netcount']? '4': '2';
  //console.log(`base-card, ngOnInit id:${this.id}`)
  this.vars = globalVars;
  //console.log(`vars[mobileDevice]: ${this.vars['mobileDevice']}`)
  this.marginLeft=globalVars['mode'] == 'license'? 5:0;
  this.mobileDevice=globalVars['mobileDevice'];
  const players=(id)=> id!=DEALER_DISPLAY_ID;

  if (!players(this.id)) {
    this.labelClass="dealer-label";
  }

  imageDims();
  const image = globalVars['image'];
  const dims=image.dims;
  this.image = {height: image.card.height, width:image.card.width};
  const offset = this.offset;
  offset.width = Math.floor(image.card.width/3);
  offset.height = Math.floor(image.card.height/3);
  this.cols = globalVars['image'].cols;

  this.subscriptions.push(
    this.store
      .select('mobileCard')
      .pipe(filter(event =>  event.typeof=== 'InsertCardsDataUnit'))
      .subscribe((data: InsertCardsDataUnit) => {

        // console.log(`id::${data.id}, this.id::${this.id}, msg:${data.msg}, cards:${JSON.stringify(data.cards)}`);

        if (data.id != this.id) {
          return;
        }
        if (data.msg == 'erase') {
          this.erase();
          return;
        }

      })
    );

    this.subscriptions.push(
            this.store
              .select('mobileCard')
              .pipe(filter(event=> event.typeof=== 'MobileDrawCardDataUnit' && this.id == event.id))
              .subscribe((data: MobileDrawCardDataUnit) => {
                if (data.id != this.id) return;
                if (data.type == MobileDrawCardEnum.dbadge) {
                  // this.requestBadge();
                  this.pbadges();
                  return;
                } else if (data.type == MobileDrawCardEnum.reset) {
                  // MobileCardsPeerErase(this.store, globalVars['npanels']-1);
                  this.erase();
                  return;

                } else { // first row of the cards to be managed the rest is displayted when first row are displayed

                      const cardsOut=globalVars['cardsOut'];
                      const cardsLeftInDeck = this.dealerService.cardsLeftInDeck();
                      const notEnoughCardsLeft = cardsLeftInDeck < cardsOut;
                      // console.log(`notEnoughCardsLeft:${notEnoughCardsLeft}, cardsLeftInDeck:${cardsLeftInDeck}`)
                      const maxCardsPercol = this.cols;
                      if (notEnoughCardsLeft || data['shuffle'] ) {
                          this.dealerService.shuffle();
                          this.event.emit('shuffled');
                          return;
                        // }
                      }
                      // console.log(`cards.comp cardsOut:${cardsOut}, ${JSON.stringify(image)}`)
                      let j=0;
                      let k=0;
                      const speed = globalVars['speed'];
                      const animation_speed = 200;
                      const lastcard = cardsOut - 1;
                      let   list = this.config[k].list;
                      this.config[k].enabled = true;
                      this.badges = [];
                      for(let i=0; i!= cardsOut; i++) {
                        setTimeout(() => {
                          const card = getImage(this.dealerService.singleCard(1));
                          const count = this.getcount(i==lastcard);
                          list.push(i);
                          // if ( globalVars['learning']) {
                          //   MobileCardBadgeBothAction (this.store, {img: card, badge: count}, i) ;
                          // } else {
                            // console.log(`sending the item`)
                            MobileCardBadgeCardAction (this.store, card, i);
                            this.badges.push(count);
                          // }
                          if (++j == this.cols) {
                            j = 0;
                            k++;
                            list = this.config[k].list;
                            this.config[k].enabled = true;
                          }
                          if (i==lastcard) {
                            setTimeout(() => {
                              this.event.emit('drawn');
                            }, 1000);
                          }
                        }, calcCardSpeed(speed, animation_speed, i+1));

                      }
                   }
                    // setTimeout(() => {
                    //   MobileSendUnFlash(this.store, 'all');
                    // }, 300);
              })
            );
    // this.calc();
    this.calBaseHeight();
  }
  cenabled(index) { return this.config[index].enabled;}
  getcount(last:any) {
    const mode = globalVars['mode'];
    if (['license', 'play'].includes(mode)) return '';
    let count = this.dealerService.getCount();
    if (globalVars['method'] == 'mit') {
      return count;
    }
    const includeReduceCount = mode.includes('Net') || mode.includes('net') || mode.includes('Continuous') || mode.includes('icense') || globalVars['netcount'];
    // console.log(`includeReduceCount:${includeReduceCount}, mode:${mode}`)
    if (includeReduceCount && last) {
       return `${count}/${this.dealerService.getReducedCount()}`;
    }
    return count;
  }

  flashit() {
    this.nflash.flash();
    this.labelClass="player-label";
  }

  unflash() {
    this.nflash.unflash();
    this.labelClass="player-label-none";
  }

  calBaseHeight() {
    const dims= globalVars['image'].dims;
    // const dims=image.dims;
    // this.image = {height: image.card.height, width:image.card.width};
    // const offset = this.offset;
    // offset.width = Math.floor(image.card.width/3);
    // offset.height = Math.floor(image.card.height/3);

    const factor=dims.includes('80') ? 13: 20;
    const base=dims.includes('80')? 40: 35;
    const leftMargin=dims.includes('80')?13:17;
    this.ilocation= {
      2: { top: factor, left: leftMargin},
      3: { top: factor, left: leftMargin+this.image.width+2},
      4: { top: factor*2, left: leftMargin},
      5: { top: factor*2, left: leftMargin+this.image.width+2},
      6: { top: factor*3, left: leftMargin},
      7: { top: factor*3, left: leftMargin+this.image.width+2},
      8: { top: factor*4, left: leftMargin},
      9: { top: factor*4, left: leftMargin+this.image.width+2},
      10: { top: factor*5, left: leftMargin},
      11: { top: factor*5, left: leftMargin+this.image.width+2},

    }
  }

  pbadges():void {

    // setTimeout(()=>{
    //   MobileCardsDrawnAction(this.store);
    // }, speed+500);
    const cardsOut=globalVars['cardsOut'];
    const lastcard = cardsOut - 1;
    const cspeed = 500;
    let speed = 0;
    for(let i=0; i!= cardsOut; i++) {
      setTimeout(() => {
        MobileCardBadgeBadgeAction(this.store, this.badges[i], i);
        if (i==lastcard) {
          setTimeout(() => {
            this.event.emit('badgeDrawn');
          }, 700);
        }

      }, speed);
      speed += cspeed;
    }
 }

  erase(setting=false) :void {
    let i=0;
    while(i < this.config.length) {
      this.config[i++] = {enabled:false, list:[]};
    }
    // alert(`before sending removed`)
    if (!setting) {
      setTimeout(() => {
        this.event.emit('removed');
      }, 50);
    }

  }


 leftMargin(index:any):any {
   // return this.width.base+index*this.width.length+ (index?this.width.extra*index:0);
   const image=globalVars['image'];
   const offset = this.offset;
   // 5 is margin between cards
   return (index?index*5:0) + (index*image.card.width) + offset.width;
 }


  ngOnDestroy():void {
     for (const elm of this.subscriptions)
          elm.unsubscribe();
   }

}
