
<div class="flex-column" style="position:relative; margin-left:{{vars['center-width']}}px;margin-top:10px;width:100%;" *ngIf="!die" >

  <anms-thumbs-timer  (timerEvent)="timerevent()"  *ngIf="displayCards" #thumbs_timer style="margin-left:100px">   </anms-thumbs-timer>
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"  (afterChange)="afterChange($event)" >
      <div ngxSlickItem class="slide" >
          <div flex="column" style="height:100%;">
               <p> Choose test duration in minutes: </p>
               <div class="flex" style="width:90%;margin-top:10px">

                    <ng-container *ngFor="let item of license.list">
                        <button mat-raised-button   style="min-width:{{license.dims.width}}px; min-height:{{license.dims.height}}px; margin-left:5px"
                        [disabled]="license.disabled[item]"
                        [color]="license.color[item]"
                        (click)="click(item)">{{item}}</button>
                    </ng-container>
               </div>
          </div>
      </div>
       <div ngxSlickItem class="slide" >

           <div  style="height:{{vars['main-height']}}px;width:{{vars['main-width']}}px" *ngIf="active.first">
                 <div  style="margin-top:5px" style="height:85%">
                      <anms-multicards (event)="eventHandler($event)" > </anms-multicards>
                 </div>

           </div>
       </div>

      <div ngxSlickItem class="slide">
              <div class="font15 certification">
                <p> {{header}} </p>
                <mat-list  class="flex-column-space-between">
                     <mat-list-item *ngFor="let rule of list" class="blue-font"> {{rule}} </mat-list-item>
                </mat-list>
                  <div *ngIf="imageEnabled" class="cert-image"  >
                    <img src="{{image}}" class="image">
                  </div>
              </div>
        </div>
   </ngx-slick-carousel>

   <anms-bottom-numbers (thumbsEvent)="tevent($event)" *ngIf="displayCards"
       style="position: absolute; left:{{number_panel.margin_left}}px; top:{{number_panel.margin_top}}px"> </anms-bottom-numbers>
</div>
<!-- <anms-bottom-numbers style="margin-left:{{numberLeftMargin}}px"> </anms-bottom-numbers> -->
