

<div style="margin-left:{{gvars['center-width']}}px"  *ngIf="!die" >
  <div class="mild-black inset-border" style="position: relative; width:{{tpage.width}}px; height:{{tpage.height}}px;margin-top:30px">
        <anms-thumbs-timer  #thumbs_timer style="position: absolute;left:{{thumbsLeft}}px; top:10px">   </anms-thumbs-timer>
        <anms-multicards (event)="eventHandler($event)" style="position:absolute; top: 40px"> </anms-multicards>
        <ng-container *ngIf="showNumbers else showArrow">
           <anms-bottom-numbers (thumbsEvent)="tevent($event)"
           style="position: absolute; left:{{buttonsMarginLeft}}px; top:{{tpage.height-150}}px"> </anms-bottom-numbers>
        </ng-container>
        <ng-template #showArrow>
          <button mat-button  (click)="draw()" [color]="'warn'" *ngIf="nextEnabled"
               style="position: absolute;left:{{tpage.width-120}}px; top:{{tpage.height-75}}px"> NEXT <mat-icon class="scale1p5" [color]="'warn'" > arrow_forward_ios </mat-icon> </button>
       </ng-template>
  </div>
</div>
