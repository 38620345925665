
    <div class="flex">
       <ng-container *ngIf="buttonEnabled">
          <ng-container *ngFor="let button of qbuttons.buttons">
            <button mat-mini-fab  (click)="clicked(button)" [color]="'accent'" style="margin-left:20px"> {{button}}</button>
          </ng-container>
      </ng-container>

   </div>
  <!-- <div class="flex"  style="margin-top:20px;" *ngIf="lesson" >
        <button mat-flat-button  (click)="clicked('dbackward')"
          [disabled]="false" [color]="'primary'" class="margin-left-c-buttons">  <<- </button>

          <button mat-flat-button  (click)="clicked('cards')"
             [disabled]="control.cards"  [color]="'warn'" class="margin-left-c-buttons">  cards  </button>

          <button mat-flat-button  (click)="clicked('speed')"
                   [disabled]="control.speed" [color]="'warn'"  class="margin-left-c-buttons">  speed  </button>

           <button mat-flat-button  (click)="clicked('speed')"
                    [disabled]="control.speed" [color]="'primary'"  class="margin-left-c-buttons">  continuous  </button>
  </div> -->

  <!-- <button mat-mini-fab   [@flash]="flashes[button].status" (click)="clicked(button)" [color]="'accent'" style="margin-left:20px"> {{button}}</button> -->
