
<div  style="margin-left:{{gvars['center-width']}}px; height:100%;" *ngIf="!die">
         <ngx-slick-carousel style="height:80%" class="carousel" #slickModal="slick-carousel" [config]="slideConfig"   (afterChange)="afterChange($event)" *ngIf="!die" >
             <div ngxSlickItem class="slide" >
                   <div style="margin-top:45px !important;">
                        <anms-online-betting > </anms-online-betting>
                   </div>
              </div>

             <div ngxSlickItem class="slide">
                 <div class="inset-border mild-black flex-column"  style="position:relative; margin-top:20px; width:{{tpage.width}}px; height:{{tpage.height}}px">
                          <mobile-dealer-cards-display [id]="3" label="Dealer" style="min-height:{{_imageDims['height']}}px; margin-left:20px"> </mobile-dealer-cards-display>
                         <div class="flex-row" style="margin-top:15px; margin-left:20px">
                               <ng-container *ngFor="let id of [2,1,0]">
                                   <div *ngIf="hands[id].enabled" style="min-width:{{_imageDims['width']*2+20}}px; min-height:{{_imageDims['height']}}px;margin-right:15px">
                                       <mobile-player-cards-display [id]="id" label="Hand({{id+1}}) ${{displayedBets[id]}}" [mode]='stack[id]' ></mobile-player-cards-display>
                                 </div>
                               </ng-container>
                         </div>
                         <button mat-button style="position:absolute; top: {{tpage.height-100}}px; left:{{matCardMarginLeft}}px; min-width:45px; min-height:30px;" >
                             <span style="font-size:20px">{{displayedBets[3]}} <mat-icon class="scale1p3" [color]="netColor">account_balance </mat-icon></span> </button>
                </div>
             </div>
           <div ngxSlickItem class="slide" >
               <div *ngIf="resultTableInit" class="cards-width">
                        <div class="mat-elevation-z8 margin-top-20px table-font" >

                            <mat-table  [dataSource]="resultTable">
                                  <ng-container  [matColumnDef]="col" *ngFor="let col of resultReport.columns">
                                        <mat-header-cell *matHeaderCellDef > {{ col }} </mat-header-cell>
                                       <mat-cell *matCellDef="let element"> {{element[col]}} </mat-cell>
                                  </ng-container>
                                  <mat-header-row *matHeaderRowDef="resultReport.columns"></mat-header-row>
                                  <mat-row *matRowDef="let row; columns: resultReport.columns;"></mat-row>

                            </mat-table>
                        </div>
                </div>
                <mat-paginator #paginator  [pageSizeOptions]="getOptions()" showFirstLastButtons  class="cards-width"></mat-paginator>

           </div>
         </ngx-slick-carousel>
         <div  style="height:20%; !important">
             <ngx-slick-carousel style="margin-top:30px;" class="carousel" #slickModalButtons="slick-carousel" [config]="slideConfig" (afterChange)="afterChangeButton($event)">
                <div ngxSlickItem class="slide">
                  <div class="ctrl-bottom-margin" >
                      <ng-container *ngFor="let elm of buttons['play']" >
                        <button  mat-fab [@flash]="flashes[elm].status"
                                 [color]="'accent'"
				                         style="margin-right:5px"
                                    (click)="clicked(elm)"> {{elm}}</button>
                        </ng-container>
                   </div>
                </div>
                <div ngxSlickItem class="slide">
                  <div class="ctrl-bottom-margin" >
                            <ng-container *ngFor="let elm of buttons['hand']" >
                              <button  mat-fab [@flash]="flashes[elm].status"
                                   class="gold-font"
				                           style="margin-right:5px"
                                  (click)="clicked(elm)"> {{elm}}</button>
                              </ng-container>
                  </div>
                </div>
                <div ngxSlickItem class="slide">
                  <div class="ctrl-bottom-margin">
                      <p> Insurance: </p>
                      <ng-container *ngFor="let elm of buttons['ins']" >
                        <button  mat-fab
                                   [@flash]="flashes[elm].status"
                                   [color]="'primary'"
				                           style="margin-right:5px"
                                    (click)="clicked(elm)"> {{elm}}</button>
                        </ng-container>
                  </div>
                </div>
             </ngx-slick-carousel>
          </div>
   </div>
   <!-- color="blue-grey-priam" -->

  <!-- <mat-icon *ngIf="signIconEnabled" class="scale1p2"> {{signIcon}} </mat-icon> <span style="font-size:18px">{{displayedBets[3]}} </span> -->
  <!-- <button  mat-fab [disabled]="disabledHand[elm]" [@flash]="flashes[elm].status" -->
