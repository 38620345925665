import { Insurance } from './insurance.service';
import {Split} from './split.service';
import { INSURANCE,  SPLIT, STAND, DOUBLE, HIT} from '../../store/switchcards.model';
const DRAW='Draw';


export class Arbiter {
  table = [];
  ptr = 0;
  did=-1;
  lastHand = -1;
  debug = false;
  iterateIndex = 0;
  insurance : Insurance;
  split: Split;
  preId=0;

  constructor() {
     this.insurance = new Insurance();
     this.split = new Split();
  }

  reset(info: string[]) {
    this.table = info.map((elm) => +elm);
    this.insurance.config(this.table);
    this.ptr = 0;
    this.iterateIndex = 0;
     //console.log(`Arbiter reset:${info}: table:${this.table}`);
    this.split.reset();
  }
  display(msg?: string) {
    console.log(`'${msg}' size:${this.table.length},table:${this.table},ptr:${this.ptr}, split:${this.split.str()}`);
  }
  realId(value) { return this.split.realId(value);}
  incr() {
    let ptr = this.ptr;
    if (++this.ptr === this.table.length) {
      this.ptr = 0;
    }
    if (this.ptr === this.did) {
      this.lastHand = ptr;
    }

  }
  resetPtr() {
    this.ptr = 0;
    this.table.splice(0,0,0);
   }

  merge() { // insurance merge
     //console.log(`before merge table:: ${JSON.stringify(this.table)}`);
      this.table = this.insurance.merge(this.table).slice();
     //console.log(`after merge table:: ${JSON.stringify(this.table)}`);
  }

  iterate() {
   let index = this.ptr + this.iterateIndex;
//console.log(`iterate: index:${index}, ptr: ${this.ptr}, iterIndex: ${this.iterateIndex}, length:${this.table.length}`);
   this.iterateIndex++;
   if (index === this.table.length) {
      this.iterateIndex = 0;
      index=0;
   }
   return this.table[index];
  }

  get(decision: string, bjack = false) {
//         console.log(`Arbiter state : ${JSON.stringify(this.table)} ptr:${this.ptr}`);
    switch ( decision ) {
      case DRAW : {
        // console.log(`Arbiter Draw : ${JSON.stringify(this.table)} ptr:${this.ptr}`);
        const rvalue = this.ptr;
        this.incr();
        return this.table[rvalue];
      }
    case INSURANCE: {
        this.incr();
        return this.table[this.ptr];
      }
      case SPLIT: {
        const id = this.table[this.ptr];
        if (this.split.length() === 0) {
          this.split.push(id);
        }
        const splitId = this.split.newId();
        const lastElem = this.split.lastElem();
        this.split.push(splitId);
        this.table.splice(this.table.indexOf(lastElem) + 1, 0, splitId);
        if (this.debug) {
          console.log(`SPLIT: id:${id}, table:${this.table}`);
        }
//console.log(`Arbiter split returns :${id}`);
        return id;
      }
      case DOUBLE: {
        const rvalue = this.table[this.ptr];
        // this.table.splice(this.ptr, 1);
        this.drop(rvalue);
//console.log(`Arbiter double returns :${rvalue}`);
        return rvalue;
      }
      case STAND :{
        if (this.debug) {
          this.display(decision)
        }
        let id = this.table[this.ptr];
        this.drop(id);
        id=this.table[this.ptr];
//console.log(`Arbiter stand returns :${id}`);
        return id;
      }
      case HIT: {
        const id = this.table[this.ptr];
//console.log(`Arbiter hit returns :${id}`);
         return id;
      }
    }
  }
  dealer(id: number) { this.did=id;}
  splitReset() {
    return this.split.reset();
  }
  isSplitHand() { return this.split.isSplitHand();}
  isSplitId(id: number) {
    return this.split.exists(id);
  }
  splitId() {
    return this.split.lastElem();
  }
   next() {
     return this.table[this.ptr+1];
   }
  current() { return this.table[this.ptr];}

  previousId() { return this.preId;}
  drop(id: number) {
    this.preId=id;

    if (!this.table.includes(id)) {
      console.log('CardSwitchService: Arbiter::drop receiving bad hand: ${id}');
      alert('Internal error please refresh the page.');
    }
    const index = this.table.indexOf(id);
    if (this.split.exists(id)) {
      this.split.pop(id);
    }
    this.table.splice(index, 1);
    if (this.table[this.ptr] === this.did) {
      this.lastHand = id;
    }
  }
  doneWithSplit() {
    return this.split.doneWithSplit(this.table);
  }
  size() {
    return this.table.length;
  }

  isLastHandSplit() {
    return this.isSplitId(this.lastHand);
  }
  splitCount() { return this.split.getCount();}
}
