
<div style="margin-left:{{vars['center-width']}}px; height:100%; width:100%">
        <div  class="mild-black inset-border" style="position: relative; margin-top:{{margin.top}}px; width:{{tpage.width}}px; height:{{tpage.height}}px">
                  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)">
                    <ng-container  *ngFor="let stmts of statements[method]">
                    <div ngxSlickItem  class="slide">
                      <div class="margin-side-10" style="margin-top:25px">
                         <Lp  *ngFor="let stmt of stmts" text="{{stmt}}"> </Lp>
                      </div>
                    </div>
                   </ng-container>
                 </ngx-slick-carousel>

                  <!-- <mobile-base-cards (event)="eventHandler($event)"  [id]="'0'" style="position: absolute; top:80px"></mobile-base-cards> -->
                    <anms-multicards (event)="eventHandler($event)" style="position:absolute; top: 80px"> </anms-multicards>
                  <div class='flex' style="position: absolute; top: {{buttonsMargins.top}}px; left:{{buttonsMargins.left}}px">
                          <button mat-mini-fab  (click)="cclicked('backward')"
                               [disabled]="disabled.backward" [color]="'warn'"> <mat-icon> skip_previous</mat-icon></button>
                          <ng-container *ngIf="buttons.length">
                              <button  mat-mini-fab   *ngFor="let elm of buttons" [@flash]="flashes[elm.key].status"
                              [color]="elm.color" style="margin-left:15px" (click)="clicked(elm.key)"> {{elm.key}}</button>
                          </ng-container>
                          <button mat-mini-fab  style='margin-left:15px' (click)="cclicked('forward')"
                               [disabled]="disabled.forward" [color]="'accent'"> <mat-icon > skip_next </mat-icon></button>
                  </div>


        </div>
</div>
<!-- <div class="flex-row" style="top:{{buttonsMargins.top+40}}px; left:{{buttonsMargins.left+40}}px"> -->
