
<div  style="margin-left:{{gvars['center-width']}}px">
  <mat-tab-group dynamicHeight (selectedTabChange)="tabClicked($event)" style="margin-top:25px" [(selectedIndex)]="selectedTab">
      <mat-tab  [label]="'Book'" >
        <div class="flex-column" style=" margin-top:10px; box-sizing: border-box;">
          <div class="rule-scene">
             <div class="flip"  [@cardFlip]="istate">
                 <div class="face back">
                   <div class="flex">
                         <anms-mobile-flip-ccard  *ngFor="let i of [0,1,2,3]" [img]="second.img[i]" [id]="second.id[i]"
                                style="margin-left:10px; margin-top:5px;"> </anms-mobile-flip-ccard>
                   </div>
                 </div>
                 <div class="face front" >
                             <ng-container *ngFor="let i of [0,1]">
                                 <div class="flex">
                                   <span *ngFor="let elm of cards[i]" style="cursor:pointer">
                                    <anms-mobile-flip-ccard  [img]="elm.img" [id]="elm.id" style="margin-left:10px; margin-top:5px;"

                                         (click)="clicked(elm.img)" > </anms-mobile-flip-ccard>
                                  </span>
                                 </div>
                             </ng-container>

                             <div class="flex">
                               <span *ngFor="let elm of cards[2]" style="cursor:pointer">
                                    <anms-mobile-flip-ccard  [img]="elm.img" [id]="elm.id" style="margin-left:10px; margin-top:5px"
                                               (click)="clicked(elm.img)" > </anms-mobile-flip-ccard>
                               </span>

                                  <button  mat-fab [@flash]="flashes[vbutton].status" [color]="'warn'" [disabled]="disabled[vbutton]"
                                     style="margin-left:30px; margin-top:39px;" (click)="clicked(vbutton)"> {{vbutton}}</button>
                             </div>
                 </div>
              </div>
              </div>
             <!-- rules -->
             <div class="flow" style="margin-left:20px;margin-top:30px" >
                       <ng-container *ngFor="let elm of rules" >
                         <button  mat-fab [@flash]="flashes[elm].status"
                                     class="mild-black"
                                     style="margin-right:8px"
                                     (click)="rclicked()"
                                     > {{elm}}</button>
                         </ng-container>
             </div>
          </div>
      </mat-tab>
      <mat-tab *ngFor="let cards of ['Low prediction', 'High prediction']" [label]="cards" >
        <div  class="mat-elevation-z8 font" >
                <mat-table #table [dataSource]="dtable" >
                      <ng-container  *ngFor="let col of columns" [matColumnDef]="col">
                        <mat-header-cell *matHeaderCellDef class="{{gcls(col)}}"> <span class="rule-cell"> {{col}} </span>  </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="rule-cell {{gcls(col)}} {{color(element[col])}}"> <span style="margin-left:20px"> {{element[col]}} </span>  </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: columns;"></mat-row>
                </mat-table>
        </div>
      </mat-tab>
  </mat-tab-group>
  <div class="flow" class="{{buttomClass}}">
     <button mat-button [color]="'primary'" (click)="tclick('Soft')" [disabled]="disabled.Soft"> Soft </button>
     <button mat-button [color]="'warn'" (click)="tclick('Split')" [disabled]="disabled.Split"> Split</button>
     <button mat-button [color]="'accent'" (click)="tclick('Hard')"[disabled]="disabled.Hard"> Hard </button>
     <mat-paginator #paginator  [pageSizeOptions]="getOptions()" showFirstLastButtons   style="margin-right:{{paginatorMargin}}"></mat-paginator>
  </div>

</div>
