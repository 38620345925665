import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import {trigger,state, style,transition,animate,query,stagger, keyframes} from '@angular/animations';
import { globalVars,imageDims } from '../../../services/global.service';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import {CardBadgeEnum, CardBadgeDataUnit} from '../../store/cards.model';

@Component({
  selector: 'anms-cards-badge',
  templateUrl: './cardsbadge.component.html',
  styleUrls: ['../../styles.scss'],
  animations : [
    trigger('drop', [
      transition(':enter', [
        // Initially the all cards are not visible
         animate('500ms ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(60px)', offset: 0 }),
            style({ opacity: .4, transform: 'translateY(-60px) scale(1.2)', offset: 0.4 }),
            style({ opacity: .8, transform: 'translateY(10px)', offset: 0.6 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 })
          ]))]),
          // transition(':leave', [
          //    animate('1s ease-in', keyframes([
          //       style({ opacity: .2, transform: 'translateX(70%)', offset: 0.8}),
          //       // style({ opacity: .2, transform: 'translateX(70%)', offset: 0.7 }),
          //       style({ opacity: 0, transform: 'translateX(100%)', offset: 1 })
          //     ]))]),

      ])
  ]
})

export class MobileCardsBadgeComponent implements OnInit {
  @Input('id') id: number =0;
// bgClass="";
dims = {top:0, left:0};
enabled = false;
img = '';
badge = '';
subscriptions = [];
list = [1];
// list: Array<number> = [1];
constructor(public store: Store<any>) {

 }
 ngOnDestroy():void {
   this.subscriptions[0].unsubscribe();
 }

  ngOnInit():void {
  imageDims();
  const image = globalVars['image'];
  const dims=image.dims;
  this.dims.left = Math.floor(image.card.width/2)-20;
  this.dims.top = Math.floor(image.card.height/2)-20;
  // this.list = [1];
  this.subscriptions.push(
    this.store
      // .select('CardBadge')
      .select('mobileCard')
      .pipe(filter(event => event.typeof==='CardBadgeDataUnit'))
      .subscribe((data: CardBadgeDataUnit) => {
        if (data.id != this.id) return;
        this.enabled = true;
        if (data.msg == CardBadgeEnum.cardbadge_both) {
          this.img = data.value.img;
          this.badge = data.value.badge;
          return;
        }
        if (data.msg == CardBadgeEnum.cardbadge_card) {
          // console.log(`setting image:${data.value}, id:${this.id}`)
          this.img = data.value;
          return;
        }
        if (data.msg == CardBadgeEnum.cardbadge_badge) {
          this.badge = data.value;
          this.list[0]++;
          return;
        }
    }))
}
}
