import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';


import { sizeTrigger, listHSlideTrigger } from '../../../animations/basicTrigger';
import { imageDims, globalVars, MobileRouterService, SpeedInSec,getMethodDesc,getTheLevel,decksMapping, check_credit, pageDims} from '../../../services/global.service';

import {ActivatedRoute} from "@angular/router";
import {BaseListComponent} from '../../shared/list/component';
import {MobileSendKeyRoute, MobileSendFlash, MobileSendUnFlash,  BottomResetMenuPressed,BottomAppleModify} from '../../store/bottom.action.reducer';
import {MobileDrawCardAction,MobileDrawCardResetAction, MobileDisplayDoubleCardsCount} from '../../store/cards.action.reducer';
import {MobileCountCardDataUnit} from '../../store/cards.model';
import {KeyPressedDm} from '../../store/bottom.model';
import { CommonListAnimations} from '../../shared/animations/mobile-animations';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
// import { GenericTopTopAction,GenericSlideLearnTrain,GenericSlideConceptTrain, GenericSettingRestoreFromCach} from '../../store/generic.action.reducer';
import { GenericTopTopAction,GenericSlideLearnTrain,GenericSingleCardTrain,GenericSingleSpeedTrain} from '../../store/generic.action.reducer';

import { GenericDataUnitEnum, GenericDataUnit} from '../../store/generic.model';
import { DealerService } from '../../../services/deck.service';



import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes, query, stagger
} from '@angular/animations';

enum CardsStateEnum {
	DRAWN,
	CLEAR
};

enum SettingActiveEnum {
	active,
	idle
};
@Component({
  selector: 'anms-mobile-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['../../styles.scss'],

})

export class MobileLearningComponent implements OnInit {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  colors = { primary: 'primary', accent: 'accent', warn: 'warn' };
  subscriptions=[];
  selector='idle';
  unit='';
  displayCount = false;
  label='';
  vars = {};
  reduceit=false;
  count=0;
  headerEnabled=false;
  header='';
  switchHeader=true;
  die=false;
  methodIcon={color: 'primary', value:'E'};
 settingWasActive=false;
  controlEnabled=false;
  tpage={width:0, height:0};
 disabled={forward:true, test:true};
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };
 marginTop=20;
 cardState=0; // no cards drawn
 scheduled=false;
 reroute=false;
 settingKey='';
 totalHeight=0;
 go2top=false;
 forceCards = false;;
 buttonMarginLeft=0;
///////
// constructor
///////
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: MobileRouterService,
    public dealerService: DealerService,
    public store: Store<any>,
            ) {
          this.vars = globalVars;
          globalVars['state']= 'train-learning';
   }
////////

  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
    // MobileSendKeyRoute(this.store,[{key:"reset"}]);
    globalVars['netcount']=false;
    globalVars['state']='idle';
    // globalVars['modifyDeck'] = {count:0, type:'low'};
    this.dealerService.shuffle();

  }
/////////

/////////
// ngOninit task
/////////


processArrowUp() {}
processArrowDown() {}
processArrowForward() {}

timeout():any {
  const cout = globalVars['cardsOut'];
  const timePerRow=1300; // 1second
  const npanels = globalVars['npanels']
  return npanels*timePerRow;
}


eventHandler(e:any):void {
  // alert(`rcvd event:${e}`)
  switch(e) {
    case 'removed': {
      // MobileSendFlash(this.store,'up');
      // BottomAppleModify(this.store, {disable:['forward', 'lastIcon'],flash:{forward: false}});
      if (this.go2top) {
          this.router.navigate('mobile/count-top');
          return;
      }
      this.cardState=CardsStateEnum.CLEAR;
      if (this.settingWasActive) {
            globalVars['setting'] = {
             func: GenericSlideLearnTrain,
             route:`mobile/lessons/${this.unit}`
           };
            this.router.navigate('mobile/setting-train');
            return;
      }

      if (this.reroute) {
        this.reroute=false;
        if (this.settingKey=='test') {
             this.router.navigate(this.reduceit? `mobile/tests/net-count-1`:'mobile/tests/adding-count');
        } else {
           globalVars['setting'] = {
             func: this.settingKey=='speed'?  GenericSingleSpeedTrain: GenericSingleCardTrain,
             route:`mobile/lessons/${this.unit}`,
             speed:800,
             mode:globalVars['mode']
           }
          this.router.navigate('mobile/setting-train');
        }
        return;
      }
      if (this.forceCards) {
        globalVars['modifyDeck'] = {count:globalVars['cardsOut'], type:'high'};
        this.forceCards = false;
      } else {
        globalVars['modifyDeck'] = {count:0, type:'high'};
      }
      if (this.scheduled) {
          this.dealerService.shuffle();
          MobileDrawCardAction(this.store);
          MobileSendUnFlash(this.store, 'up');
          this.scheduled=false;
      }
      break;
    } case 'badgeDrawn': {
			setTimeout(() => {
				this.controlEnabled = true;
			}, 400);
			break;
    }  case 'drawn': {
      if ( this.switchHeader) {
        this.switchHeader = false;
        this.header = this.reduceit? "Green circle below contains the count/running-count": "Green circle below contains the count";
        this.headerEnabled=true;
      }
/*
      setTimeout(() => {
        MobileSendFlash(this.store,'forward');
      }, this.timeout());
     */
     this.disabled.test=false;
      this.cardState=CardsStateEnum.DRAWN;
      BottomAppleModify(this.store, {enable:[ 'lastIcon']});
      this.disabled.forward=false;
      this.totalHeight = ((globalVars['npanels']+1) * globalVars['image']['height'])+300;
      // this.controlEnabled=true;
      MobileDisplayDoubleCardsCount(this.store); //
      break;
    }
  }
}
  setIcon(color:any, value:any):void {
    this.methodIcon.color=color;
    this.methodIcon.value=value;
  }

  istate() {
    const list=[
      getMethodDesc(),
      getTheLevel(),
      `${this.label}`
    ];
    GenericTopTopAction(this.store, list);
    BottomAppleModify(this.store, {enable:['backward'], disable:['forward', 'lastIcon'],  lastIcon:'settings'});

  }
  clear(onlyClear=false) {
    this.disabled.forward=true;
    this.headerEnabled=false;
    MobileDrawCardResetAction(this.store);
    this.scheduled=!onlyClear;
  }
  clicked(key) {
      this.clear();
      this.disabled.test=true;
      if (['test', 'cards', 'speed'].includes(key)) {
        this.reroute=true;
        this.settingKey=key;
      }
      if (key=='dbackward') {
        this.clear();
        this.go2top=true;

        return;
      }
       this.controlEnabled=false;
  }
  ngOnInit() :void{
      check_credit(this.router);
      BottomResetMenuPressed(this.store);
    // if (this.router.refreshed()) {
    //   this.router.navigate('mobile/train');
    // }
     globalVars['testing']=false;
     globalVars['learning']=true;
     imageDims();
     const image = globalVars['image'];
     this.tpage = {...pageDims()};
     this.marginTop=globalVars['desktop'] ? 40: 10;
     this.buttonMarginLeft = this.tpage.width-(3*image.card.width+70);
     this.dealerService.shuffle();
     // globalVars['modifyDeck'] = {count:globalVars['cardsOut'], type:'low'};
     this.forceCards = true;
     this.subscriptions.push(
         this.activatedRoute.params.subscribe( ( params:any) => {
          this.unit = params['id'];
          this.reduceit = this.unit.includes('net');
          globalVars['vpath']=`count/lessons/${this.unit}`;
          globalVars['netcount'] = this.reduceit;
          this.label = decksMapping();
          this.label += this.reduceit? " running count tutorial": " adding tutorial";
          this.istate();
       }));

       this.subscriptions.push(
           this.store
             .select('mobileTopBottom')
             .pipe(filter(data => data.typeof=== 'KeyPressedDm' ))
             .subscribe((data: KeyPressedDm) => {
               if (data.target=='parent') {
                  this.die=true;
                  return;
               }
          		switch(data.key) {
                case 'home' : {
                    this.die=true;
                    setTimeout(() => {
                      this.router.navigate('mobile/home');
                    }, 200);
                  break;
                }
          		   case 'backward' : {
                       this.die=true;
                       setTimeout(() => {
                         this.router.navigate('mobile/train');
                       }, 200);
          		       break;
          	     } case 'settings': {
                                    this.clear(true);
                                    this.settingWasActive=true;
                  break;
          	   	}
	          }
             })
         );
        this.subscriptions.push(
              this.store
                .select('genericReducer')
                .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                .subscribe((data: GenericDataUnit) => {
                  if (data.action==GenericDataUnitEnum.die) {
                    this.die=true;
                    return;
                  }

                }
              )
         );

      this.headerEnabled=false;
      this.dealerService.shuffle();
      MobileDrawCardAction(this.store);
      // this.disableButtons();
  }
  disableButtons(value=true) {
    this.disabled.forward=value;
    this.disabled.test=value;

  }
  afterChange(event){
  }

}
