import { Component, OnDestroy, OnInit,ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { MobileRouterService, globalVars, imagesAttr } from '../../services/global.service';
import { DealerService } from '../../services/deck.service';
import {BaseListComponent} from '../shared/list/component';
import {KeyPressedDm} from '../store/bottom.model';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { map, filter } from 'rxjs/operators';
import { GenSettingAction } from '../../store/gen-setting.action.reducer';
import { GenSettingEnum } from '../../store/gen-setting.model';
import {GenericTopTopAction, FlipCardActionWrapper,UpdateImageCardActionWrapper} from '../store/generic.action.reducer';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatPaginator } from '@angular/material/paginator';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {rules} from '../services/rule.service';
import { START_FLASH,  FADEOUT, FADEIN,nFlash} from '../../services/common.service';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';


enum PageEnum {
  top,
  strength,
  table
};

interface tableColumns {
  p:string;
  1:string;
  2:string;
  3:string;
  4:string;
  5:string;
  6:string;
  7:string;
  8:string;
  9:string;
  10:string;
};

enum State {
  idle,
  player,
  dealer,
  rule
};

@Component({
  selector: 'anms-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['../styles.scss'],
  animations: [
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)'
      })),
      transition('default => flipped', [
        //animate('400ms')
        animate('1000ms')
      ]),
      transition('flipped => default', [
        //animate('200ms')
        animate('500ms')
      ])
    ]),
      trigger('flash', [
        transition(`* => ${FADEIN}`, [
          style({ opacity: 0 }),
          animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
        ]),

        transition(`* => ${FADEOUT}`, [
          animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
        ])
      ])
    ]
})


export class RulesComponent  implements OnDestroy, OnInit  {
    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  columns= [];
  columns_hash = {
    'Book':  ['PL','2','3','4','5','6','7','8','9','10','A'],
    'Prediction': ['P', 'D', 'Counts','Prediction', 'Book']
  };

  paginatorMargin="40px";
  selectedTab=0;
  state=State.idle;
  istate="default";
  bgcls={
    P:"null",
    D:"null",
    A:"null",
    2:"null",
    3:"null",
    4:"null",
    5:"null",
    6:"null",
    7:"null",
    8:"null",
    9:"null",
    10:"null",
    Counts:"null",
    'Prediction':"accent-bg",
    'Book':"warn-bg",
  };
  vbutton = '';
  debug=false;
  tableShow=false;
    slideConfig= {
   slidesToShow: 1,
   slidesToScroll: 1,
   dots: false,
    arrows: false,
   infinite: false,
   swipeToSlide: true,
 };

  subscriptions = [];
  enabled=false;
  gvars=globalVars;
  dtable :  MatTableDataSource<tableColumns>;
  pageSizeOptions=[];
  page={c:PageEnum.top, PL:[]};
  selected = {
    rule:'',
    strength:'',
  };
 // tableEnabled=false;

 sides=['PLAYER', 'DEALER'];
 flashes={};
 disabled={
   'PLAYER':true, 'DEALER':true, 'Soft': true, 'Split': false, 'Hard': false
 };

 rules=['STAND', 'HIT', 'DOUBLE', 'SPLIT'];
 userInput=[];
 mobile=false;
 rulesFlashed=[];
 cards = [
   [{img:'2C', id:0}, {img:'3H', id:1},{img:'4C', id:2},{img:'5H', id:3},{img:'6C', id:4}],
   [{img:'10C', id:5}, {img:'JH', id:6},{img:'QC', id:7},{img:'KH', id:8},{img:'AC', id:9}],
   [{img:'7C', id:10}, {img:'8H', id:11},{img:'9C', id:12}],
 ];

 cards2id = {
   '2C':0, '3H':1, '4C':2, '5H':3, '6C':4,
   '10C':5, 'JH':6, 'QC':7, 'KH':8, 'AC':9,
   '7C':10, '8H':11, '9C':12
 };

 second = {id:[13,14,15,16], img:['','','2C','']};
 savedRule='';
 dims={width:0, height:0};
 rulesMap={
   D:'DOUBLE',
   H: 'HIT',
   S: 'STAND',
   SP: 'SPLIT',
   'D,I':'DOUBLE, INSURANCE',
   'H,I': 'HIT, INSURANCE'
 };

 buttomClass="hide";

flipit() {
   this.istate = this.istate == 'default'? 'flipped': 'default';
}
 flipCard(index) {
   FlipCardActionWrapper(this.store, index);
 }

 flipSecond() {
   for (let i of [13, 14, 16]) {
     FlipCardActionWrapper(this.store, i);
   }
 }

 flipAll(value=true) {
   for(let i=0;i!=13;i++) {
     FlipCardActionWrapper(this.store, i);
   }
 }

 flipOtherList(list) {
     // for (const key of Object.keys(this.cards2id).filter(elm=>list.includes(elm))) {
   for (const key of Object.keys(this.cards2id)) {
       if (!list.includes(key)) {
         FlipCardActionWrapper(this.store, this.cards2id[key]);
         // console.log(`key:${key}`)
       }
   }
 }

 flipList(list) {
     for (const key of list) {
        FlipCardActionWrapper(this.store, this.cards2id[key]);
   }
 }
 unflushAllRules() {
   for(const key of this.rules) {
     this.flashes[key].unflush();
   }
 }

  color(rule) {
    switch(rule) {
      case 'SP': return 'red-font';
      case 'D' : return 'yellow-font';
      default: return 'none';
    }
  }

  gcls(col) {
    switch(col) {
      case 'P': return "player_table_margin";
      case 'Prediction': return "rule-prediction-column";
      case 'Book': return "rule-book-column";
      case 'Counts': return "rules-times-table";
      default: return "null";
    }
  }

  isSoft(type) { return type=='Soft';}
  isSplit(type) { return type=='Split';}

  transform(dict, type) {
    const list=[];
    for (let pcard in dict) {
      // console.log(`key:${key}, value:{ value)}`);
      let dpcard=pcard; // modify the pcard for soft and split
      if (this.isSoft(type)) {
         dpcard=`A,${pcard}`;
      } else if (this.isSplit(type)) {
           dpcard=`${pcard},${pcard}`;
      }
       const d={...dict[pcard]}; d['PL']=dpcard;

       list.push(d);
    }
    return list;
  }

  getList(rule, type, strength="null") {

    if (rule=='Book') {
      this.debug=true;
      return this.transform(rules['Book'][type], type);
    }
    this.debug=false;
    const list=[];
    const transform={
      D:'double',
      H: 'hit',
      S: 'stand',
      SP: 'split',
      'D,I':'double, insurance',
      'H,I': 'hit, insurance'
    };
       for (const item of  rules[rule][type]) {
         const cc={...item};
         const Prediction=item['Prediction'];
         const book=item['Book'];
         const counts=item['Counts'];
         const d={}
         for (let s of counts) { // extract count
            s=s.replace(/"/g, "");
            let [decision, count] = s.split(':');
            d[decision]=count;
         }
         cc['Counts']=`${d[Prediction]} vs ${d[book]}`;
         cc['Prediction']=transform[Prediction];
         cc['Book']=transform[book];
         list.push(cc);
     }
     return list;
  }



  constructor(public store: Store<any>, private routerService:MobileRouterService) {
      for (const elm of [...this.sides, ...this.rules])
        this.flashes[elm]= new nFlash;
  }

  flashOp(list, ops) {
    for(const e of list) {
      if (ops=='flash') {
        this.flashes[e].flash();
      } else {
        this.flashes[e].unflash();
      }
    }
  }

  splitUserInput() {
    const userInput = this.userInput.map(value=>value.slice(0, value.length-1))
    let phand = userInput.slice(0,2);
    const dhand = ['J', 'Q', 'K'].includes(userInput[2])? '10': userInput[2];
    if (phand.includes('A')) {
      const index = phand[0] == 'A'? 1: 0;
      return {type:'Soft', player: ['J', 'Q', 'K'].includes(phand[index])? '10': phand[index], dealer: dhand};
    }
    if (phand[0] == phand[1]) {
      return {type:'Split', player: ['J', 'Q', 'K'].includes(phand[0])? 10: phand[0], dealer: dhand};
    }

    phand = phand.map((value) =>['10', 'J', 'Q', 'K'].includes(value)? 10: value);
    const p = +phand[0] + +phand[1];
    return {type: 'Hard', player:`${p}`, dealer: dhand };
  }

  getRule() {
    const rvalue = this.splitUserInput();
    const type = rvalue.type;
    const brule = rules['Book'];
    if (type != 'Hard') {
      return this.rulesMap[brule[type][rvalue.player][rvalue.dealer]];
    }
    const player = +rvalue.player;
    if (player < 9 ) return 'HIT'
    if (player >= 17) return 'STAND'
    return this.rulesMap[brule[type][rvalue.player][rvalue.dealer]];
  }

  rclicked() {
    while(this.rulesFlashed.length) {
      this.flashes[this.rulesFlashed.shift()].unflash();
    }
    this.flipit();
    this.flipAll();
  }
  clicked(key) {
    switch(this.state) {
      case State.idle: {
        if (key=='PLAYER') {

          this.state = State.player;
          if (this.userInput.length) {
            this.flipSecond();
          }
          this.flipAll();
          this.flashes[key].unflash();
          this.disabled.PLAYER=true;
          this.userInput=[];
        }

        break;
      } case State.player : {

        this.userInput.push(key);
        if (this.userInput.length==2) {
             this.state = State.dealer;
             // this.flipAll();
             this.vbutton = 'DEALER';
             this.disabled.DEALER=false;
             this.flashes['DEALER'].flash();
             this.flipOtherList(this.userInput);
             return;
        }
        break;
      } case State.dealer : {
        if (key=='DEALER') {
          this.state = State.rule;
           this.flipList(this.userInput);
           this.flipAll();
          this.flashes[key].unflash();
        }
        break;
      } case State.rule: {

        this.userInput.push(key);
        setTimeout(() => {
          UpdateImageCardActionWrapper(this.store, this.userInput[0], 13);
        }, 10);
        setTimeout(() => {
          UpdateImageCardActionWrapper(this.store,this.userInput[1], 14);
        }, 20);
        setTimeout(() => {
          UpdateImageCardActionWrapper(this.store, this.userInput[2], 16);

        }, 30);
        setTimeout(() => {
          this.flipit();
        }, 50);

        setTimeout(() => {
          this.flipSecond();
        }, 100);

        // this.flipOtherList([key]);
        const rule=this.getRule();
        this.flashes[rule].flash();
        // get the rules
        this.state = State.idle;
        this.flashes['PLAYER'].flash();
        this.disabled.PLAYER=false;
        this.vbutton='PLAYER';
        this.rulesFlashed.push(rule);
        break;
      }
    }
  }



  bookCol() {
    return this.columns_hash['Book'];
  }
  jackCol() {
    return this.columns_hash['Prediction'];
  }

  jackMsg() {
      GenericTopTopAction(this.store, ["Book vs Prediction", this.savedRule.includes('High') ? "high cards likelihood strong": "low  cards likelihood strong"], 'tigger');
  }
  topMsg() {
    GenericTopTopAction(this.store, ['Overview of the rules', "First select 2 player's cards", "Second select dealer face card"], 'tigger');

  }
  ngOnInit() {
    // BottomAppleModify(this.store, { enable:['backward'], disable:['forward', 'lastIcon'],  lastIcon:'settings'});
    // BottomAppleModify(this.store, { disable:['forward', 'lastIcon'],  lastIcon:'settings'});
      BottomAppleModify(this.store, {enable:['lastIcon', 'backward'], disable:['forward'], lastIcon:'settings'});
    const key='PLAYER';
    this.vbutton=key;
    this.disabled[key]=false;
    this.flashes[key].flash();
    const attr = imagesAttr.mobile;
    this.dims={width: attr.card.width, height:attr.card.height};
    globalVars['vpath']="rules";
    this.topMsg();

    this.mobile = globalVars['mobileDevice'];
    this.pageSizeOptions = this.mobile? [3,4]: [5,10];
    // this.assign('Strong-high');
    this.columns=this.jackCol();
    this.dtable = new MatTableDataSource<tableColumns>(this.getList('Book', 'Soft'));
    this.paginatorMargin = this.mobile ?"0px":"40px";

    setTimeout(() => {
      this.enabled=true;

    }, 400);
     this.subscriptions.push(
     this.store
       .select('mobileTopBottom')
       .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
       .subscribe((data: KeyPressedDm) => {
         // need to see approriate data with keys pressed
         if (data.key=='backward') {
           this.routerService.navigate('mobile/train');
           return;
         }

       }));

  }

   ngOnDestroy() :void {
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }

   getOptions() {
    if (globalVars['mobileDevice']) {
       return [6,5]
    }
    if (globalVars['midDevice']) {
       return [8,7,5]
    }
    return [10, 7, 5];
  }

   ngAfterViewInit() {
     this.dtable.paginator = this.paginator;
  }

  afterChange(event:any):void { }


   tabClicked(event) {
     // console.log(`this.selected.rule:${this.selected.rule}, event.tab.textLabel:${event.tab.textLabel}`)
     const prediction = event.tab.textLabel;
     // this.tableEnabled = prediction=='Book'? false: true;
     this.dtable.data=this.getList(prediction, 'Soft');
     this.savedRule = prediction;
     this.buttomClass= prediction=='Book'? "hide": "show";
     this.jackMsg();

   }

   tclick(button) {
     switch(button) {
       case 'Soft': {
         this.disabled.Soft  = true;
         this.disabled.Split = false;
         this.disabled.Hard  = false;
         break;
       }
       case 'Split': {
         this.disabled.Soft  = false;
         this.disabled.Split = true;
         this.disabled.Hard  = false;
         break;
       }
       case 'Hard': {
         this.disabled.Soft  = false;
         this.disabled.Split = false;
         this.disabled.Hard  = true;
         break;
       }
     }
     this.paginator.firstPage();
     this.dtable.data=this.getList(this.savedRule, button);
   }
}
