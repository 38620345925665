import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { GenericDataUnitEnum, FlipDataUnit} from '../store/generic.model';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { globalVars, imagesAttr } from '../../services/global.service';


@Component({
  selector: 'anms-mobile-flip-ccard',
  templateUrl: './flip.card.component.html',
  // styleUrls: ['./flip.component.scss', '../../styles.scss'],
  styleUrls: ['./flip.card.component.scss'],

  animations: [
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)'
      })),
      transition('default => flipped', [
        //animate('400ms')
        animate('1000ms')
      ]),
      transition('flipped => default', [
        //animate('200ms')
        animate('500ms')
      ])
    ])
  ]
})

export class FlipCCardComponent implements OnInit {
  subscriptions=[];
@Input('id') id: number;
@Input('img') img: string;
state: 'default'| 'flipped';

cards=[];

dims='';

///////
// constructor
///////
  constructor(
    public store: Store<any>,
  ) {
    this.state='default';

  }
////////

  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
  }

 flipit() {
     this.state= this.state=='default'? 'flipped': 'default';
 }

 getImage(img) {
   return `/assets/cards/${img}${this.dims}.png`;
 }

  ngOnInit() : void {
    this.dims = imagesAttr.mobile.dims;
    this.cards = [this.getImage('Gray_back'), this.getImage(this.img)];
    this.subscriptions.push(
          this.store
            .select('flipCardReducer')
            .pipe(filter(event =>event.typeof=== 'FlipDataUnit'))
            .subscribe((data: FlipDataUnit) => {
              if (data.id == this.id) {
                    if (data.action == 'flipcard') {
                      this.flipit();
                    } else if (data.action == 'update') {
                      this.img = data.image;
                      this.cards[1] = this.getImage(this.img);
                    }
              }
          }));
  }
}
