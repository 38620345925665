
<div *ngIf="label.length" class="{{labelClass}}" style="margin-left:15px" [@flash]="nflash.status"> {{label}} </div>
<div class="flex-column">
   <div class="flex" *ngIf="cenabled(0)">
      <anms-cards-badge [id]="i"  [@blist]="config[0].list.length" style="margin-top:15px" class="cards-spacing" *ngFor="let i of config[0].list"> </anms-cards-badge>
   </div>
   <div class="flex" *ngIf="cenabled(1)">
      <anms-cards-badge [id]="i"   [@blist]="config[1].list.length" style="margin-top:15px" class="cards-spacing" *ngFor="let i of config[1].list"> </anms-cards-badge>
   </div>
   <div class="flex" *ngIf="cenabled(2)">
      <anms-cards-badge [id]="i"   [@blist]="config[2].list.length" style="margin-top:15px" class="cards-spacing" *ngFor="let i of config[2].list"> </anms-cards-badge>
   </div>
</div>
