
 <div style="margin-left:{{vars['center-width']}}px" class="white-border">
          <div  class="mild-black inset-border" style="position: relative;margin-top:{{marginTop}}px; width:{{tpage.width}}px; height:{{tpage.height}}px;" *ngIf="!die" >
                <anms-multicards (event)="eventHandler($event)" > </anms-multicards>
                <div class="flex-row"  *ngIf="controlEnabled" >
                  <button mat-button  (click)="clicked('dbackward')" [color]="'warn'"
                       style="position: absolute;left:{{tpage.width-220}}px; top:{{tpage.height-75}}px"> PREV <mat-icon class="scale1p5" [color]="'warn'" > arrow_backward_ios </mat-icon> </button>
                   <button mat-button  (click)="clicked('forward')" [color]="'warn'"
                        style="position: absolute;left:{{tpage.width-120}}px; top:{{tpage.height-75}}px"> NEXT <mat-icon class="scale1p5" > arrow_forward_ios </mat-icon> </button>
                </div>
          </div>
  </div>
