

      <!-- <div  style="margin-top:20px;width:100%; height:100%;margin-left:{{gvars['center-width']}}px"> -->
          <div  class="flex inset-border mild-black" style="margin-top:30px; width:{{pageWidth}}px">
            <div class="flex-column" style="margin-left:20px">
                 <p> Dealer hand </p>
                  <div class="flex">
                        <div  style="min-height:{{margins.height+40}}px; margin-top:10px;" >
                          <div class="flex"  >
                               <img  src="{{cards[mode].dealer[0]}}" class="cards-spacing" style="margin-top:10px">
                               <div class="scene">
                                  <div class="flip"  [@cardFlip]="cards[mode].state">
                                       <div class="face back" style="cursor:pointer">
                                            <img  src="{{cards[mode].dealer[2]}}"  class="cards-spacing" style="margin-top:10px" (click)="flipit(mode, 'base')">
                                       </div>
                                       <div class="face front"  style="cursor:pointer">
                                            <img  src="{{cards[mode].dealer[1]}}"  class="cards-spacing" style="margin-top:10px" (click)="flipit(mode, 'base')">
                                       </div>
                                  </div>
                               </div>
                        </div>
                      </div>
                  </div>
                  <button  mat-fab  *ngIf="mode==2" [color]="colorObj.value" style="margin-left:12px; margin-top:5px"
                               [@flash]="fb.status" [disabled]="false"> {{easyBust.value}}</button>

                  <p style="margin-top:20px"> Player hand </p>
                   <div class="flex">
                         <div  style="min-height:{{margins.height+40}}px; margin-top:10px;" >
                           <div class="flex " >
                                <img  src="{{cards[mode].player[0]}}" class="cards-spacing" style="margin-top:10px">
                                <img  src="{{cards[mode].player[1]}}"  class="cards-spacing" style="margin-top:10px">
                         </div>
                       </div>

                   </div>
            </div>
            <div style="margin-top:{{margins.top}}px; margin-left:50px">
              <p > Next card </p>
              <div class="scene">
                 <div class="flip"  [@cardFlip]="cards[mode].next.state">
                      <div class="face back" style="cursor:pointer">
                           <img  src="{{cards[mode].next.card}}"  class="cards-spacing"  (click)="flipit(mode, 'next')">
                      </div>
                      <div class="face front"  style="cursor:pointer">
                           <img  src="{{cards[0].dealer[1]}}"  class="cards-spacing"  (click)="flipit(mode, 'next')">
                      </div>
                 </div>
              </div>
            </div>
      </div>
