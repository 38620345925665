import { Component, OnInit, OnDestroy} from '@angular/core';

import { globalVars} from '../../services/global.service';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import {  map, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { FlashButtons, FilterType, START_FLASH,  FADEOUT, FADEIN,nFlash} from '../../services/common.service';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
const IDLE = 0;

@Component({
  selector: 'anms-mobile-toptop',
  templateUrl: './toptop.component.html',
  styles: [
		`
    .primary-bg {
      color: white;
      background: #303f9f !important;
    }

    .red-bg {
      color: black;
      background: #d50000 !important;
    }

    .orange-bg {
      color: black;
      background: #e57373;
    }

    .green-bg {
      color: black;
      background: #388e3c;
    }

    .accent-bg {
      color: black;
      background: #00897b !important;
    }
    .warn-bg {
      color:black;
      background: #e91e63 !important;
    }

    .count-dims {

      width:31px;
      height:31 px;
      font-size:20px;
      // width:28px;
      // height:28px;
        // @include media('>=phone', '<tablet') {
        //   width:20px;
        //   height:20px;
        // }
      }
    .count-circle {
        border-style:solid !important;
        border-radius:15px !important;
        border-color: black !important;
        border-width:5px;
         box-shadow: inset 0 0 0 5px white;
        // color:white !important;
        text-align: center !important;
      }


      `

		],
  animations: [
    trigger('flash', [
    transition(`* => ${FADEIN}`, [
      style({ opacity: 0 }),
      animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
    ]),

    transition(`* => ${FADEOUT}`, [
      animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
    ])
  ])
]
  // styleUrls: [],
})
export class TopTopComponent implements OnInit, OnDestroy{
  labels=[];
  disable=false;
  subscriptions=[];
  content={
    image: 'tigger',
    message:[]
  };
  gvars:{};
  flags = {
    cardCount: false
  };

  flash=new nFlash();
  rule="None";
  cls=[];
  firstMsg="";
  font:any;

  countClass='red-bg';

  constructor(private store:Store<any>) {}

   ngOnInit():void {
     this.gvars = globalVars;
     const mobile = globalVars['mobileDevice'];
     this.font  =  mobile? 15: 17;

     this.subscriptions.push(
           this.store
             .select('genericReducer')
             .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
             .subscribe((data: GenericDataUnit) => {
               switch(data.action) {
                 case GenericDataUnitEnum.msg : {
                   this.content.image=data.image;
                   this.cls=['','','',''];
                   if (data.msg[0].includes('class')) {
                     const d=data.msg.shift();
                     let [key,value]=d.split(':');
                     this.cls[0]=value;
                   }
                   this.content.message=data.msg;
                   break;
                } case GenericDataUnitEnum.strength : {
                   if (data.value==GenericDataUnitEnum.strong) {
                     this.flash.flash();
                     this.countClass='green-bg';
                   } else if (data.value==GenericDataUnitEnum.medium) {
                     this.countClass='orange-bg';
                     if (this.flash.isActive())
                         this.flash.unflash();
                   } else {
                     this.countClass='red-bg';
                     if (this.flash.isActive())
                         this.flash.unflash();
                   }
                   break;
                 } case GenericDataUnitEnum.addCardCount : {
                   this.flags.cardCount = true;
                   break;
                 }case GenericDataUnitEnum.removeCardCount : {
                   this.flags.cardCount = false;
                   break;
                 }case GenericDataUnitEnum.updateRule : {
                   this.rule = data.value;
                   break;
                 }case GenericDataUnitEnum.disable : {
                   this.disable=true;
                   break;
                 }case GenericDataUnitEnum.enable : {
                   this.disable=false;
                   break;
                 }


               }
             }
           )
      );


   }

   ngOnDestroy() {
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
   }
}
