

  <div style="margin-left:{{gvars['center-width']}}px">
    <div class="mild-black inset-border" style="margin-top:20px; width:{{tpage.width}}px; height: {{tpage.height}}px">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"   (afterChange)="afterChange($event)" style='width:80%'>
                    <div ngxSlickItem class="slide">

                         <div class='flex-column' style="margin-top:25px">
                           <ng-container *ngFor="let button of buttons">
                              <button mat-raised-button
                                      class="{{mouseClass[button]}} max-width margin-top-10px curvey-border "
                                       (click)="clicked(button,0)" style="margin-left:100px">{{button}}

                                       <mat-icon *ngIf="button=='Audio'"> play_circle</mat-icon>
                                     </button>
                           </ng-container>

                        </div>

                    </div>
                    <div ngxSlickItem class="slide">
                         <p *ngFor="let label of [matcard.label, matcard.subtitle,matcard.content ]"> {{label}}  </p>
                         <div class='flex-column'>
                             <ng-container *ngFor="let button of buttons">
                                <button mat-raised-button
                                       style="margin-left:100px"
                                        class="{{mouseClass[button]}} max-width margin-top-10px curvey-border " (click)="clicked(button,1)">
                                        {{button}}
                                           <mat-icon *ngIf="button=='Audio'"> play_circle</mat-icon>
                                      </button>
                             </ng-container>
                        </div>
                     </div>
          </ngx-slick-carousel>
    </div>

   </div>
