import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { globalVars } from '../../services/global.service';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { START_FLASH,  FADEOUT, FADEIN,nFlash} from '../../services/common.service';
const IDLE = 0;
const DEFAULT_COLOR = 'primary';
enum BetEnum {
  default=25
};
@Component({
  selector: 'anms-online-betting',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  animations: [
      trigger('flash', [
        transition(`* => ${FADEIN}`, [
          style({ opacity: 0 }),
          animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
        ]),

        transition(`* => ${FADEOUT}`, [
          animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
        ])
      ]),
      trigger('cardFlip', [
        state('default', style({
          transform: 'none'
        })),
        state('flipped', style({
          transform: 'rotateY(180deg)'
        })),
        transition('default => flipped', [
          animate('600ms')
        ]),
        transition('flipped => default', [
          animate('300ms')
        ])
      ])
    ]
})

export class OnlineBettingComponent implements OnInit {
  subscriptions=[];
@Input('id') id: number;


hand='First';
price=`${BetEnum.default}`;
bet=BetEnum.default;
value=0;
map={
  First: 1,
  Second:2,
  Third:3,
  fourth:4,
  fifth:5,
  sixth:6
};
max=3;
keys=['First', 'Second', 'Third', 'fourth', 'fifth', 'sixth'];
flashes={};
numbers={
  1:['25', '50', '100'],
  2:['500', '1K', '10K'],
  3:['25k', '50K', '100K']

};
ops = ['add', 'remove'];
gvars=globalVars;
mssapPlaylist= [
{
  title: 'Betting buttons',
  link: 'assets/audio/train/bettingButtons.m4a',
  artist: 'Jack Count',
  duration: 94000
}
];
audioEnable = false;
icons=[
  {value:'', disabled: true},
  {value:'add', disabled: false},
  {value:'remove', disabled: false},
  {value:'arrow_forward', disabled: false},
  {value:'arrow_backward', disabled: true}
];
marginCount=0;
flashNumberList = [];

///////
// constructor
///////
  constructor(
    public store: Store<any>) {
      globalVars['online-bet']=[];
      this.flashNumberList = [...this.numbers[1], ...this.numbers[2], ...this.numbers[3]];
      for (const e of this.flashNumberList ) {
        this.flashes[e] = new nFlash;
      }
      for (const e of this.ops) {
        this.flashes[e]= new nFlash;
      }
    }
flashNumbers() {
  for (const e of this.flashNumberList ) {
    this.flashes[e].flash();
  }
}
unflashNumbers() {
  for (const e of this.flashNumberList ) {
    this.flashes[e].unflash();
  }
}
flashOps() {
  for (const e of this.ops) {
    this.flashes[e].flash();
  }
}
unflashOps() {
  for (const e of this.ops) {
    this.flashes[e].unflash();
  }
}
marginLeft() {
  return [2,5,8].includes(this.marginCount++)? 0: 14;

}
  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
  }

  ngOnInit() :void{
    globalVars['online-bet'] = [25];
    // this.max=globalVars['mobileDevice']|| (globalVars['status']=='train-play')?3:6;
    this.max=globalVars['mobileDevice']?2:3;

    this.flashNumbers();
    if (globalVars['mobileDevice']) this.max=2;
  }

  handIndex() {
    const index=this.map[this.hand]-1;
    // globalVars['online-bet-n'] = index;
    return index;
  }

  include(hand) {
    const bet=globalVars['online-bet'];
    const index=this.handIndex();
    if (index>=bet.length) {
       bet[index]=0;
    }
  }

  nexthand() {
    const nxt=this.map[this.hand] +1;
    this.hand=this.keys[nxt-1];
    return nxt;
  }

  prevhand() {
    const current=this.map[this.hand] -1;
    const prev = current - 1;
    this.hand=this.keys[prev];
    return prev;
  }

  clicked(key) {
    let bet=globalVars['online-bet'];
    switch(key) {
      case 'arrow_forward': {
        if (bet[this.handIndex()]==0) {
          return;
        }
        const hand = this.nexthand();
        this.include(hand);
        this.icons[3].disabled = hand ==this.max;
        this.icons[4].disabled = false;
        break;
      } case 'refresh' : {
          globalVars['online-bet'] = [0];
          bet=globalVars['online-bet'];
          this.hand='First';
          break;
      }  case 'arrow_backward': {
          const hand = this.prevhand();
          if (!hand) {
            this.icons[4].disabled = true;
          }
          this.icons[3].disabled = false;


          break;
      } case 'add': {
            this.flashNumbers();
            this.unflashOps();
            bet[this.handIndex()] += this.value;
            break;
      } case 'remove': {
           const index = this.handIndex();
           this.flashNumbers();
           this.unflashOps();
            if (this.value>bet[index]) {
              bet[index] = 0;
            } else {
              bet[index] -= this.value;
            }
            break;
      } default: {
        this.unflashNumbers();
        this.flashOps();
        if (key.includes('K')) {
          key=key.replace('K','');
          this.value=+key*1000;
        } else {
          this.value=+key;
        }
        break;
      }
    }
    this.price=bet[this.handIndex()];
  }
}
