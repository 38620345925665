
        <div style="margin-left:{{gvars['center-width']}}px; width:80%"  *ngIf="!die">
           <div [ngSwitch]="state" >
               <div  *ngSwitchCase="'cards0'">
                 <span   class="card-count-dressing"
                         style="position: absolute; top:{{top}}px; left:{{gvars['center-width']+75}}px; height:25px; z-index:4">25%
                  </span>
                  <span   class="card-count-dressing"
                          style="position: absolute; top:{{top}}px; left:{{gvars['center-width']+300}}px; height:25px;z-index:4">25%
                   </span>
                   <span   class="card-count-dressing"
                           style="position: absolute; top:{{top+150}}px; left:{{gvars['center-width']+75}}px; height:25px;z-index:4">25%
                    </span>
                    <span   class="card-count-dressing"
                            style="position: absolute; top:{{top+150}}px; left:{{gvars['center-width']+300}}px; height:25px;z-index:4">25%
                     </span>
               </div>
               <div  *ngSwitchCase="'cards1'">
                 <span   class="card-count-dressing"
                         style="position: absolute; top:{{top}}px; left:{{gvars['center-width']+75}}px; height:25px; z-index:4">25%
                  </span>
                  <span   class="card-count-dressing"
                          style="position: absolute; top:{{top}}px; left:{{gvars['center-width']+300}}px; height:25px;z-index:4">25%
                   </span>
                   <span   class="card-count-dressing"
                           style="position: absolute; top:{{top+150}}px; left:{{gvars['center-width']+75}}px; height:25px;z-index:4">50%
                    </span>

               </div>
           </div>
          <div class="flex-column" >
                    <ng-container [ngSwitch]="mode">
                      <ng-container *ngSwitchCase="'manual'">
                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)">
                                   <div ngxSlickItem class="slide">
                                     <anms-oddslanding-cc  [mode]="'0'" *ngIf="pageEnabled[0]"> </anms-oddslanding-cc>
                                   </div>
                                   <div ngxSlickItem class="slide">
                                     <anms-oddslanding-cc  [mode]="'1'" *ngIf="pageEnabled[1]"> </anms-oddslanding-cc>
                                   </div>
                                   <div ngxSlickItem class="slide">
                                     <anms-oddslanding-cc   [mode]="'2'" *ngIf="pageEnabled[2]"> </anms-oddslanding-cc>
                                   </div>
                                   <div ngxSlickItem class="slide" >
                                     <anms-landing-sample-cc  delay="1200" mode="split" id='0' *ngIf="pageEnabled[3]"> </anms-landing-sample-cc>
                                   </div>
                                   <div ngxSlickItem class="slide" >
                                     <anms-landing-sample-cc  delay="1200" mode="csplit" id='1' *ngIf="pageEnabled[4]"> </anms-landing-sample-cc>
                                   </div>
                                   <div ngxSlickItem class="slide" >
                                     <anms-landing-sample-cc  delay="1200" mode="static-double" id='2' *ngIf="pageEnabled[5]"> </anms-landing-sample-cc>
                                   </div>
                                   <div ngxSlickItem class="slide" >
                                     <anms-landing-sample-cc  delay="1200" mode="double" id='3' *ngIf="pageEnabled[6]"> </anms-landing-sample-cc>
                                   </div>
                                   <div ngxSlickItem class="slide" >
                                     <anms-landing-sample-cc  delay="1200" mode="cdouble" id='4' *ngIf="pageEnabled[7]"> </anms-landing-sample-cc>
                                   </div>
                                   <div ngxSlickItem class="slide">
                                     <div style="margin-top:75px">
                                         <mat-container *ngFor="let header of list; let i=index">
                                               <mat-card  [ngClass]="i==3? 'last-panel': 'rest-panel'">
                                                     <mat-card-content>
                                                        <span style="margin-left:10px">{{i+1}}.<span style="margin-left:2px">{{header}}</span></span>
                                                     </mat-card-content>
                                               </mat-card>
                                          </mat-container>
                                    </div>
                                     <div  class="flex" style="margin-top:50px; margin-left:200px">
                                        <button mat-raised-button  [color]="'warn'" (click)="bclicked('demo')">Demo</button>
                                        <button mat-raised-button  [color]="'accent'" class="" style="margin-left:20px;" (click)="bclicked('signup')">Signup</button>
                                    </div>
                                  </div>
                             </ngx-slick-carousel>
                      </ng-container>
                       <ng-container *ngSwitchCase="'auto'">
                                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)">
                                       <div ngxSlickItem class="slide">
                                         <anms-landing-sample-cc  delay="1200" mode="split" id='1'> </anms-landing-sample-cc>
                                       </div>
                                       <div ngxSlickItem class="slide">
                                         <anms-landing-sample-cc  delay="1200" mode="csplit" id='2'> </anms-landing-sample-cc>
                                       </div>
                                       <div ngxSlickItem class="slide">
                                         <anms-landing-sample-cc  delay="1200" mode="double" id='3'> </anms-landing-sample-cc>
                                       </div>
                                       <div ngxSlickItem class="slide">
                                         <anms-landing-sample-cc  delay="1200" mode="cdouble" id='4'> </anms-landing-sample-cc>
                                       </div>

                                     <div ngxSlickItem class="slide">
                                       <div style="margin-top:75px">
                                           <mat-container *ngFor="let header of list; let i=index">
                                                 <mat-card  [ngClass]="i==3? 'last-panel': 'rest-panel'">
                                                       <mat-card-content>
                                                          <span style="margin-left:10px">{{i+1}}.<span style="margin-left:2px">{{header}}</span></span>
                                                       </mat-card-content>
                                                 </mat-card>
                                            </mat-container>
                                      </div>
                                       <div  class="flex" style="margin-top:50px; margin-left:200px">
                                          <button mat-raised-button  [color]="'warn'" (click)="bclicked('demo')">Demo</button>
                                          <button mat-raised-button  [color]="'accent'" class="" style="margin-left:20px;" (click)="bclicked('signup')">Signup</button>
                                      </div>
                                    </div>

                                </ngx-slick-carousel>
                       </ng-container>
                     </ng-container>
            </div>

          </div>
