import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { MobileRouterService } from '../../services/global.service';
import { globalVars, imageDims } from '../../services/global.service';
import { GenericTopTopAction} from '../store/generic.action.reducer';
import { Store } from '@ngrx/store';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { FADEOUT, FADEIN,n2Flash} from '../../services/common.service';



const m2c = (card) => `/assets/cards/${card}${globalVars['image'].dims}.png`;

@Component({
  selector: 'anms-oddslanding-cc',
  templateUrl: './oddslpage.component.html',
  styleUrls: ['./oddslpage.component.scss', '../styles.scss'],
  animations: [
    trigger('flash', [
      transition(`* => ${FADEIN}`, [
        style({ opacity: 0 }),
        animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
      ]),

      transition(`* => ${FADEOUT}`, [
        animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
      ])
    ]),
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)'
      })),
      transition('default => flipped', [
        //animate('400ms')
        animate('1000ms')
      ]),
      transition('flipped => default', [
        //animate('200ms')
        animate('500ms')
      ])
    ])
  ]
})


export class OddsLandingPageComponent implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  //columns=['Count', 'High count', 'Low count', 'Net count', 'Spread', 'comments'];
  //
  // supported modes are: split, nsplit, double, ndouble
  //
  @Input() mode = 0;
  pageEnabled=false;
  // state: 'default'| 'flipped' = 'default';
  fclass:any;
  bclass:any;
  pageWidth = 0;
  cards={
    0: {
      dealer: [m2c('KC'), m2c('Gray_back'), m2c('5H')],
      player: [m2c('KH'), m2c('4C')],
      state: 'default',
      next:   {card: m2c('JC'), state: 'default'}
    },

    1: {
      dealer: [m2c('KC'), m2c('Gray_back'), m2c('7H')],
      player: [m2c('9H'), m2c('2C')],
      state: 'default',
      next:   {card: m2c('4C'), state: 'default'}
    },

    2: {
      dealer: [m2c('6C'), m2c('Gray_back'), m2c('5H')],
      player: [m2c('9H'), m2c('8C')],
      state: 'default',
      next:   {card: m2c('7C'), state: 'default'}
    },
  };

  margins = {
    top:0,
    left:0,
    height:0
  };
  easyBust={value:'EASY'};
  colorObj={value:'accent'}
  fb: any;
  constructor(private store:Store<any> ) {
    this.fb = new n2Flash(this.easyBust, ['EASY', 'BUST'], this.colorObj, ['accent', 'warn']);
  }

   flipit(index, type) {
     if (type=='next') {
       this.cards[index].next.state = this.cards[index].next.state == 'default'? 'flipped': 'default';
       return;
     }
     this.cards[index].state = this.cards[index].state == 'default'? 'flipped': 'default';
     if (index=='2' && this.cards[index].state == 'default') {
        const bcard = m2c('5H');
        setTimeout(() => {
          this.cards[index].dealer[2] = this.cards[index].dealer[2] == bcard ? m2c('10H'): bcard;

        }, 300);
     }
   }

   ngOnDestroy() :void {
     // this.cards={};
     this.fb.unflash();
    }

   ngOnInit() :void{
     GenericTopTopAction(this.store, [ "4 cards drawn",'25% weight per card', '25/75 percent odds'], 'monkey');
      this.bclass='leave';
      this.fclass='enter';
      this.fb.flash();

      imageDims();
      let cards = globalVars['cardsOut'];
      const image = globalVars['image'];
      this.margins.height=image.card.height+20;
      this.pageWidth = image.card.width*5;
      this.margins.top=Math.ceil(image.card.height*3/2);
      // this.margins.left=Math.ceil(image.card.width*3/2);
      this.margins.left=(image.card.width*2);

  }
}
