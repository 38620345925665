
 <div class="scene">
      <div class="flip"  [@cardFlip]="state">
         <div class="face back">
           <mobile-base-cards (event)="eventHandler($event)" style="margin-top:15px" [id]="'1'"></mobile-base-cards>
         </div>
         <div class="face front" >
           <mobile-base-cards (event)="eventHandler($event)" style="margin-top:15px" [id]="'0'"></mobile-base-cards>
         </div>
    </div>
 </div>

 <!-- <div class="flip"  [@cardFlip]="state"> -->
