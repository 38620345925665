export interface CardsBase {
   card:any;
   badge:boolean;
   value?: number;
};

export interface InsertCardsDataUnit {
   typeof:string;
   cards?: Array<any>;
   id: number;
   msg?:any;
   value?:any;
};
export interface PlayCardsDataUnit {
   typeof:string;
   cards: Array<any>;
   id: number;
   label:string;
   sum?:number;
};

export interface CardBadgeDataUnit {
   typeof:string;
   id: number;
   msg:any;
   value:any;
};

export enum CardBadgeEnum {
   cardbadge_both,
   cardbadge_card,
   cardbadge_badge,
   cardbadge_flip_card,
   cardbadge_flip

};

export enum MobileDrawCardEnum {
   cards,
   badge,
   dbadge,
   erase,
   sbadge,
   reset,
};
export interface MobileDrawCardDataUnit {
  type:MobileDrawCardEnum;
  typeof: string;
  id:any;
}
export interface MobileCountCardDataUnit {
  count: string;
  typeof: string;
}
export interface MobileCardsDrawnDataUnit {
  typeof: string;
}
