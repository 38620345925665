import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { isJack,MobileRouterService, globalVars,getMethodDesc,imageDims, check_credit, pageDims} from '../../services/global.service';
import { Store } from '@ngrx/store';
import {BaseListComponent} from '../shared/list/component';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import { GenericTopTopAction,GenericSlideConceptTrain,GenericSettingRestoreFromCach, GenericMultiDrawCards, GenericMultiResetCards} from '../store/generic.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';
import { nFlash} from '../../services/common.service';
// import {MobileDrawCardResetAction} from '../store/cards.action.reducer';
import { DealerService} from '../../services/deck.service';
@Component({
  selector: 'anms-mobile-adding-strategy',
  templateUrl: './adding.strategy.component.html',
  styleUrls: ['../styles.scss','./adding.strategy.component.scss'],
  animations: [
        CommonListAnimations.flash
  ]
})
export class MobileAddingStrategyComponent  implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  subscriptions=[];
  vars=globalVars;
  disabled={forward:false, backward:true, audio: false, dbackward:false};
  method=globalVars['method'];
  margin={top:10};
	settingCurrentlyActive=false;
  cachedDisabledKeys=[];
  flashes = {};

  topMargin=0;

  postActions:any;

  tpage={width:0, height:0};

  statements= {

    mit:[
          [// one set
            "Count starts at '0'",
            "add 1 for each low-cards",
            "subtract 1 for each high-cards",
            // "press next->"
           ],
           [
             "Press any of the below buttons to see some example of low or high counts"
           ],
           [
             "Press any of the below buttons to see some example of mix cards counts"
           ]

         ],
    advance:[
           [
             "Put two card counts side by side",
             "First high cards count followed by the low cards count",
             "For each high cards, add '1' to the high cards count",
             "For each low cards, add '1' to the low cards count",
             "Note: Don't add up these two counts, keep them seperate",
             "Your initial count is '0 0'",
             "press next for see examples"
           ],
           [
             "Press any of the below buttons to see example of low or high cards counts"
           ],
           [
             "Counts containing mixture of low and high cards:",
             "Maintain two seperate counts, one for low and one for high cards count",
             "For example 2 high cards followd by 4 low cards",
             "Gives the count of '2 4'",
             "It is pronounced 'two four' and not 'twentyfour'",
             "press next for some examples"

           ],

           [
             "Press any of the below buttons to see example of mix cards counts"
           ],
           [
             "Calculate the running count:",
             "Objective here is to end up with either low or high card count",
             "Subtract these two counts from one another",
             "For example:",
             "if the count is '2 4', running count is '0 2'",
             "if the count is '4 2', running count is '2 0'",
             "press next for some examples"
           ],

           [
             "Press any of the below buttons to see example of running counts",
             "Note the last card is showing the running-count in red circle"
           ],
       ]
  };

  audioEnable=false;
  slidesCount=0;
  slideConfig= {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    arrows:false,
    "infinite": false,
     'swipeToSlide': true,

  };
  settingWasActive=false;

  mssapPlaylist= [
  {
    title: 'Audio Title',
    link: 'assets/audio/First-sample.m4a',
    artist: 'Jack Count',
    duration: 90000
  }
];
audioCount = {
  ten:0,
  mit:0,
  advance:0
};
clearCard = false;
// ncards = 0;
mit=false;
mix = {
  buttons:['+4', '-4', '-6','+6', '2 4', '4 2', '3 5', '5 3'],
  count:{
    '5 3':{cards:8, count: (5<<4) | 3},
    '3 5': {cards:8, count:(3<<4) | 5},
    '4 2': {cards:6, count:(4<<4) | 2},
    '2 4': {cards:6, count:(2<<4) | 4},
    '-6': {cards:12, count:(9<<4) | 3},
    '+6': {cards:12, count:(3<<4) | 9},
    '-4': {cards:8, count:(6<<4) | 2},
    '+4': {cards:8, count:(2<<4) | 6}
  }
};
buttonsGroup = {
  mit: [
        [{key:'+3', color:'accent'}, {key:'-3', color:'warn'},  {key:'+7', color:'accent'}, {key:'-7', color:'warn'}],
        [{key:'+4', color:'accent'}, {key:'-4', color:'warn'},  {key:'+6', color:'accent'}, {key:'-6', color:'warn'}]
      ],
 advance: [
   [{key:'0 3', color:'accent'}, {key:'3 0', color:'warn'},  {key:'0 7', color:'accent'}, {key:'7 0', color:'warn'}],
   [{key:'2 4', color:'accent'}, {key:'4 2', color:'warn'},  {key:'3 5', color:'accent'}, {key:'5 3', color:'warn'}]
 ]
};

buttons = [];
flashed=[];
page = 0;
activeButtons = [];


marginTop=0;
buttonPressed = false;
buttonWidth=0;

pageMapping = {
  mit: {
    1:0, 2:1
  },
  advance: {
    1:0, 3:1, 5:1
  },
  valid: {
    mit: [1,2],
    advance: [1,3,5]
  }
};
buttonsMargins = {left:0, top:0};
buttonPages() {
  return this.pageMapping.valid[this.method].includes(this.page);
}
disableButtons(value) {
  for(const key in this.disabled) {
    if (value && this.disabled[key]) {
      this.cachedDisabledKeys.push(key);
    }
    this.disabled[key] = value;
  }
  if (!value) {
    for(const key of this.cachedDisabledKeys) {
      this.disabled[key] = true;
    }
    this.cachedDisabledKeys =[];
  }
}

getButtons() {
  const index = this.pageMapping[this.method][this.page];
  //alert(`index:${index}`);
  this.buttons = this.mit ? this.buttonsGroup.mit[index]: this.buttonsGroup.advance[index];
}

getActiveButtons() {
  for (const elm of this.buttons) this.activeButtons.push(elm.key);
}


flashAll() {
  this.buttons.map((elm)=> {
       if(!this.flashed.includes(elm.key)) this.flashes[elm.key].flash();
     });

  this.flashed = [...this.activeButtons];
}

unflashAll() {
  this.flashed.map((elm)=>this.flashes[elm].unflash());
  this.flashed=[];
}

unflashExcept(key) {
  this.flashed.map((elm)=> {
    if (key!=elm) this.flashes[elm].unflash()
  });
  this.flashed = [key];
}

afterChange(event:any):void {

    if (this.flashed.length) {
      this.unflashAll();
    }

    if (!event.currentSlide) {
      this.disabled.forward=false;
      this.disabled.backward=true;
      return;
    }
    if ((this.slidesCount-1)==event.currentSlide) {
      this.disabled.forward=true;
      this.disabled.backward=false;
      return;
    }
    this.disabled.forward=false;
    this.disabled.backward=false;
}



setPlayList() {
  switch(this.method) {
    case 'mit': {
      switch(this.audioCount.advance) {
        case 0: {
          this.mssapPlaylist[0].title = "Classical high-low intro";
          this.mssapPlaylist[0].link = "assets/audio/mit/MIT-1.m4a";
          this.mssapPlaylist[0].duration = 30000;
          return;
        }
        case 1: {
          this.mssapPlaylist[0].title = "Classical high-low first example";
          this.mssapPlaylist[0].link = "assets/audio/mit/MIT-2.m4a";
          this.mssapPlaylist[0].duration = 43000;
          return;
        }
        case 2: {
          this.mssapPlaylist[0].title = "Classical high-low second example";
          this.mssapPlaylist[0].link = "assets/audio/mit/MIT-3.m4a";
          this.mssapPlaylist[0].duration = 100000;
          return;
        }

        default : return
      }
    }
    default: {
      switch(this.audioCount.advance) {
        case 0: {
          this.mssapPlaylist[0].title = "Revised high-low intro";
          this.mssapPlaylist[0].link = "assets/audio/revised-high-low/Revised-high-low-1.m4a";
          this.mssapPlaylist[0].duration = 90000;
          return;
        }
        case 1: {
          this.mssapPlaylist[0].title = "Revised high-low 2nd example";
          this.mssapPlaylist[0].link = "assets/audio/revised-high-low/Revised-high-low-2.m4a";
          this.mssapPlaylist[0].duration = 42000;
          return;
        }
        case 2: {
          this.mssapPlaylist[0].title = "Revised high-low 3rd example";
          this.mssapPlaylist[0].link = "assets/audio/revised-high-low/Revised-high-low-3.m4a";
          this.mssapPlaylist[0].duration = 38000;
          return ;
        }
        case 3: {
          this.mssapPlaylist[0].title = "Revised high-low fourth example";
          this.mssapPlaylist[0].link = "assets/audio/revised-high-low/Revised-high-low-4.m4a";
          this.mssapPlaylist[0].duration = 37000;
          return ;
        }

        default: return ;
      }
    }
  }
}

constructor(
  public dealerService: DealerService,
    public store: Store<any>,
     private router: MobileRouterService
            ) {
	      // globalVars['state'] = 'train-concept';
        // super(store);

    }

  ngOnDestroy() :void {
     for (const elm of this.subscriptions)
          elm.unsubscribe();
    MobileSendKeyRoute(this.store,[{key:"reset"}]);
    if (this.flashed.length) {
      this.unflashAll();
    }
    globalVars['modifyDeck'] = {count:0, type: ''};
    // if (this.buttonPressed) {
    //   (this.store);
    // }
    globalVars['netcount']=false;
    globalVars['cardsOut'] = 3;
   }

   drawCard() {
     globalVars['netcount'] = this.page==5;
     this.dealerService.shuffle();
     GenericMultiDrawCards(this.store);
   }


   movePage(key) {
     if (key=='forward') {
       this.slickModal.slickNext();
       return;
     }
     this.slickModal.slickPrev();
   }


   processButton(key) {
     this.unflashExcept(key);
     let cards;
     let type = this.mix.buttons.includes(key)? 'mix': 'other';
     let mcards;
     if (type =='mix') {
       const e = this.mix.count[key];
       cards = e.count;
        mcards = e.cards;
     } else if (this.method=='mit') {
       const fchar = key[0];
       type = key.includes('+') ? 'low': 'high';
       cards = ['+','-'].includes(fchar)? +key[1]: +fchar;
       globalVars['cardsOut'] = cards;
     } else {
        type = key[0]=='0'? 'low': 'high';
        cards = type =='low'? +key[2]: +key[0];
     }
     globalVars['modifyDeck'] = {count:cards, type: type};
     globalVars['cardsOut'] = type=='mix'? mcards: cards;
     // this.ncards = cards;
     this.drawCard();
     this.disableButtons(true);
     return;
   }


   removeCardsSetbuttons(key) {
       this.unflashAll();
       this.postActions=()=> {
         this.getNewButtons();
         this.movePage(key);

       }
   }


   handleAudio(key) {
     this.audioCount.advance += key =='forward'? 1: -1;
     this.setPlayList();
     this.audioEnable = false;
   }

   // control clicked
   cclicked(key) {
     if (key=='audio') {
       this.audioEnable = this.audioEnable? false: true;
       return;
     }
      this.page += key == 'forward'? 1: -1;
      this.handlePage(key);
   }

   // button clicked

   clicked(key) {
     this.buttonPressed = true;
     this.processButton(key);
   }



   handlePage(key) {
     this.buttons=[];
     GenericMultiResetCards(this.store);
     setTimeout(() => {
       if (this.buttonPressed) {
         this.buttonPressed = false;
         if (this.buttonPages()) this.getNewButtons();
         this.movePage(key);
       } else {
          if (this.buttonPages()) this.getNewButtons();
          this.movePage(key);
       }
     }, 300);

     this.setTopMessage();
   }

   getNewButtons() {
     this.getButtons();
     this.getActiveButtons();
     this.flashAll();
   }

   eventHandler(e:any):void {
     switch(e) {
       case 'removed': {
         if (this.postActions) {
 	    this.postActions();
	 }
         break;
       } case 'drawn': {
          this.flashAll();
          this.disableButtons(false);
         break;
       } default: break;
     }
   }

  setTopMessage() {
    if (this.method=='mit') {
      switch(this.page) {
        case 0: {
          GenericTopTopAction(this.store, [getMethodDesc(''), "Add 1 for any low and -1 for any high card"], 'tigger');
          return;
        }
        case 2:
        case 1: {
          GenericTopTopAction(this.store, [getMethodDesc(''), "Try all 4 buttons"], 'tigger');
          return;
        }
        default: return;
      }
      return;
    }
    switch(this.page) {
      case 0: {
        GenericTopTopAction(this.store, [getMethodDesc(''), "90% addition and only 10% subtraction"], 'tigger');
        return;
      }
      case 1: {
        GenericTopTopAction(this.store, [getMethodDesc(''), "Examples of low or high count",
                        "Try all 4 buttons"], 'tigger');
        return;
      }
      case 2: {
        GenericTopTopAction(this.store, [getMethodDesc(''), "High and low card count",
                        "Maintain 2 seperate count",
                        "High card count followd by low card count"],
                         'tigger');
        return;
      }
      case 3: {
        GenericTopTopAction(this.store, [getMethodDesc(''),
                  "Examples of low and high count",
                  "Each card only effects one count"  ], 'tigger');
        return;
      }
      case 4: {
        GenericTopTopAction(this.store, [getMethodDesc(''),
                  "Running count calculation",
                  "Do this at the end of the hand not during"], 'tigger');
        return;
      }
      case 5: {
        GenericTopTopAction(this.store, [getMethodDesc(''),
                  "Examples of running-count",
                  "Press the button and pay attention to the last card with the red circle"
                  ], 'tigger');
                  break;
      }
    }

  }

   istate() {
        let note='';
        let difficulty;
        const method=getMethodDesc('');
        this.margin.top = 30;
        if (isJack()) {
            this.slidesCount=this.statements.advance.length;
            // this.margin.top=20;
        } else if (this.method=='mit') {
          this.slidesCount=this.statements.mit.length;
          // this.margin.top=25;
        }

        BottomAppleModify(this.store, { enable:['backward','lastIcon'], disable:['forward'],lastIcon:'settings'});
   }

   setTopMargin() {
     this.marginTop = this.buttons.length? 100: globalVars['image'].card.height*3 + 100;
   }

   buildFlash() {
     const buttonGroup = this.method == 'mit'? this.buttonsGroup.mit : this.buttonsGroup.advance;
     for (const list of buttonGroup) {
       for (const elm of list) {
         this.flashes[elm.key] = new nFlash;
       }
     }
   }

   ngOnInit() :void{
        check_credit(this.router);

        globalVars['vpath']="concept/adding-count";
      	imageDims();
        this.buildFlash();
        this.istate();
        this.setTopMessage();
        this.setPlayList();
        this.setTopMargin();
        this.tpage={...pageDims()};
        this.buttonsMargins = {left: Math.ceil(this.tpage.width/2)-40, top: this.tpage.height-100};

        const image = globalVars['image'];
        this.buttonWidth = globalVars['mobileDevice']? 10: 30;
          this.mit = globalVars['method'] == 'mit';
        this.subscriptions.push(
            this.store
              .select('mobileTopBottom')
              .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='child'))
              .subscribe((data: KeyPressedDm) => {
                  switch(data.key) {
                    case 'home' : {
                        setTimeout(() => {
                          this.router.navigate('mobile/home');
                        }, 200);
                      break;
                    }
                    case 'backward' : {
                           if(this.settingCurrentlyActive) { return; }
                           let timeout=200;
                           // if (this.buttonPressed ) {
                           //   MobileDrawCardResetAction(this.store);
                           //   timeout=1400;
                           // }
                           setTimeout(() => {
                             this.router.navigate('mobile/train');
                           }, timeout);
                         break;
                    } case 'settings': {
                          // this.istate();
                          let timeout=0;
                          // if (this.buttonPressed) {
                          //   MobileDrawCardResetAction(this.store);
                          //   timeout=1400;
                          // }
                           globalVars['setting'] = {
                            func: GenericSlideConceptTrain,
                            route:'mobile/adding-strategy'
                          };
                          setTimeout(() => {
                            this.router.navigate('mobile/setting-train');
                          }, timeout);
                           break;
                       }
                  }
                           })
          );
         this.subscriptions.push(
               this.store
                 .select('genericReducer')
                 .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                 .subscribe((data: GenericDataUnit) => {
                   if (data.action==GenericDataUnitEnum.die) {
                     return;
                   }
                 }
               )
          );




      }

onEnded(e) {

}



  }
