import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { MobileRouterService, globalVars, maxCardsOut,decksMapping,ManageButtonColors} from '../../services/global.service';
import { DealerService } from '../../services/deck.service';
import {BaseListComponent} from '../shared/list/component';
import {KeyPressedDm} from '../store/bottom.model';
import {MobileSendKeyRoute,  BottomResetMenuPressed,BottomAppleModify} from '../store/bottom.action.reducer';
import { map, filter } from 'rxjs/operators';
import { GenSettingAction } from '../../store/gen-setting.action.reducer';
import { GenSettingEnum } from '../../store/gen-setting.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {GenericTopTopAction,GenericSettingDone} from '../store/generic.action.reducer';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';


interface locationIf {
  row: number;
  col: number;
};

interface Selected {
  method: string;
  decks: string;
  cards: string;
  speed: string
};
const ENTRY_PER_ROW=4;

enum PageEnum {
  method,
  voice,
  decks,
  risk,
  cards,
  speed,
  soft17,
  displayCount,
  splits
};
@Component({
  selector: 'anms-mobile-setting-train',
  templateUrl: './setting.train.component.html',
  styleUrls: ['../styles.scss']

})


export class MobileTrainSettingComponent  implements OnDestroy, OnInit  {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  // disabled={forward:false, backward:true};

   speed={
     disabled:{}
   };
   subscriptions= [];
   enabled = {
	   settings: false,
	   decks:false,
     method:false,
     cards:false,
     speed:false,
     soft17:false,
     displayCount:false,
     splits:false,
     configCards: false,
     voice:false
   };
   ncards={disabled:{}};

   mouseClass= {
     method:{ten:'leave', jack:'leave', mit:'leave', single:'leave', chosen:'advance', advance:'leave'},
     soft17: {
       stay:'leave',
       hit:'leave',
       chosen:'stay'
     },
     count: {
       show:'leave',
       hide:'leave',
       chosen: 'show'
     },

     splits: {2:'leave', 3: 'leave', 4: 'leave', 5: 'leave', chosen:'2'},
     decks: {single:'leave', double:'leave', six:'leave', chosen:'double'},
     risk:{high:'leave', moderate:'leave', low:'leave', chosen:'moderate'},
     voice : {yes:'leave', no: 'leave', chosen:'yes'},
     speed: {
       600:'leave',400:'leave',
       800:'leave', 1000:'leave', 1200:'leave', 1400:'leave',
       1600:'leave', 1800:'leave', 2000:'leave', 2200:'leave',
       2400:'leave', 2600:'leave', 2800:'leave', 3000:'leave',
       3200:'leave', 3400:'leave', 3600:'leave', 3800:'leave',
       4000:'leave', 4200:'leave', 4400:'leave', 4600:'leave',
       chosen:'1200'
     },

     cards: {
       3:'leave', 4:'leave', 5:'leave', 6:'leave', 7:'leave', 8:'leave',
       9:'leave', 10:'leave', 11:'leave', 12:'leave', 13:'leave', 14:'leave',
       15:'leave', 16:'leave', 17:'leave', 18:'leave',19:'leave', 20:'leave',
       21:'leave', 22:'leave', 23:'leave', 24:'leave', chosen:'3'
     }

   };
   cardsState="Low and high cards are balanced";


   slideConfig= {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
    arrows: false,
    'swipeToSlide': true,
  };
  vars = globalVars;
  mobile=false;
  mapping = {
    1: 'single',
    2: 'double',
    6: 'six',
    single: 1,
    double: 2,
    six: 6
  };
  mode='count';
  cpage=0;
  npages=0;
  cached={
    enabled:[],
    disabled:[]
  };
  decks={single:false, double:false, six:false};
  maxcards=0;
  disabled={forward:true, backward:true};
  pageEnabled=false;
  nextPage='';
  speedAttr = {
     base: 4200,
     interval: 200,
     cols:4,
     cost: 200*4
  };

  gvars:any;
  mbc:any;
  forceBasicSpeed=false;
 countRange = 1;
 count_range = {
   1: 8,
   2: 16,
   6: 64
 }
 configCount = '0';
  mapMethodToRes={
    mit: 'Classic high-low count',
    advance:'Revised high-low count',
    jack: 'Revised high-low count',
    single: 'Revised high-low single deck',
    tens: 'Count-tens'

  };

  // to make sure the colors are set by globalVars; so anytime this page is
  // up, it reflects what was set before
  globalColors() {
    for (const key of ['method', 'soft17', 'count', 'splits', 'risk', 'voice', 'speed']) {
      this.mbc.swap(globalVars[key], key);
    }
    // ncards , decks
    const map = {
        1: 'single',
        2: 'double',
        6: 'six'
      };
    let value=map[globalVars['ndecks']];
    this.mbc.swap(value, 'decks');
    value=globalVars['cardsOut'];
    this.mbc.swap(value, 'cards');
  }


  setCountRange() {
    this.countRange = this.count_range[globalVars['ndecks']];
  }
  speedDistr(page=0) {
    const attr=this.speedAttr;
    const base = attr.base - (attr.cost*page);
    return [base, base-attr.interval, base-(attr.interval*2), base-(attr.interval*3)]
  }

  constructor(public store: Store<any>,
              private dealerService: DealerService,
              private routeService:MobileRouterService) {
                this.mbc=new ManageButtonColors(this.mouseClass);

    }
  cachit(enabled, disabled) {
      this.cached.enabled=enabled;
      this.cached.disabled=disabled;
  }

  fill(fill:any, n:any):any {
    let lst=[];
    for (let i=0; i!=n; i++) {
      lst=lst.concat([fill]);
    }
    return lst;
  }




  cardsLoc(value:any) :any{
    const dist= value -3;
    const row = Math.floor(dist/ENTRY_PER_ROW)
    return [row, dist-(row*ENTRY_PER_ROW)];

  }

  speedLoc(value:any): locationIf {
      const dist= Math.floor((value -800)/200);
      const row = Math.floor(dist/ENTRY_PER_ROW)
      return {row:row, col:dist-(row*ENTRY_PER_ROW)};
  }
  _nxtPage() {
    if (this.nextPage=='speed') {
      this.nextPage='cards';
      return 2;
    }
    this.nextPage='speed';
    return 1;
  }
  afterChange(event:any) {

    this.msgTop(['speed', 'cards'].includes(this.nextPage) ? this._nxtPage(): event.currentSlide);
  }

  next() {
    // if (this.cpage==this.npages) return;
    this.cpage++;
    if (globalVars['setting']['single']) {
        this.disabled.forward=this.cpage>=1;
    } else {
        this.disabled.forward=(this.cpage+1)==this.npages;
    }
    this.disabled.backward=false;

    this.slickModal.slickNext();
  }

  prev() {
    // if (this.cpage==0) return;
    this.cpage--;
    this.disabled.backward=this.cpage==0;
    this.disabled.forward=false;
    this.slickModal.slickPrev();
  }

  click( key:any) {
    if (key=='done') {
      let value;
      if (this.configCount != '0') {
         value = this.configCount.slice(1, this.configCount.length);
        if (+value > this.countRange) {
          alert (`Going over the range ${this.countRange}`);
          return;
        }

        if (globalVars['method'] == 'mit') {
          this.dealerService.configureCards(this.configCount );
        } else {
          const sign = this.configCount[0]=='+'? 'L': 'H';
          this.dealerService.configureCards(`${value}${sign}`);
        }
        globalVars['no_shuffle']=true;

      }

      this.done();
      return;
    }
     if (key=='forward') {
       this.next();
       return;
     }
       this.configCount='0';
       this.prev();
  }

  deckdisable(enable=false) {
    for (const deck in this.decks) {
      this.decks[deck]=enable;
    }
  }
  singleSetup(enable=true) {
    // this.singleDeckCards(enable);
    this.deckdisable(enable);
  }

  clicked( key:any, value:any):void {
     if (['cbackward', 'cardConfig'].includes (key)) {
       if (key=='cbackward') {
          this.configCount = this.configCount.slice(0, this.configCount.length-1);
          return;
       }
       if (this.configCount =='0') {
         this.configCount = `${value}`;
         return;
       }
       if (['add', 'remove'].includes(value)) {
           const _value = value=='add'? '+': '-';
           const c = this.configCount;
           this.configCount = `${_value}${this.configCount}`;
           const label = value=='add' ? 'high': 'low';
           this.cardsState =  `${c} more ${label}-cards left in the deck`;
           return;
       }

       this.configCount = `${this.configCount}${value}`;
       return;
     }
     if (key=='cards' && this.ncards.disabled[value]) return;
     this.mbc.swap(value,key);
    switch(key) {
     case 'method' : {
        // this.colorMethod(value);

        globalVars['method'] = value;
        if (value=='single') {
          // this.colorDecks('single');
          globalVars['ndecks']=this.mapping['single'];
          this.dealerService.setDecks();
          const decks = this.mapping[globalVars['ndecks']];
          // this.colorDecks(decks);

          this.singleSetup();
        } else {
          if (this.decks.single) {
            this.singleSetup(false);
          }
          this.setCountRange();
        }
        // GenericTopTopAction(this.store, this.checkForFit(this.methodMsg() ), 'tigger');

        break;
      } case 'decks' : {
         // this.colorDecks(value);
         globalVars['ndecks']=this.mapping[value];
         this.dealerService.setDecks();
         const decks = this.mapping[globalVars['ndecks']];
         this.setCountRange();

         break;
      } case 'cards': {
        globalVars['cardsOut']=+value;

        break;
      } case 'speed': {
        globalVars['speed']=+value;
        this.forceBasicSpeed=true;
        // this.colorSpeed(speed);
        break;
      } case 'risk': {
        globalVars['risk'] = value;
        // this.swapColors(this.colors.risk, value);
        break;
      } case 'soft17': {
        globalVars['soft17'] = value;

        break;
      }case 'splits': {
        globalVars['splits'] = value;

        break;
      } case 'voice': {
          globalVars['voice'] = value=='yes'? true: false;
          break;
      }
      case 'count': {
          globalVars['count'] = value;

          break;
      }
    }
    this.next();
  }

  checkForFit(list) {
    if (globalVars['mobileDevice']) {
      return list.slice(0,2);
    } return list;
  }
  selected(value) {
    return `Selected:${value}`;
  }
  methodMsg() {
    const selected = this.mapMethodToRes[globalVars['method']]
    return ["Settings","Select the card-counting method:", this.selected(selected)];
  }
  deckMsg() {
    return ["Settings","Select the decks:", this.selected(decksMapping())];
  }
  cardsMsg() {
    return   ["Settings","Select number of cards to come out: ", this.selected(globalVars['cardsOut'])];
  }
  speedMsgBase() {
    const speed=(globalVars['speed']/1000).toPrecision(2);

    return   ["Settings","Select the card speeds", `Selected: ${speed} sec`];
  }
  speedMsg() {
    if (this.forceBasicSpeed)   return this.speedMsgBase();
    if (this.modeInSetting(globalVars['setting'])) {

      const speed=(globalVars['setting']['speed']/1000).toPrecision(2);

      return   ["Settings","Select the card speeds:[800, 600, 400] ms", `Selected: ${speed} sec`];
    }
    return this.speedMsgBase();
  }
  soft17msg() {
    return   ["Settings","Select house rule on soft 17", this.selected(globalVars['soft17'])];
  }
  splitsMsg() {
    return   ["Settings","Select house rule for splits", this.selected(globalVars['splits'])];
  }
  countMsg() {
    return   ["Settings","Select displaying card-count", this.selected(globalVars['count'])];
  }
  countConfigMsg() {
      return   ["Settings","Presetting the card-count"];
  }



  countTrainingMsg(page) {
    if (this.enabled['cards']) {
      switch(page) {
        case 0: { GenericTopTopAction(this.store, this.checkForFit(this.methodMsg() ), 'tigger'); break;}
        case 1: { GenericTopTopAction(this.store, this.checkForFit(this.deckMsg()), 'tigger'); break;}
        case 2: { GenericTopTopAction(this.store, this.checkForFit(this.cardsMsg()), 'tigger'); break;}
        case 3: { GenericTopTopAction(this.store, this.checkForFit(this.speedMsg()), 'tigger'); break;}

      }
      return;
    }
    switch(page) {
      case 0: { GenericTopTopAction(this.store, this.checkForFit(this.methodMsg()), 'tigger'); break;}
      case 1: { GenericTopTopAction(this.store, this.checkForFit(this.deckMsg()), 'tigger'); break;}
      case 2: { GenericTopTopAction(this.store, this.checkForFit(this.speedMsg()), 'tigger'); break;}

    }

  }
  playTrainingMsg(page) {
    switch(page) {
      case 0: { GenericTopTopAction(this.store, this.checkForFit(this.methodMsg()), 'tigger'); break;}
      case 1: { GenericTopTopAction(this.store, this.checkForFit(this.deckMsg()), 'tigger'); break;}
      case 2: { GenericTopTopAction(this.store, this.checkForFit(this.countConfigMsg()), 'tigger'); break;}
      case 3: { GenericTopTopAction(this.store, this.checkForFit(this.soft17msg()), 'tigger'); break;}
      case 4: { GenericTopTopAction(this.store, this.checkForFit(this.countMsg()), 'tigger'); break;}
      case 5: { GenericTopTopAction(this.store, this.checkForFit(this.splitsMsg()), 'tigger'); break;}

    }
 globalVars['mode'] = 'license';
  }
  onlineTrainMsg(page) {

    switch(page) {
      case 0: { GenericTopTopAction(this.store, this.checkForFit(["Online-assist setting","Enable voice recognition"]), 'tigger'); break;}
      case 1: { GenericTopTopAction(this.store, this.checkForFit(this.deckMsg()), 'tigger'); break;}
      case 2: { GenericTopTopAction(this.store, this.checkForFit(["Online-assist setting","Select level of the risk", "Recommended moderate level"]), 'tigger'); break;}
      case 3: { GenericTopTopAction(this.store, this.checkForFit(this.soft17msg()), 'tigger'); break;}
      case 4: { GenericTopTopAction(this.store, this.checkForFit(this.splitsMsg()), 'tigger'); break;}

    }

  }
  licenseTrainMsg(page) {

    switch(page) {
      case 0: { GenericTopTopAction(this.store, this.checkForFit(this.methodMsg()), 'tigger'); break;}
      case 1: { GenericTopTopAction(this.store, this.checkForFit(this.deckMsg()), 'tigger'); break;}
      case 2: { GenericTopTopAction(this.store, this.checkForFit(this.speedMsg()), 'tigger'); break;}
    }
  }
  learnTrainMsg(page) {
    switch(page) {
      case 0: { GenericTopTopAction(this.store, this.checkForFit(this.methodMsg()), 'tigger'); break;}
      case 1: { GenericTopTopAction(this.store, this.checkForFit(this.deckMsg()), 'tigger'); break;}
      case 2: { GenericTopTopAction(this.store, this.checkForFit(this.cardsMsg()), 'tigger'); break;}
      case 3: { GenericTopTopAction(this.store, this.checkForFit(this.speedMsg()), 'tigger'); break;}
    }

  }
  conceptTrainMsg(page) {
    GenericTopTopAction(this.store,this.methodMsg(), 'tigger');
  }
  betTrainMsg(page) {
    GenericTopTopAction(this.store, this.deckMsg(), 'tigger');

  }
  msgTop( page) {
    switch(this.mode) {
      case 'count': { this.countTrainingMsg(page); return;}
      case 'play': { this.playTrainingMsg(page); return;}
      case 'online': { this.onlineTrainMsg(page); return;}
      case 'license': { this.licenseTrainMsg(page); return;}
      case 'concept': { this.conceptTrainMsg(page); return;}
      case 'bet': { this.betTrainMsg(page); return;}
      case 'learn': { this.learnTrainMsg(page); return;}
    }

  }

  train4(key) {
    let list=[];
    switch(key) {
      case 'count' :{list=['method','decks','cards', 'speed']; break;}
      case 'learn' :{list=['method','decks','cards', 'speed']; break;}
      case 'play' :{list=['method', 'decks','soft17', 'displayCount', 'splits', 'configCards']; break;}
      case 'online' :{list=['voice','decks','risk','soft17', 'splits']; break;}
      case 'license' :{list=['method', 'decks', 'speed']; break;}
      case 'concept' :{list=['method']; break;}
      case 'singleCard' :{list=['cards','speed']; break;}
      case 'singleSpeed' :{list=['speed']; break;}
      case 'bet' :{list=['decks']; break;}
    }
    if (key=='license' || globalVars['adjustSpeed']) this.adjustSpeed();
    if (globalVars['adjustSpeed']) {
      globalVars['adjustSpeed']=false;
      if (	globalVars['dropCards'] && list.includes('cards')) {
        list=['method', 'decks','speed'];
      }
    }
    // alert(`key:${key}, list:${list}`)
    for (let elm of list) {
      this.enabled[elm] = true;
    }

    if (list.includes('configCards')) {
      this.setCountRange();
    }

    this.npages=list.length;
    this.cpage=0;
    this.mode=key;
    this.msgTop(0);

    // const enableList=['lastIcon'];
    // if (list.length>1) {
    //    enableList.push('forward');
    // }
    if (list.length>1) {
      this.disabled.forward=false;
    }
  }

  // singleDeckCards(disable=true) {
  //    this.maxcards=disable?12:24;
  // }


  discolorSpeed(certification=true) {
    const mcls = this.mouseClass.speed;
    const slist=[800, 600, 400];
    for(let i=400; i!=4600; i+=200) {
      if (!slist.includes(i)) {
        this.speed.disabled[i]=true;
          mcls[i]="";
      }
    }
    this.mbc.swap('800', 'speed');
    mcls[`1200`]="";
    globalVars['speed']=800;
  }

  setCards() {
    let number=2;let index=0;
    this.maxcards=maxCardsOut();
    for(let i=3; i!=27;i++) {
      this.ncards.disabled[i]= i > this.maxcards;
      if (i>this.maxcards) {
        this.mouseClass['cards'][`${i}`]="";
      }

    }
  }
  setSpeed(start, action=false) {
     for(let i=start; i!=4600; i+=200) {
       this.speed.disabled[i]=action;
     }
  }

  adjustSpeed() {
    this.setSpeed(3000, true);
  }
  modeInSetting(setting) {
    return 'mode' in setting  && ( ['practice', 'license'].includes(setting['mode'])) ;
  }
  ngOnInit() :void{
    const method=globalVars['method'];
    this.gvars=globalVars;
    this.setSpeed(600);
    this.setCards();
    console.log(`setting enabled:${JSON.stringify(this.enabled)}`)

    this.globalColors();
    if (method=='single') {
      this.singleSetup();
    }

    setTimeout(() => {
      this.pageEnabled=true;
    },400);

    this.mobile = globalVars['mobileDevice'];
    const _setting=globalVars['setting'];
    if ('func' in _setting) {
      // setting function to call
      _setting.func(this.store);
      if ('speed' in _setting) {
          // this.mouseClass.speed.chosen='800';
          this.discolorSpeed();
      }
    }

    // setTimeout(() => {
    //   BottomAppleModify(this.store, { disable:['forward'], enable:['lastIcon', 'backward'], lastIcon:'done'});
    // }, 100);
    this.subscriptions.push(
          this.store
            .select('genericReducer')
            .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
            .subscribe((data: GenericDataUnit) => {
              if (data.action==GenericDataUnitEnum.trainSlideSetting) {
                this.train4(data.mode);
                this.enabled.settings = true;
                console.log(`after setting enabled:${JSON.stringify(this.enabled)}`)

                return;
              }
              if (data.action==GenericDataUnitEnum.restoreFromCach) {
                // this.enabled.settings = true;
                BottomAppleModify(this.store, { enable:this.cached.enabled, disabled: this.cached.disabled,lastIcon:'done' });
                return;
              }
            }
          )
     );
    this.subscriptions.push(
       this.store
         .select('mobileTopBottom')
         .pipe(filter(data => data.typeof=== 'KeyPressedDm'))
         .subscribe((data: KeyPressedDm) => {
           if (data.target=='parent') {
              this.pageEnabled=false;
              return;
           }
            if (data.key=='done') {
              // GenericSettingDone(this.store);
              // this.enabled.settings = false;
              this.done();
              return;
            }
            if (data.key=='forward') {
              this.next();
              return;
            }
            if (data.key=='backward') {
              this.done();
            }
         })
     );


  }
  done() {
    if ('route' in globalVars['setting']) {
          this.routeService.navigate(globalVars['setting']['route']);
          globalVars['setting']={};
    }
  }
   ngOnDestroy() :void{
     for (const subs of this.subscriptions) {
       subs.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }
}
