

      <!-- <div  style="margin-top:20px;width:100%; height:100%;margin-left:{{gvars['center-width']}}px"> -->
      <div  class="inset-border mild-black" style="position: relative;margin-top:{{margin.top}}px; height:{{cardsH*4+40}}px;width:{{pageWidth+20}}px" *ngIf="pageEnabled">
        <div style="margin-left:20px">
              <div class="flex" *ngIf="labelEnable" >
                   <p > {{label.top}}</p>
                   <mat-icon style="margin-left:10px" [color]="thumbs.color">  {{thumbs.direction}} </mat-icon>
              </div>

              <span *ngIf="cards.dealer.length" > {{label.dealer[0]}} <span *ngIf="end"> {{label.dealer[1]}} </span></span>
              <div class="flex">
                    <div  style="min-height:{{imageH+40}}px; margin-top:10px;" >
                          <div class="flex " [@mcards]="cards.dealer.length" >
                               <img  src="{{cimage}}" *ngFor="let cimage of cards.dealer" class="cards-spacing" style="margin-top:10px">
                        </div>
                  </div>
              </div>
              <div style="position: absolute; top:{{cardsH * 2 + 20}}px">
                <p > player betting $500 </p>
                <div class='flex'>
                     <div class="flex-column">
                        <p  *ngIf="cards.player[0].length"> {{label.player[0]}} </p>
                        <div class="flex " [@mcards]="cards.player[0].length" style="margin-top:10px">
                               <img  src="{{cimage}}" *ngFor="let cimage of cards.player[0]" class="cards-spacing">
                        </div>
                    </div>
                    <div   class="flex-column" *ngIf="cards.shand" style="margin-left:30px">
                          <p  *ngIf="cards.player[1].length"> {{label.player[1]}} </p>
                          <div class="flex " [@mcards]="cards.player[1].length" style="margin-top:10px">
                                 <img  src="{{cimage}}" *ngFor="let cimage of cards.player[1]" class="cards-spacing">
                          </div>
                    </div>

                </div>
              </div>
          </div>

  </div>
