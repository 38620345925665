export const rules = {
   'Book': {
     Soft:{
     10: {
            2:'S',3:'S',4:'S',5:'S',6:'S',7:'S',8:'S',9:'S',10:'S', 'A':'S'
        },
     9: {
           2:'S',3:'S',4:'S',5:'S',6:'S',7:'S',8:'S',9:'S',10:'S', 'A':'S'
        },
     8: {
           2:'S',3:'S',4:'S',5:'S',6:'S',7:'S',8:'S',9:'S',10:'S', 'A':'S'
        },
     7: {
          2:'S',3:'D',4:'D',5:'D',6:'D',7:'S',8:'S',9:'H',10:'H', 'A':'H'
        },
     6: {
          2:'D',3:'D',4:'D',5:'D',6:'D',7:'H',8:'H',9:'H',10:'H', 'A':'H'
        },
     5: {
          2:'H',3:'H',4:'D',5:'D',6:'D',7:'H',8:'H',9:'H',10:'H', 'A':'H'
        },
     4: {
          2:'H',3:'H',4:'D',5:'D',6:'D',7:'H',8:'H',9:'H',10:'H', 'A':'H'
        },
     3: {
          2:'H',3:'H',4:'H',5:'D',6:'D',7:'H',8:'H',9:'H',10:'H', 'A':'H'
        },
     2: {
          2:'H',3:'H',4:'H',5:'D',6:'D',7:'H',8:'H',9:'H',10:'H', 'A':'H'
        },
      A: {
            2:'SP',3:'SP',4:'SP',5:'SP',6:'SP',7:'SP',8:'SP',9:'SP',10:'SP', 'A':'SP'
       },
      },
     Split:{
       A: {
            2:'SP',3:'SP',4:'SP',5:'SP',6:'SP',7:'SP',8:'SP',9:'SP',10:'SP', A:'SP'
       },
     10: {
       2:'S',3:'S',4:'S',5:'S',6:'S',7:'S',8:'S',9:'S',10:'S', A:'S'
       },

   9: {
       2:'SP',3:'SP',4:'SP',5:'SP',6:'SP',7:'S',8:'SP',9:'SP',10:'S', A:'S'
       },
   8: {
       2:'SP',3:'SP',4:'SP',5:'SP',6:'SP',7:'SP',8:'SP',9:'SP',10:'SP', A:'SP'
       },
   7: {
       2:'SP',3:'SP',4:'SP',5:'SP',6:'SP',7:'H',8:'H',9:'H',10:'H', A:'H'
       },
   6: {
       2:'SP',3:'SP',4:'SP',5:'SP',6:'SP',7:'H',8:'H',9:'H',10:'H', A:'H'
       },
   5: {
       2:'D',3:'D',4:'D',5:'D',6:'D',7:'D',8:'D',9:'D',10:'H', A:'H'
       },
   4: {
       2:'H',3:'H',4:'H',5:'H',6:'H',7:'H',8:'H',9:'H',10:'H', A:'H'
       },
   3: {
       2:'H',3:'H',4:'SP',5:'SP',6:'SP',7:'H',8:'H',9:'H',10:'H', A:'H'
       },
   2: {
       2:'H',3:'H',4:'SP',5:'SP',6:'SP',7:'H',8:'H',9:'H',10:'H', A:'H'
       },
     },
     Hard:{
    16: {
        2:'S',3:'S',4:'S',5:'S',6:'S',7:'H',8:'H',9:'H',10:'H', A:'H'
        },
    15: {
        2:'S',3:'S',4:'S',5:'S',6:'S',7:'H',8:'H',9:'H',10:'H', A:'H'
        },
    14: {
        2:'S',3:'S',4:'S',5:'S',6:'S',7:'H',8:'H',9:'H',10:'H', A:'H'
        },
    13: {
        2:'S',3:'S',4:'S',5:'S',6:'S',7:'H',8:'H',9:'H',10:'H', A:'H'
        },
    12: {
        2:'H',3:'H',4:'S',5:'S',6:'S',7:'H',8:'H',9:'H',10:'H', A:'H'
        },
    11: {
        2:'D',3:'D',4:'D',5:'D',6:'D',7:'D',8:'D',9:'D',10:'D', A:'D'
        },
    10: {
        2:'D',3:'D',4:'D',5:'D',6:'D',7:'D',8:'D',9:'D',10:'H', A:'H'
        },
    9: {
        2:'H',3:'D',4:'D',5:'D',6:'D',7:'H',8:'H',9:'H',10:'H', A:'H'
        },
    8: {
        2:'H',3:'H',4:'H',5:'H',6:'H',7:'H',8:'H',9:'H',10:'H', A:'H'
        }
     }
   },
   'High prediction': {
     Soft:[
       {'P': 'A,2', 'D': '4', 'Counts': ['D:202', 'H:163'], 'Prediction': 'D', 'Book': 'H'},
        {'P': 'A,3', 'D': '4', 'Counts': ['D:244', 'H:154'], 'Prediction': 'D', 'Book': 'H'},
        {'P': 'A,6', 'D': '7', 'Counts': ['D:20', 'H:-18'], 'Prediction': 'D', 'Book': 'H'},
        {'P': 'A,7', 'D': '2', 'Counts': ['S:110', 'D:72'], 'Prediction': 'S', 'Book': 'D'},
        {'P': 'A,7', 'D': 'Ace', 'Counts': ['S:-195', 'H:-230'], 'Prediction': 'S', 'Book': 'H'}
     ],
     Split:[
       {'P': '2,2', 'D': '3', 'Counts': ['SP:70', 'H:15'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '2,2', 'D': '7', 'Counts': ['SP:4', 'H:-85'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '3,3', 'D': '2', 'Counts': ['SP:-28', 'H:-74'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '3,3', 'D': '3', 'Counts': ['SP:177', 'H:25'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '3,3', 'D': '7', 'Counts': ['SP:-141', 'H:-188'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '4,4', 'D': '4', 'Counts': ['D:270', 'H:194'], 'Prediction': 'D', 'Book': 'H'},
        {'P': '4,4', 'D': '5', 'Counts': ['SP:377', 'H:246'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '4,4', 'D': '6', 'Counts': ['SP:781', 'H:410'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '5,5', 'D': '10', 'Counts': ['D:70', 'H:48'], 'Prediction': 'D', 'Book': 'H'},
        {'P': '5,5', 'D': 'Ace', 'Counts': ['D:248', 'H:97'], 'Prediction': 'D', 'Book': 'H'},
        {'P': '6,6', 'D': 'Ace', 'Counts': ['S:-408', 'H:-427'], 'Prediction': 'S', 'Book': 'H'},
        {'P': '7,7', 'D': '7', 'Counts': ['SP:-52', 'H:-417'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '7,7', 'D': 'Ace', 'Counts': ['S:-400', 'H:-529'], 'Prediction': 'S', 'Book': 'H'},
        {'P': '8,8', 'D': '10', 'Counts': ['S:-634', 'SP:-664'], 'Prediction': 'S', 'Book': 'SP'},
        {'P': '8,8', 'D': 'Ace', 'Counts': ['S:-414', 'SP:-541'], 'Prediction': 'S', 'Book': 'SP'}
     ],
     Hard:[
       {'P': '9', 'D': '2', 'Counts': ['D:206', 'H:144'], 'Prediction': 'D', 'Book': 'H'},
       {'P': '9', 'D': '7', 'Counts': ['D:370', 'H:300'], 'Prediction': 'D', 'Book': 'H'},
       {'P': '9', 'D': '8', 'Counts': ['D:346', 'H:195'], 'Prediction': 'D', 'Book': 'H'},
       {'P': '10', 'D': '10', 'Counts': ['D:88', 'H:75'], 'Prediction': 'D', 'Book': 'H'},
       {'P': '10', 'D': 'Ace', 'Counts': ['D:274', 'H:114'], 'Prediction': 'D', 'Book': 'H'},
       {'P': '12', 'D': '3', 'Counts': ['S:-90', 'H:-180'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '13', 'D': 'Ace', 'Counts': ['S:-436', 'H:-480'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '14', 'D': 'Ace', 'Counts': ['S:-458', 'H:-558'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '15', 'D': '7', 'Counts': ['S:-466', 'H:-504'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '15', 'D': '8', 'Counts': ['S:-500', 'H:-568'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '15', 'D': '9', 'Counts': ['S:-548', 'H:-633'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '15', 'D': '10', 'Counts': ['S:-600', 'H:-638'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '15', 'D': 'Ace', 'Counts': ['S:-418', 'H:-634'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '16', 'D': '7', 'Counts': ['S:-474', 'H:-493'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '16', 'D': '8', 'Counts': ['S:-524', 'H:-546'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '16', 'D': '9', 'Counts': ['S:-564', 'H:-644'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '16', 'D': '10', 'Counts': ['S:-616', 'H:-678'], 'Prediction': 'S', 'Book': 'H'},
       {'P': '16', 'D': 'Ace', 'Counts': ['S:-436', 'H:-635'], 'Prediction': 'S', 'Book': 'H'}

       ]
   },
   'Low prediction': {
     Soft:[

       {'P': 'A,2', 'D': '5', 'Counts': ['H:59', 'D:-54'], 'Prediction': 'H', 'Book': 'D'},
        {'P': 'A,2', 'D': '6', 'Counts': ['H:266', 'D:254'], 'Prediction': 'H', 'Book': 'D'},
        {'P': 'A,3', 'D': '5', 'Counts': ['H:21', 'D:-162'], 'Prediction': 'H', 'Book': 'D'},
        {'P': 'A,4', 'D': '4', 'Counts': ['H:38', 'D:-34'], 'Prediction': 'H', 'Book': 'D'},
        {'P': 'A,4', 'D': '5', 'Counts': ['H:37', 'D:14'], 'Prediction': 'H', 'Book': 'D'},
        {'P': 'A,5', 'D': '4', 'Counts': ['H:-26', 'D:-86'], 'Prediction': 'H', 'Book': 'D'},
        {'P': 'A,6', 'D': '4', 'Counts': ['H:-23', 'D:-46'], 'Prediction': 'H', 'Book': 'D'},
        {'P': 'A,7', 'D': '3', 'Counts': ['S:66', 'D:-32'], 'Prediction': 'S', 'Book': 'D'},
        {'P': 'A,7', 'D': '4', 'Counts': ['S:108', 'D:76'], 'Prediction': 'S', 'Book': 'D'},
        {'P': 'A,7', 'D': '5', 'Counts': ['S:121', 'D:78'], 'Prediction': 'S', 'Book': 'D'}
    ],
     Split:[
       {'P': 'A,A', 'D': '3', 'Counts': ['H:157', 'SP:132'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': 'A,A', 'D': '8', 'Counts': ['H:230', 'SP:77'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': 'A,A', 'D': '9', 'Counts': ['H:151', 'SP:47'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': 'A,A', 'D': '10', 'Counts': ['H:100', 'SP:3'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': 'A,A', 'D': 'Ace', 'Counts': ['H:104', 'SP:-249'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '2,2', 'D': '4', 'Counts': ['H:-115', 'SP:-146'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '2,2', 'D': '5', 'Counts': ['H:-66', 'SP:-183'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '2,2', 'D': '7', 'Counts': ['SP:44', 'H:-39'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '3,3', 'D': '4', 'Counts': ['H:-179', 'SP:-283'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '3,3', 'D': '5', 'Counts': ['H:-136', 'SP:-217'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '3,3', 'D': '6', 'Counts': ['H:70', 'SP:-24'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '3,3', 'D': '7', 'Counts': ['SP:3', 'H:-74'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '4,4', 'D': '6', 'Counts': ['SP:177', 'H:-6'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '5,5', 'D': '8', 'Counts': ['H:104', 'D:28'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '5,5', 'D': '9', 'Counts': ['H:87', 'D:-4'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '6,6', 'D': '2', 'Counts': ['H:-230', 'SP:-526'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '6,6', 'D': '3', 'Counts': ['H:-159', 'SP:-422'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '6,6', 'D': '4', 'Counts': ['H:-108', 'SP:-478'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '6,6', 'D': '5', 'Counts': ['H:-155', 'SP:-317'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '6,6', 'D': '6', 'Counts': ['H:-59', 'SP:-95'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '7,7', 'D': '2', 'Counts': ['H:-286', 'SP:-463'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '7,7', 'D': '3', 'Counts': ['S:-288', 'SP:-510'], 'Prediction': 'S', 'Book': 'SP'},
        {'P': '7,7', 'D': '4', 'Counts': ['H:-282', 'SP:-340'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '7,7', 'D': '5', 'Counts': ['H:-246', 'SP:-261'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '7,7', 'D': '7', 'Counts': ['SP:-230', 'H:-243'], 'Prediction': 'SP', 'Book': 'H'},
        {'P': '8,8', 'D': '10', 'Counts': ['H:-473', 'SP:-521'], 'Prediction': 'H', 'Book': 'SP'},
        {'P': '8,8', 'D': 'Ace', 'Counts': ['H:-417', 'SP:-431'], 'Prediction': 'H', 'Book': 'SP'},
     ],
     Hard:[

       {'P': '9', 'D': '3', 'Counts': ['H:-12', 'D:-154'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '9', 'D': '4', 'Counts': ['H:-64', 'D:-256'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '9', 'D': '5', 'Counts': ['H:42', 'D:-72'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '9', 'D': '6', 'Counts': ['H:108', 'D:88'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '10', 'D': '7', 'Counts': ['H:172', 'D:72'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '10', 'D': '8', 'Counts': ['H:130', 'D:100'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '10', 'D': '9', 'Counts': ['H:31', 'D:-142'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '11', 'D': '8', 'Counts': ['H:170', 'D:56'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '11', 'D': '9', 'Counts': ['H:90', 'D:10'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '11', 'D': '10', 'Counts': ['H:91', 'D:62'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '11', 'D': 'Ace', 'Counts': ['H:62', 'D:30'], 'Prediction': 'H', 'Book': 'D'},
        {'P': '12', 'D': '4', 'Counts': ['H:-185', 'S:-340'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '12', 'D': '5', 'Counts': ['H:-127', 'S:-222'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '12', 'D': '6', 'Counts': ['H:-52', 'S:-138'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '13', 'D': '2', 'Counts': ['H:-201', 'S:-372'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '13', 'D': '3', 'Counts': ['H:-162', 'S:-360'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '13', 'D': '4', 'Counts': ['H:-196', 'S:-302'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '13', 'D': '5', 'Counts': ['H:-171', 'S:-248'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '14', 'D': '2', 'Counts': ['H:-262', 'S:-344'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '14', 'D': '3', 'Counts': ['H:-285', 'S:-348'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '14', 'D': '4', 'Counts': ['H:-255', 'S:-330'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '14', 'D': '5', 'Counts': ['H:-261', 'S:-304'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '14', 'D': '6', 'Counts': ['H:-168', 'S:-184'], 'Prediction': 'H', 'Book': 'S'},
        {'P': '15', 'D': 'Ace', 'Counts': ['S:-402', 'H:-433'], 'Prediction': 'S', 'Book': 'H'}

       ]
   }
};
const transform = {
	S: 'Stand',
	D: 'Double-down',
	H: 'Hit',
	SP: 'Split'
};

const getRule=(rule, dcard, pcard) => {

	const _rule=rules['Book'][rule];
	if (pcard in _rule) {
		//console.log(`inside dcard:${dcard}, pcard:${pcard}, rule:${rule}`);
           return transform[_rule[pcard][dcard]];
	}
		//console.log(`inside dcard:${dcard}, pcard:${pcard}, rule:${rule}`);
	return (pcard>16)? 'Stand': 'Hit';
};

export const book_rule_get = (ddm, pid) => {
	const dfaceCard=ddm.dealerFacecard();
	const playerHand=ddm.hands[pid];
	const _2cards=playerHand.length()==2;
	const sum=playerHand.sum();
	if (_2cards) {
		// console.log(`isSoft:${ddm.isSoft(pid)}, isSplit(pid):${ddm.isSplit(pid)}`);
                // playerHand.display();
		if (ddm.isSoft(pid)) {
			const index=playerHand.valuePerCard(0)==1? 1: 0;
		       	return getRule('Soft', dfaceCard, playerHand.valuePerCard(index));
		}
		if (ddm.isSplit(pid)) return getRule('Split', dfaceCard, playerHand.valuePerCard(1));
		return getRule('Hard', dfaceCard, sum);
	}
	return getRule('Hard', dfaceCard, sum);
};
