import { Component, OnInit, Input, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { isJack,globalVars,MobileRouterService, imageDims} from '../../../services/global.service';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import { BottomQuesButtons,BottomMenuReset} from '../../store/bottom.model';
import { BottomQuesButtonsPressedSend, MobileSendSubNavigationInfo} from '../../store/bottom.action.reducer';
import { nFlash} from '../../../services/common.service';
import { CommonListAnimations} from '../../shared/animations/mobile-animations';

const IDLE = 0;
const DEFAULT_COLOR = 'primary';

@Component({
  selector: 'anms-bottom-numbers',
  templateUrl: './component.html',
  styleUrls: ['../../styles.scss'],
  animations: [
        CommonListAnimations.flash
  ]
})


export class BottomNumbersComponent implements OnInit, OnDestroy{
  @Output() timerEvent = new  EventEmitter();
  @Output() thumbsEvent = new EventEmitter();
  subscriptions = [];
  flashed='';
  qbuttons = {
    buttons:[],
    cvalue:'',
  };
  keys=['lesson', 'continuous-test', 'reset', 'test', 'speed', 'cards'];
  lesson=false;
  numberMargin=0;
  jcount=true;
  control={
    speed:false,
    lesson:false,
    cards:false
  };
  mleft=0
  testkey="continuous-test";
  nmargin = 0;
  flashes = {};

  buttonEnabled=false;

  flashAllbuttons(buttons) {
    for (const key of buttons) {
      this.flashes[key]= new nFlash;
      this.flashes[key].flash();
    }
  }

  setupFlash(buttons) {
    if (Object.keys(this.flashes).length) {
      for (const key in this.flashes) {
          this.flashes[key].unflash();
      }
      this.flashes = {};
      setTimeout(() => {
        this.flashAllbuttons(buttons);
      }, 0);
    } else {
      this.flashAllbuttons(buttons);
    }
    this.buttonEnabled = true;
  }

  unflashExcept(button) {
    for (const key in this.flashes) {
        if (key != button) this.flashes[key].unflash();
    }
  }

  constructor(
    private router: MobileRouterService,private store: Store<any>) {

     // this.setupFlash(['a','b','c','d']);
   }

   tevent() {
     // alert(`numbers event triggered`)
     this.timerEvent.emit('');
   }
   _unflash(dict:any):void {
     dict.flash.unflash();

     // if (dict.name != 'home') {
     //   dict.disabled= true;
     // }
     dict.flashed=false;
     dict.color= DEFAULT_COLOR;
   }

///////////////////////
  ngOnInit():void {
    // this.unflash('all');
        this.jcount=isJack();
	       // imageDims();
        let rows=3;
        let cols=8;
        this.mleft=globalVars['mobileDevice']? 50:100;
        // alert(`mode:${globalVars['mode'] = 'license';}`)
        // alert(`mode:${globalVars['mode']}, cardsOut:${globalVars['cardsOut']}`)
        if (globalVars['cardsOut'] >= 18) {
          this.nmargin = globalVars['mode'] == 'license' ? 60: 40;
        }

        this.subscriptions.push(
          this.store
            .select('mobileTopBottom')
            .pipe(filter(data => data && data.typeof=== 'BottomQuesButtons'))
            .subscribe((bottomQuesButtons: BottomQuesButtons) => {
                const continuous=bottomQuesButtons.timeout;
                const buttons = bottomQuesButtons.buttons;
                this.lesson='lesson' in bottomQuesButtons;
                this.qbuttons.buttons = [];
                setTimeout(() => {
                  this.setupFlash(buttons);
                  this.qbuttons.buttons = buttons;
                }, 100);

                this.qbuttons.cvalue=bottomQuesButtons.cvalue;
              }
            ));


        this.subscriptions.push(
          this.store
            .select('mobileTopBottom')
            .pipe(filter(data => data && data.typeof=== 'BottomMenuReset'))
            .subscribe((nodata: BottomMenuReset) => {
                this.resetQbuttons(nodata.blank);
              }
            ));
            let cout=globalVars['cardsOut'];
            if (cout>4) {
              const image = globalVars['image'];
              const dims=image['dims'];
              const pixel=image.card.width;
              const cols=image.cols;
              let cout=globalVars['cardsOut'];
                  cout= cout < cols ? cout: cols;
              this.numberMargin = Math.floor((pixel*cout)/3);
            }


  }
//////////////////////
ngOnDestroy():void {
    for (const subscriber of this.subscriptions) {
      subscriber.unsubscribe();
    }
    this.resetQbuttons();
    this.unflashExcept('kkhan');
}

 resetQbuttons(blank=false):void {
    this.qbuttons.buttons=[];
 }
////////////////
  clicked(button:any):void {
    // console.log(`bottom comp. received key: ${key}`);
     // send it up to application

    if (button == 'dbackward') {
      BottomQuesButtonsPressedSend(this.store,'go2top');
      return;
    }
    if (this.keys.includes(button)) {
        // this.menulist=this.availList[button]
        this.unflashExcept('all');
        BottomQuesButtonsPressedSend(this.store,button);
        return;
    }
    let timeout=0;

    if (this.qbuttons.cvalue) { // cross out the
      if (button != this.qbuttons.cvalue) {
        const buttons = this.qbuttons.buttons;
        // let lindex=0; let index=0;
         for (const _button of buttons) {
           if (_button == this.qbuttons.cvalue) {
             // lindex=index;
             continue;
           }
           // index++;
         }
      }
    }
    this.thumbsEvent.emit(button==this.qbuttons.cvalue? 'up': 'down');
    setTimeout(() => {
      this.resetQbuttons(button.includes('Insu')|| ['hit', 'stand', 'double', 'split'].includes(button));
      BottomQuesButtonsPressedSend(this.store,button);
    }, timeout);

  }
}
