<div style="width:100%;margin-left:{{vars['center-width']}}px; height:100%; margin-top:30px" *ngIf="!die" >
      <div class="flex-column mild-black inset-border" style="position: relative; width:{{tpage.width}}px; height:{{tpage.height}}px; margin-top:20px;">
          <anms-thumbs-timer  #thumbs_timer  style="position: absolute; top:20px; left:{{tpage.width-250}}px">   </anms-thumbs-timer>
              <div class="scene" style="position: absolute; top:60px; left:100px">
              		     <div class="flip" [@cardFlip]="state">
                                 <div class="face back">
                                   <ng-container *ngIf="state!='default'">
                      		             <ng-container *ngFor="let row of matrix.row">
                                            <div class="flex-row" >
                                              <ng-container *ngFor="let col of matrix.row">
                                                   <anms-mobile-flip-card    style="margin-right:4px;cursor:pointer" [id]="row*matrix.size+col" >  </anms-mobile-flip-card>
                                               </ng-container>
                                            </div>
                                         </ng-container>
                                 </ng-container>

                                 </div>
                                <div class="face front">
                                   <ng-container *ngIf="state=='default'">
                                         <ng-container *ngFor="let i of matrix.row">
                                              <div class="flex-row">
                                                <ng-container *ngFor="let col of matrix.row">
                                                   <anms-mobile-flip-card style="margin-right:4px;cursor:pointer"  [id]="i*matrix.size+col" >  </anms-mobile-flip-card>
                                                 </ng-container>
                                              </div>
                                           </ng-container>
                                   </ng-container>

                              </div>
                    </div>
	           </div>
             <anms-bottom-numbers style="position: absolute; top:{{tpage.height-100}}px; left:{{tpage.width-300}}px" (thumbsEvent)="tevent($event)"> </anms-bottom-numbers>

      </div>

</div>
