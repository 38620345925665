<div style="margin-left:{{gvars['center-width']}}px; margin-top:15px">
 <div style="margin-top:20px; position: relative; width:{{tpage.width}}px; height:{{tpage.height}}px">
         <ngx-slick-carousel class="carousel" #slickModal="slick-carousel"  [config]="slideConfig" (afterChange)="afterChange($event)" style='width:100%'>
              <div ngxSlickItem *ngFor="let stmts of statements" class="slide">
                <div class="margin-side-10">
                    <Lp  *ngFor="let stmt of stmts" text="{{stmt}}">  </Lp>
                </div>
              </div>
        </ngx-slick-carousel>

        <div class="flex-row"  style="margin-left:30%">
                <button mat-flat-button  (click)="clicked('dbackward')"
                  [disabled]="false" [color]="'primary'" class="scale-p8">  <<- </button>
                <button mat-flat-button  (click)="clicked('backward')"
                  [disabled]="disabled.backward" [color]="'warn'" class="scale-p8 margin-left-c-buttons"> <mat-icon> skip_previous</mat-icon></button>
               <button mat-flat-button   (click)="clicked('forward')"
                    [disabled]="disabled.forward" [color]="'warn'"  class="scale-p8 margin-left-c-buttons"> <mat-icon > skip_next </mat-icon></button>
        </div>
  </div>

</div>
