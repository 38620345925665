


  <div style="position:relative; margin-left:{{gvars['center-width']}}px; width:100%; margin-top:30px;" *ngIf="topEnabled">
    <span *ngIf="pcntgEnabled">
       <button mat-mini-fab [color]="'warn'"
               style="position: absolute; top:{{top[0]}}px; left:{{left[0]}}px;z-index:8">25%
        </button>
        <button mat-mini-fab [color]="'warn'"
                style="position: absolute; top:{{top[0]}}px; left:{{left[1]}}px;z-index:8">25%
         </button>
         <button mat-mini-fab [color]="'warn'"
                 style="position: absolute; top:{{top[1]}}px; left:{{left[0]}}px;z-index:8">25%
          </button>
          <button mat-mini-fab [color]="'warn'"
                  style="position: absolute; top:{{top[1]}}px; left:{{left[1]}}px;z-index:8">25%
           </button>
   </span>
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)" >
              <div ngxSlickItem class="slide">
                    <div class="flex-column" style="z-index:20">
                        <div style="margin-left:7px" *ngFor="let key of cardsNumber"> {{key}} high-cards</div>
                    </div>
                    <div class="mild-black inset-border" style="position: relative; margin-top:20px; width:{{dims.width*5+80}}px; height:{{dims.height+75}}px">
                            <div class="row" style="position: absolute; left:20px; top:20px">
                                   <img  src="{{cimage}}" *ngFor="let cimage of images" class="cards-spacing">
                            </div>
                      </div>
              </div>
              <div ngxSlickItem class="slide">
                  <div class="flex-column" style="z-index:20">
                      <div style="margin-left:7px" *ngFor="let key of cardsNumber"> {{key}} low-cards</div>
                  </div>
                  <div class="mild-black inset-border" style="position: relative;margin-top:20px; width:{{dims.width*5+80}}px; height:{{dims.height+75}}px">
                          <div class="row" style="position: absolute; left:20px; top:20px">
                                 <img  src="{{cimage}}" *ngFor="let cimage of images" class="cards-spacing">
                          </div>
                    </div>
              </div>
              <div ngxSlickItem class="slide">
                    <div class="mild-black inset-border" style="position: relative;margin-top:20px; width:{{dims.width*3+80}}px; height:{{dims.height+75}}px">
                        <div style="font-size: 20"> Ignored cards </div>
                            <div class="row" style="position: absolute; left:20px; top:30px">
                                   <img  src="{{cimage}}" *ngFor="let cimage of images" class="cards-spacing">
                            </div>
                      </div>
              </div>
              <div ngxSlickItem class="slide">
                 <div class="flex-column mild-black inset-border" style="position: relative;margin-top:15px; margin-left:20px;width:{{dims.width*5+100}}px; height:{{dims.height*2+100}}px">
                   <div  style="position: absolute; left:20px; top:20px">
                       <ng-container *ngFor="let j of [0,1]">
                            <div class="flex-row" style="margin-top:10px">
                                  <img  src="{{cimage}}" *ngFor="let cimage of allImages[j]; let i=index" class="cards-spacing"  style="cursor:pointer" (click)="cclicked(j,i)">
                           </div>
                        </ng-container>
                    </div>
                 </div>
              </div>

              <div ngxSlickItem class="slide">
                 <div class="flex-column">
                     <div>
                         <div> 2 cards odds: </div>
                         <div class="flex">
                            <div  style="margin-left:7px;" *ngFor="let key of cardsLikelihood"> {{key}} </div>
                         </div>
                     </div>
                     <div class="mild-black inset-border" style="position: relative;margin-top:15px;margin-left:20px;width:{{dims.width*5+100}}px; height:{{dims.height*2+75}}px">
                         <div class="flex">
                                 <div class="flex-column" *ngFor="let odd of odds" style="margin-left:20px;" >
                                       <div class="flex-column" >
                                           <div class="flex " *ngFor="let row of ['first', 'second']">
                                               <img  src="{{odd[row][column]}}" class="cards-spacing" *ngFor="let column of [0,1]">
                                           </div>
                                      </div>
                                 </div>
                          </div>
                    </div>
                 </div>

            </div>

    </ngx-slick-carousel>

    <div style="position: absolute; top: {{top[2]+20}}px; left:{{left[0]}}px;">
              <div class="flex-row"  >
                      <button mat-flat-button  (click)="clicked('backward')"
                        [disabled]="disabled.previous" [color]="'warn'" class="scale-p8 margin-left-c-buttons"> <mat-icon> skip_previous</mat-icon></button>

                     <button mat-flat-button   (click)="clicked('forward')"
                          [disabled]="disabled.next" [color]="'warn'"  class="scale-p8 margin-left-c-buttons "> <mat-icon > skip_next </mat-icon></button>
              </div>
        </div>
  </div>
   <!-- <div class="flex-row level-top"  style="margin-top:15px" [@cards]="allImages[j].length" > -->
   <!-- <div  style="position: absolute; left:20px; top:20px"> -->
