import { SwUpdate } from '@angular/service-worker';
import { Title, Meta } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit, Input,HostBinding,HostListener} from '@angular/core';
import { ActivationEnd, Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {  Subject, Observable } from 'rxjs';
import { catchError, takeUntil, map, filter } from 'rxjs/operators';

import {GenericGresetAction, GenericDieAction, SendRefreshEvent} from './mobile/store/generic.action.reducer';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Timer1sec } from './services/timer.observable.service';
//import { print, GetDays, GetHours, GetMinutes,GetSeconds } from './services/common.service';
import { print } from './services/common.service';
// import { login, logout, selectorAuth, routerTransition } from '@app/core';
import { routerTransition } from '@app/core';


import { environment as env } from '@env/environment';
import { globalVars, MobileRouterService,startCountdownTimer,isPageRoute,pageRoute,imageDims,JcountSeo,getVideoUrl,stopCountdownTimer} from './services/global.service';
import { HttpClient } from '@angular/common/http';

import { selectorSettings } from './settings';
import { UserService } from './services/user.service';
import { User } from "@app/models/user";
import { LocalStorageService } from './core/local-storage/local-storage.service';
import { AuthGuard } from '@app/services/auth-guard-service';
import { actionChangeTheme} from './settings/settings.reducer';
import { GenSettingModel, GenSettingEnum } from './store/gen-setting.model';
// import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DealerService } from './services/deck.service';
import {  LoginSuccessful,UserHaveCredit} from './store/gen-setting.action.reducer';
// import { SignupDialog } from './login/signup.component';
// import { LoginDialog  } from './login/login.component';
import {MatSidenav} from '@angular/material/sidenav';
import {MobileSendKeyRoute,BottomAppleModify} from './mobile/store/bottom.action.reducer';
import {KeyPressedDm} from './mobile/store/bottom.model';
import {ServerErrorData} from './store/server.action.reducer';
import {insertVerticalImageAction, VerticalImageSlidAction } from './store/vimage.action.reducer';
import { StorageServiceModule} from 'angular-webstorage-service';
import { GenericDataUnitEnum, GenericDataUnit} from './mobile/store/generic.model';



const DECREASEIT=false; // used for capturing video
const date2string = () =>  new Date().toString().split(' ').splice(0,5).join(':');

@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  //styleUrls: ['./app.component.scss', '../styles.scss'],
  styleUrls: [ ],

  animations: [
    routerTransition,
  ]
})

export class AppComponent{
  @HostBinding('class') componentCssClass;
  private unsubscribe$: Subject<void> = new Subject<void>();
  dimension = {
    sidebar: {width:0, height:0},
     app:  {
      top: {width:0, height:0},
      bar: {width:100, height:5},
      toptop: {width:100, height:10},
      route:{width:0,height:0},
      bottom:{width:0, height:0}
    },
    top: {width:0, height:0}
  }
  sideNavEnable=true;
  location=window.location;
  device='';
  noCredit=true;
  // logo = require('./../assets/logo.png');
  navMenu= [
    // { link: 'online', label: 'Online-assist' , tip:'', auth:true},
    // { link: 'subscriptions', label: 'Subscriptions' , tip:'', auth:false},
  ];
  routerHeight:any;
  mobile:boolean;
  userEmail: string;
  userPassword: string;
  // userRepeatPassword;
  /*
  remaingSubscriptionTimeInSecs: number;
  isRemaingSubscriptionTimeLoading = true;
  remaingSubscriptionTimeFormatted: string = '';
 */
  action=GenSettingEnum.None;
  allowSetting = false;
  screen = {height:0, width:0};
  subscriptions= [];
  savedPin=-1;
  savedLoginRes={};
  theWidth=0;

  itemList=['Train', 'Time-left','Certificate', 'Contact-us', 'Home'];
  // vimages=['aria-2_200x200.jpg','bellagion-2_200x200.jpg','paris-2_200x200.jpg','aria-3_200x200.jpg',
  // vimages=['aria-2_200x200.jpg','bellagion-2_200x200.jpg','aria-3_200x200.jpg',
  //
  //     'ceaser-1_200x200.jpg','paris_200x200.jpg','trump_200x200.jpg','aria_200x200.jpg',
  //     'flamingo_200x200.jpg','red-new-1_200x200.jpg','venetioan-1_200x200.jpg','bellagio_200x200.jpg',
  //     'mandaly-bay_200x200.jpg','rio-1_200x200.jpg','wynn_200x200.jpg'];
  left_casinos=['aria', 'bellagio', 'red-rock', 'ceaser','golden','mgm', 'space-available'];
  // right_casinos=['wynn', 'world-resorts', 'venetian', 'paris', 'mirage','palms','cosmopolitan','space-available'];
  right_casinos=['wynn', 'resorts-world', 'venetian', 'paris', 'mirage','palms','cosmopolitan'];


  gdeck='single-deck';
  customize_enabled='none';
  timerActive=false;
  gvars:{};
  sidew=0;
  toptopEnabled=true;
  restStyle={display:'flex', 'flex-direction':'flex-column', align_item:'center' };

  loadedCheck(email) {
    // console.log(`loadedCheck::token:${localStorage.getItem('token')}, email:${email}`);
    this.subscriptions.push(
         this.userService.processUserTimer({ token: localStorage.getItem('token'), email: email})
            .subscribe( (resp:any) => {
              // console.log(`app.component::loaded-check::rcvd resp:${JSON.stringify(resp)}`);
              const token = resp['token'];
              const credit = resp['credit'];
              globalVars['credit']=credit;
              globalVars['now']=resp['started'];
              globalVars['updateCredit'] = true;
              globalVars['token'] = token;
              localStorage.setItem('token', token);
              localStorage.setItem('email', resp['email']);
              this.noCredit = true;
              //console.log(`recvd timer value of ${JSON.stringify(resp)}`)
              if (resp['code'] == 700 ) {
                if (credit>0) {
                  // check Access
                  const access=resp['access'];
                  globalVars['isAuthenticated']=true;
                  this.noCredit = false;
                  this.forwardMenu();
                  this.startTimer();
                  this.routeService.navigate('mobile/train');
                  return;
                } else {
                  alert('you are out of credit, sending you to subscription page');
                  this.subscription();
                 return;
                }
                return;
              }
           }));
  }
  constructor(
    private swUpdate: SwUpdate,
    public overlayContainer: OverlayContainer,
    private store: Store<any>,
    public routerService: MobileRouterService,
    public userService: UserService,
    private authGuard: AuthGuard,
    private localStorageService: LocalStorageService,
    private dealerService: DealerService,
  //  private elementRef: ElementRef
    private http: HttpClient,
    private jcountSeo: JcountSeo,
    private routeService: MobileRouterService

  ) {
      globalVars['state'] = 'top';
      // console.log(`the token is:${localStorage.getItem('token')}, email:${localStorage.getItem('email')}`)
      const subsObj = localStorage.getItem("subscription");

      if (subsObj) {
        const obj = JSON.parse(subsObj);
        globalVars['isAuthenticated']=true;
        localStorage.setItem('token', obj.token);
        localStorage.setItem('email', obj.email);
        localStorage.removeItem("subscription");
      }

      // if (globalVars['isAuthenticated'] && localStorage.getItem('token')  ) {
        let email='nullx';
        if (localStorage.getItem('email') !== null) {
          email = localStorage.getItem('email');
        }
        if (localStorage.getItem('token') && email.includes('@') ) {

         const timeout = subsObj? 120000: 0;
         setTimeout(() => {
           this.loadedCheck(email);
           // console.log(`after two minutes credit:${globalVars['credit']}`);
         }, timeout);
         if (subsObj) {
           globalVars['credit']=300000; // temporay 5 minutes credit; since it takes a while for table to be updated.
           this.noCredit = false;
           this.forwardMenu();
           // this.startTimer();
           this.routeService.navigate('mobile/train');
         }
      } else {
        this.routerService.navigate('mobile/home');
      }


      // console.log(`app.component constructor called.`)
      // xmls(new Connect2server(this.routerService, this.http));
      // xmls(this.routerService, this.http);
}
videoUrl() {
  return getVideoUrl();
}

//onDestory
ngOnDestroy(): void {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
  for (const subscriber of this.subscriptions) {
    subscriber.unsubscribe();
  }
   MobileSendKeyRoute(this.store,[{key:"reset"}]);
   stopCountdownTimer();
}

// getToken() { return this.localStorageService.getItem('AUTH')['token'];}

forwardMenu() {
   BottomAppleModify(this.store, {switch:'apple', list: [...this.itemList],
                    icons:['train','timelapse','filter','','home'],
                    disable:['backward', 'forward', 'lastIcon'], enable:['menu']});

}
selectorSettingsEvent():void {
this.subscriptions.push(
  this.store
    .select(selectorSettings)
    .pipe(
      takeUntil(this.unsubscribe$),
      map(({ theme }) => theme.toLowerCase())
    )
    .subscribe(theme => {
      this.componentCssClass = theme;
      this.overlayContainer.getContainerElement().classList.add(theme);
    }));
}

vimageMouse(id, action='enter') {
  // for(let i=0;i!=this.left_casinos.length+this.right_casinos.length;i++) {
  //   VerticalImageSlidAction(this.store, i, i==id);
  // }
    VerticalImageSlidAction(this.store, id, action=='enter');
}
////////////////////////
/*
mobileClick(event) {
//print([`received mobileClickEvent:`, event]);
   // this.toolBarLabel=event.label;
}
   turnOff(obj) {
    obj.checked = 'None';
   }
*/
////////////////////////

settingClicked(key:any):void {
     let page='';
     switch(key) {
       case ('signin') : {
         page='signin';
         break;
       }
       case ('Contact-us'): {
           page='contact';
           break;
      } default : {
        pageRoute(this.store, this.routeService, key);
        return;
      }
     }
     // send the reset to other active page
      GenericGresetAction(this.store);
      GenericDieAction(this.store);
      setTimeout(() => {
        this.routerService.navigate(page);
      }, 1000 );
   }

routerEvents():void {
  this.subscriptions.push(
    this.routerService.events()
      .pipe(filter(event => event instanceof ActivationEnd))
      .subscribe((event: ActivationEnd) => {
        let lastChild = event.snapshot;
        while (lastChild.children.length) {
          lastChild = lastChild.children[0];
        }

      }));
}
onLogoutClick() :void{
  //console.log('logout is called!!!');
  GenericDieAction(this.store);
  setTimeout(() => {
    this.localStorageService.setItem('logout', true);
    // console.log(`onlogoutclick is called before navigation`)

    this.routerService.navigate('about');
    // this.store.dispatch(logout());
    globalVars['isAuthenticated'] = false;
    this.routerService.navigate('mobile/home');
    this.localStorageService.clear();
  }, 200);

}

insertSubscription() {
  this.navMenu= [
    // { link: 'online', label: 'Online-assist' , tip:'', auth:true},
    { link: 'subscriptions', label: 'Subscriptions' , tip:'', auth:false},
  ];
}

removeSubscription() {
  this.navMenu=[];
}

subscription() {
  // this.insertSubscription();
  this.routerService.navigate('/subscriptions');
}
startTimer() {
  if (!globalVars['timerOn']) {
    startCountdownTimer(this.routeService);
    globalVars['timerOn']=true;
  }
}

measurements() {
  const swidth = window.innerWidth;
  // const ipad=(navigator.platform === 'MacIntel') || navigator.platform === 'iPad';
  const ipad=['MacIntel','iPad' ].includes(navigator.platform);
  const sheight= window.innerHeight;
  // const sheight= window.innerHeight-200;

  // const sheight= window.innerHeight;
  const side_bar_width=200;
  globalVars['width'] = swidth;
  globalVars['height'] =  sheight;
  globalVars['mobileDevice'] = swidth<=600;
  globalVars['miniDevice'] = swidth<=375;
  globalVars['desktop'] = (globalVars['mobileDevice'] || globalVars['miniDevice']) ? false: true;

  // globalVars['midDevice'] = (swidth>600 && swidth <=768) || ipad;
  globalVars['midDevice'] = (swidth>600 && swidth <=1200);

  this.device = globalVars['mobileDevice']? 'mobile':
                globalVars['midDevice']? 'tablet': 'desktop';
  globalVars['desktop'] = globalVars['mobileDevice'] || globalVars['midDevice']? false: true;
  const top = this.dimension.top;
  this.mobile= globalVars['mobileDevice'];

   let toptopHeight=0;
  if (this.toptopEnabled) {
    toptopHeight = this.mobile ? 17: 5;
  }
  //if (sheight<1000) {
  // if (sheight<800) {
  //   this.navMenu.shift();
  // }
  this.theWidth=swidth;
  switch(this.device) {
    case 'mobile': {
      // this.dimension.app.sheight = Math.floor(sheight*0.9);
      const topUrl=10;
      const bottomNavigator=10;
      const navBar=10;
      // const routingHeight=100-(topUrl+navBar+toptopHeight+bottomNavigator);
      const routingHeight=100-(topUrl+toptopHeight+bottomNavigator);
      globalVars['main-height']=Math.floor(sheight*routingHeight/100);
      globalVars['main-width']=Math.floor(swidth*0.9);


      this.dimension.app.bar={width:90, height:10};
      this.dimension.app.route={width:90,height:routingHeight};
      this.dimension.app.bottom={width:90, height:7};
      // console.log(`width:${swidth}, height:${this.dimension.app.height}`);
      break;
    } case 'tablet': {
      const h10p = Math.floor(sheight*0.1);
      // globalVars['height'] = Math.floor(sheight*0.9);
      this.dimension.app.bar={width:100, height:10};
      //this.dimension.app.route={width:100,height:76};
      this.dimension.app.route={width:100,height:80};
      globalVars['main-height']=Math.floor(sheight*0.8);
      globalVars['main-width']=Math.floor(swidth*0.67);

      // this.dimension.app.bottom={width:100, height:10};
      // console.log('tablet')
      break;
    } default: { //desktop
      this.dimension.sidebar.width = side_bar_width;
      this.dimension.sidebar.height = sheight;
      this.theWidth-=side_bar_width*2 -10;

      const rh = 75-(ipad?10:0);
      let spliceIndex=this.left_casinos.length;
      if (sheight < 800) {
        spliceIndex=4;
      } else if (sheight < 1000) {
        spliceIndex=5;
      } else if (sheight < 1200) {
        spliceIndex=6;
      } else if (sheight < 1400) {
        spliceIndex=7;
      }
      this.left_casinos.splice(spliceIndex,8);
      this.right_casinos.splice(spliceIndex,8);
      this.dimension.app.bar={width:100, height:5};
      this.dimension.app.route={width:100,height:rh};
      this.dimension.app.bottom={width:100, height:5};
      const _rh=ipad?rh: rh-5;
      globalVars['main-height']=Math.floor(sheight*_rh/100);
      globalVars['main-width']=Math.floor(swidth*0.50);


      // console.log(`window.innerWidth:${window.innerWidth}, window.innerHeight:${window.innerHeight}`);
      // console.log(`desktop:window.innerHeight:${window.innerHeight},innerwidth:${swidth} width:${this.theWidth}, height:${sheight}`);
      globalVars['ncasinos'] = Math.floor(rh*sheight/100);
      break;
    }
  }
  globalVars['center-width'] =this.mobile?10: Math.floor(swidth/4);
  globalVars['online-width'] = Math.floor(swidth/6);
  if (this.device=='tablet') globalVars['online-width']=100;
  // globalVars['online-mobile'] = sheight<1000;
  globalVars['online-mobile'] = false;
  imageDims();
  this.restStyle['width']= "100%";
  const ht=this.dimension.app.route.height+this.dimension.app.toptop.height+this.dimension.app.bottom.height;
  this.restStyle['height']= `${ht}%`;
}
///////////////////////////
  ngOnInit() :void{
    this.gvars=globalVars;
    globalVars['vpath']='top';

    this.jcountSeo.defaultSetting();
    // this.jcountSeo.updateTag(
    //       {name:'title', content: lastChild.data['page']},
    //     );
    this.jcountSeo.addTags([
      {name:'keywords', content:'blackjack,jackcount,easy card counting, card counting,card count, card count training,online blackjack,card counting software,card count practice'}

    ]
        );
    this.subscriptions.push(
    this.store
      .select('genSetting')
      .pipe(filter(event => event.typeof=== 'GenSettingModel'))
      .subscribe((data: GenSettingModel) => {
        if (data.action==GenSettingEnum.disableSubscription) {
          this.removeSubscription();
          return;
        }
        if (data.action==GenSettingEnum.enableSubscription) {
          this.insertSubscription();
          return;
        }
        if (data.action==GenSettingEnum.logout) {
          this.onLogoutClick();
          return;
        }
        if (globalVars['credit']>0) {
          if (this.navMenu.length==2) {
            this.navMenu.splice(1,1);
          }
          this.noCredit =false;
          globalVars['isAuthenticated']=true;
          this.forwardMenu();
          this.startTimer();
          this.routerService.navigate('mobile/train');

        } else {
            this.noCredit = true;
            this.routerService.navigate('/subscriptions');
        }
        //console.log(`gensetting-rcvd::${JSON.stringify(data)}, credit:${globalVars['credit']}, nocredit:${this.noCredit}`);
      })
    );
    this.subscriptions.push(
            this.store
              .select('mobileTopBottom')
              .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='parent'))
              .subscribe((data: KeyPressedDm) => {
                if (['home', 'done'].includes(data.key)) {
                  this.routerService.navigate('mobile/train');
                   return;
                }
                if (isPageRoute(data.key)) {
                  setTimeout(() => {
                    pageRoute(this.store, this.routerService, data.key);

                  }, 500);
                  return;
                }
              })
            );
      this.subscriptions.push(
          this.store
            .select('genericReducer')
            .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
            .subscribe((data: GenericDataUnit) => {
              if (data.action==GenericDataUnitEnum.toptopEnabled) {
                this.measurements();
                this.toptopEnabled=true;
                return;
              }
              if (data.action==GenericDataUnitEnum.toptopDisabled) {
                this.measurements();
                this.toptopEnabled=false;
                return;
              }

            }
          )
     );
    this.measurements();

    this.swUpdate.checkForUpdate();
    this.swUpdate.available.subscribe(event => {
      alert('A newer version is now available. Refresh the page now to update the page');
    });
    let timeout=0;
    let id=0;
    const nc=globalVars['ncasinos'];
    setTimeout(() => {
      for (let casino of this.left_casinos.slice(0,nc)) {
        insertVerticalImageAction(this.store, id++, casino);
      }
      for (let casino of this.right_casinos.slice(0,nc)) {
        insertVerticalImageAction(this.store, id++, casino);

      }
    }, 100);


    MobileSendKeyRoute(this.store,[{key:['backward', 'forward', 'up','down','home'], disabled: false}]);

  }


}
