import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
// import { DealerService } from '../../../services/deck.service';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import {ActivatedRoute} from "@angular/router";
import {MobileDrawCardAction, MobileDrawCardResetAction, MobileCardsPeerErase} from '../../store/cards.action.reducer';
import {MobileCountCardDataUnit} from '../../store/cards.model';
import {MobileSendKeyRoute, BottomQuesButtonsSend,BottomResetMenuPressed, BottomAppleModify} from '../../store/bottom.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../../store/generic.model';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';

@Component({
  selector: 'anms-multicards',
  templateUrl: './multicards.component.html',
  styleUrls: ['./multicards.component.scss', '../../styles.scss'],
	animations: [
		trigger('xcardFlip', [
      transition('default => flipped', [
        animate('500ms', style({ transform: 'rotateY(180deg)', opacity: 1 }))
        // animate('500ms')
      ]),
      transition('flipped => default', [
        // animate('500ms', style({ transform: 'rotateY(-180deg)', opacity: 1 }))
        animate('500ms')

      ])
		]),
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(-180deg)'
      })),
      transition('default => flipped', [
        //animate('400ms')
        animate('1000ms')
      ]),
      transition('flipped => default', [
        //animate('200ms')
        animate('500ms')
      ])
    ])
	]
})

export class MultiCardsComponent  implements OnInit, OnDestroy {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
	@Output() event = new EventEmitter();

  subscriptions=[];
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };
 istate = 0;
 state = 'default';
 page = 0;
 ppage = 0;
///////
// constructor
///////
  constructor(public store: Store<any>) { }
  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
  }

  eventHandler(e:any):void {
		this.event.emit(e);
	}
  flip() {
		this.state = this.state == 'default'? 'flipped': 'default';
	}
  ngOnInit() :void {
     this.subscriptions.push(
           this.store
             .select('genericReducer')
             .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
             .subscribe((data: GenericDataUnit) => {
               if (data.action==GenericDataUnitEnum.multiCards) {
								 if (data.mode == 'clear') {
									  // MobileDrawCardResetAction(this.store, this.page);
                    MobileCardsPeerErase(this.store, this.ppage );
										return;
								 }
                 if (this.istate == 0) {
                    MobileDrawCardAction(this.store, this.page);
                    this.page = 1;
                    this.istate = 1;
                    return;
                 }
                 setTimeout(() => {
                   MobileDrawCardAction(this.store, this.page);
                   this.ppage = this.page;
                   this.page = this.page == 0? 1: 0;
                }, 700);
                this.flip();
                const epage = this.page? 0: 1;
                MobileDrawCardResetAction(this.store, epage);
               }
             }
           )
      );

  }

	afterChange(event) {}

}
