 <div  style="margin-top:10px;margin-left:{{gvars['center-width']}}px;" >
     <div class='flex-column mild-black inset-border' style="height:{{pageOffset['height']}}px; margin-top:20px">
        <div style="margin-top:20px">
             <button mat-raised-button
                   *ngFor="let button of buttons"
                    style="width:80%; margin-left:50px"
                    class="{{mouseClass[button]}} margin-top-10px curvey-border" (click)="clicked(button)">{{button}}</button>
        </div>
     </div>
 </div>
