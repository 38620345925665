import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import {  MobileRouterService, globalVars, imageDims, check_credit, m2c} from '../../services/global.service';
import { Store } from '@ngrx/store';
import {BaseListComponent} from '../shared/list/component';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {GenericTopTopAction} from '../store/generic.action.reducer';
import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
@Component({
  selector: 'anms-mobile-card-classification',
  templateUrl: './card.classification.component.html',
  styleUrls: ['../styles.scss'],
  animations: [
    CommonListAnimations.anim,
    CommonListAnimations.manim,
    CommonListAnimations.flip,
    CommonListAnimations.cards
  ]
})
export class MobileCardClassificationComponent implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  //columns=['Count', 'High count', 'Low count', 'Net count', 'Spread', 'comments'];
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  // images= [];
  subscriptions=[];
  savedIndex=0;
  topEnabled=true;
  images=[];
  allImages = [[],[]];
  min_height=0;
  tpage = {width: 0, height:0};
  dims={width:0, height:0};
  cards= {
      'High cards':[
        '/assets/cards/10C',
        '/assets/cards/JD',
        '/assets/cards/QH',
        '/assets/cards/KS',
        '/assets/cards/AC',
      ],
      'Low cards': [
        '/assets/cards/2C',
        '/assets/cards/3D',
        '/assets/cards/4H',
        '/assets/cards/5S',
        '/assets/cards/6C',
      ],
      'Ignored cards': [
        '/assets/cards/7C',
        '/assets/cards/8D',
        '/assets/cards/9H',
      ],
  };

  odds = [
    {
    first: [m2c('10C'), m2c('2C')],
    second: [m2c('10C'), m2c('10C') ]
    },
    {
    first: [m2c('2C'), m2c('10C') ],
    second: [m2c('2C'), m2c('2C') ]
    }
  ];



  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
    arrows: false,
   "infinite": false,
   // 'showArrow': false,
   'swipeToSlide': true,
 };

  disabled = {
    next:false,
    previous: true
  };
  top=[0,0,0];
  left=[0,0];
  gvars=globalVars;
  cardsLikelihood = ['high-cards @25%', 'low-cards @25%', 'mixed-cards @50%'];

  cardsNumber = [
    ' Single-deck: 20 ',
    ' Double-decks: 40 ',
    ' Six-decks: 120 '
  ];

  indexUsed=[0, 0];
  pcntgEnabled=false;
  constructor(
    public store: Store<any>,
     private router: MobileRouterService
            ) {
              imageDims();
      }


  ngOnDestroy() :void{
     for (const elm of this.subscriptions)
          elm.unsubscribe();
    this.odds=[];
    this.images=[];
    this.allImages=[];
     this.topEnabled=false;
   }

   index2keys(index) {
     switch(index) {
       case 0: return 'High cards';
       case 1: return 'Low cards';
       default: return 'Ignored cards';

     }
   }

   setImages(key) {
     this.images=[...this.cards[key]];
   }

   setAllImages() {
     this.allImages[0]=[...this.cards['High cards']];
     this.allImages[1]=[ ...this.cards['Low cards']];

   }

   cclicked(i, j) {
     if (j) {
       const images = this.allImages[i];
       images.splice(j, 1);
       return;
     }
    this.slickModal.slickNext();
   }

   topview(page)  {
     this.disabled.previous=false;
     this.disabled.next=false;
     this.pcntgEnabled=false;

     switch(page) {
       case 0 : { // method
          // this.stage='High cards';
          GenericTopTopAction(this.store, ['Five unique high-cards', 'Four different suits'], 'tigger');
          this.disabled.previous=true;
          break;
       } case 1: { //decks
         GenericTopTopAction(this.store, ['Five unique low-cards', 'Four different suits'], 'tigger');
         break;
       } case 2: { // cards-out
         GenericTopTopAction(this.store, ['To keep card counting simple', 'Ignore these 3 cards'], 'tigger');
         break;
       } case 3: { // cards-out
          GenericTopTopAction(this.store, ['High and low card', 'Balance'], 'tigger');
          break;
        }
        case 4: {
          GenericTopTopAction(this.store, ['All possible cards'], 'tigger');
          this.pcntgEnabled=true;
          this.disabled.next=true;
        }

     }
   }

   afterChange(event:any):void {
    // restore everything to what globalvars is pointing to.
    const page=event.currentSlide;
    if (page < 3) {
       this.setImages(this.index2keys(page));
    } else if (page>=3){
       this.setAllImages();
    }
    this.topview(page)
  }

   clicked(key) {
     const length = this.images.length;
     this.images=[];
     setTimeout(() => {
       switch(key) {
         case 'forward': {
           this.slickModal.slickNext();
           break;
         }
         case 'backward': {
           this.slickModal.slickPrev()
           break;
         }
         default: this.router.navigate('mobile/concept-top');
       }
     // }, length*600);
   }, 0);


   }

   ngOnInit() :void {

     check_credit(this.router);

     GenericTopTopAction(this.store, ['Five unique high-cards', 'Four different suits'], 'tigger');

     BottomAppleModify(this.store, { enable:['menu', 'backward', 'forward']});
     const cheight = globalVars['image'].card.height;
     const cwidth = globalVars['image'].card.width;
     this.top[0] = 60 + Math.ceil(cheight/2);
     this.top[1] = this.top[0] + cheight;
     this.top[2] = this.top[1] + cheight;

     this.left[0] = 40 + Math.ceil(cwidth/2);
     this.left[1] = this.left[0]+(2*cwidth);
     this.dims = {width: cwidth, height: cheight};
     const image=globalVars['image'];
     this.min_height=image.card.height+70;

      for (const key in this.cards) {
        this.cards[key] = this.cards[key].map(img=> `${img}${image.dims}.png`);
      }


    this.subscriptions.push(
        this.store
          .select('mobileTopBottom')
          .pipe(filter(data => data.typeof=== 'KeyPressedDm' ))
          .subscribe((data: KeyPressedDm) => {
             this.topEnabled=false;
             if (data.target == 'child') {
                const page = data.key == 'forward'? 'mobile/adding-strategy': 'mobile/train';
                setTimeout(() => {
                  this.router.navigate(page);
                }, 100);
              }
          })
      );
      this.subscriptions.push(
            this.store
              .select('genericReducer')
              .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
              .subscribe((data: GenericDataUnit) => {
                if ([GenericDataUnitEnum.die, GenericDataUnitEnum.refreshEvent].includes(data.action)) {
                  //alert(`receive reset from app.comp`)
                  this.topEnabled=false;
                  return;
                }
              }
            )
       );

      this.setImages(this.index2keys(0));
      // this.setAllImages();
    }
  }
