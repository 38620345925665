import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LocalStorageService } from './local-storage/local-storage.service';
import { authReducer } from './auth/auth.reducer';
import { AuthEffects } from './auth/auth.effects';

import { reduceAnimationReducer } from '../store/animation.reduce.action.reduce';
import { GenSettingReducer } from '../store/gen-setting.action.reducer';
import { certificateReducer } from '../store/certificate.action.reducer';
import { topBottomReducer} from '../mobile/store/bottom.action.reducer';
import { GenericReducer,FlipCardReducer} from '../mobile/store/generic.action.reducer';

import {MobileCardReducer, CardBadgeReducer} from '../mobile/store/cards.action.reducer';
import {MobileBetReducer} from '../mobile/store/bet.action.reducer';
import {ServerErrorReducer} from '../store/server.action.reducer';
import {verticalImageReducer} from '../store/vimage.action.reducer';
import { switchCardReducer } from '../store/switchcards.action.reducer';


export function getInitialState():any {
  return LocalStorageService.loadInitialState();
}

//       @NgModule({
//   imports: [
//     StoreModule.forRoot(reducers, {
//       runtimeChecks: {
//         strictStateImmutability: false,
//         strictActionImmutability: false,
//       },
//     }),
//   ],
// })
const reducers = {
  auth: authReducer,
  scard: switchCardReducer,
  reduceAnimation: reduceAnimationReducer,
  genSetting: GenSettingReducer,
  certificate: certificateReducer,
  mobileCard: MobileCardReducer,
  mobileBet: MobileBetReducer,
  serverError: ServerErrorReducer,
  vimages:verticalImageReducer,
  mobileTopBottom: topBottomReducer,
  genericReducer:GenericReducer,
  flipCardReducer:FlipCardReducer,
  cardBadge:CardBadgeReducer

};
@NgModule({
  imports: [
    // angular
    CommonModule,
    HttpClientModule,

    // ngrx
    StoreModule.forRoot(
      reducers,
      { initialState: getInitialState }
    ),
    EffectsModule.forRoot([AuthEffects])
  ],
  declarations: []
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
