import { Injectable } from   '@angular/core';
import { timer, Observable,Subscription, Subject  } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
//import * as _ from 'lodash';

const INSURANCE = 'Insurance';
const INSURANCE_YES = 'Insurance-yes';
const INSURANCE_NO = 'Insurance-no';
const SPLIT = 'split';
const STAND = 'stand';
const DOUBLE = 'double';
const HIT = 'hit';




export const print = (lst, spread=false) => {
  if (typeof lst === 'string') {
       //console.log(lst);
       return;
  }
  for (const key of lst) {
    if (typeof key === 'string') {
       //console.log(key);
    } else {
       if (spread) {
           //console.log(JSON.stringify(key));
       } else {
           //console.log(JSON.stringify(key));
       }
    }
  }
};

export const START_FLASH="StartFlash";
export const FADEOUT="FadeOut";
export const FADEIN="FadeIn";



export interface TaskIf {
  id:string;
  interval: number;
  schedule: boolean;
  callback():void;
}

@Injectable({providedIn: 'root'})
export class OnesecTimer {
   callbacks = {};
   observable: any;
   subjectStop: any;
   counter: number;
   //
   // pushing different callbacks on the ticker
   //
   schedule(task: TaskIf): void {
     if (!(`${task.interval}` in this.callbacks)) {
          this.callbacks[task.interval] =[task];
          return;
     }
     this.callbacks[task.interval].push(task);
   }
   stopTimer(): void {
     this.subjectStop.next();
   }
   reset() : void{
     this.callbacks = {};
   }
   deSchedule(task) : void {
     const list = this.callbacks[task.interval] ;
     let index=-1;
     let findex=-1;
     for (const elm of list) {
         index++;
         if (elm.id == task.id) {
           findex=index;
           break;
         }
     }
     if (findex != -1) {
         this.callbacks[task.interval].splice(findex, 1);
         if (this.callbacks[task.interval].length === 0) {
             delete this.callbacks[task.interval];
         }
     }
   }
   // constructor (cycle=1000) {
   // don't fuck around with constructor of class that has injectable on it, the only thing that should be added is;
   // another service like, private dealerService: DealerService, the simple constructor creates all kinds of problems.
     constructor () {

     this.subjectStop = new Subject<any>();
     this.observable =  timer(100, 1000).pipe(takeUntil(this.subjectStop));
     let count = 0;
     this.counter = 0;
     this.observable.subscribe(
        tick => {
           this.counter++;
           const intervals = Object.keys(this.callbacks);
           if (intervals.length) {
              for (const interval of intervals) {
                  if ( (this.counter % +interval) === 0 ) {
                        for (const callback of  this.callbacks[interval]) {
                            callback.callback();
                        }
                  }
              }
              count=0;
           } else {
              if (++count === 500000) {
                // stop the timer nothing to be done
                this.stopTimer();
              }
           }
        }
      );


   }
}
@Injectable({providedIn: 'root'})
export class CommonService {

/*
 * Not being used now
 * change the attribute of elementRef
 * usage:elmRefModifyAttr(this.nameInputRef,'class',{'from':'btn-primary','to':'btn-danger'});
elmRefModifyAttr(ref:ElementRef,attr:string,change:{}) :void {
   let what=ref.nativeElement.attributes[attr].value;
   if ('from' in change) {
     if (what.indexOf(change['from'])!=-1) {
       ref.nativeElement.attributes[attr].value=what.replace(change['from'],change['to']);
     }
   } else {
       ref.nativeElement.attributes[attr].value=change['to'];
   }
}
*/



  // measure time from when the cards out to the time user enters input
 response_time(itime:number) : any{ // initial time
         let e_hsh=this.time_elapsed(itime);
         // don't need the following to print the time elapsed in ?d?h?m?s
         //$scope.timeit.response=timehsh2str(e_hsh);
         //$scope.timeit.rth.push(e_hsh.elapsed);
         return e_hsh.elapsed
 }
    // calculates average response time
 averageTime(responses:number[]) : any{
       let sum = responses.reduce(function(acc, val) { return acc + val; }, 0);
       let avr=Math.floor(sum/ responses.length);
       return this.timehsh2str(this.time_elapsed_hsh(avr));
 }


time_elapsed_hsh(elapsed:number): any {
   let minutes=60; // 60 sec
   let hours=60*minutes;
   let days=24*hours;
   let elapsed_persec=Math.floor(elapsed/1000);
   let e_days=Math.floor(elapsed_persec/days);
            elapsed_persec-=e_days*days;
   let e_hours=Math.floor(elapsed_persec/hours);
         elapsed_persec-=e_hours*hours;
   let e_minutes=Math.floor(elapsed_persec/minutes);
            elapsed_persec-=e_minutes*minutes;
   return {elapsed:elapsed,days: e_days, hours:e_hours,minutes:e_minutes,seconds:elapsed_persec};
}


time_elapsed (pretime:number) :any {
        //return this.time_elapsed_hsh(Date.now()-pretime);
        let t= this.time_elapsed_hsh(Date.now()-pretime);
        return t;
}

timehsh2str(elapsed:{days:number,minutes:number,hours:number,seconds:number}) :any {
         let str='';
         if (elapsed.days) str+=elapsed.days+'d';
         if (elapsed.hours) {
            let space='';
            if (str.length) space=' ';
            str+=space+elapsed.hours+' hr';
         }
         if (elapsed.minutes) {
            let space='';
            if (str.length) space=' ';
            str+=space+elapsed.minutes+' min';
         }
         if (elapsed.seconds) {
            let space='';
            if (str.length) space=' ';
            str+=space+elapsed.seconds+' sec';
         } else {
            str+=' 00 sec';
         }
         return str;
    }


count2object(count: string,level : string) :any {

   let rval={};
   if ((/\+|-/).exec(count)) {
      rval['sign'] = count[0];
      count=count.slice(1);
   }
   let div = level =='advance' ? 100 : 10;
   let val=+count;
   rval['high']= Math.floor(val/div);
   rval['low']= val%div;
//console.log(`count:${count}, level:${level}, rval:${JSON.stringify(rval)}`);
   return rval;
}
object2count(count: {},level:string) :any {
   let div = level === 'advance' ? 100 : 10;
   let sign ='sign' in count ? count['sign']:"";
   let rval = `${sign}${count['high']*div+count['low']}`;
//console.log(`rval:${rval}, level:${level}, count:${JSON.stringify(count)}`);
   //return `${sign}${count['high']*div+count['low']}`;
   return rval;
}

}

export type FilterType = { only?: Array<string>, except?: Array<string>};


export class FlashButtons {
  observable = {};
  flashed = [];
  saved = [];
  //
  // for each button in button list there should be associated obj that specifies state, color and other info
  constructor(private buttonList: string [], private objs: {}) {
     for (const button of buttonList) {
        this.observable[button] = {destroy: new Subject<any>()};
     }
     this.flashed = [];
  }
  snapshot(): any {
    this.saved = this.flashed.slice();
  }

  restore(extra: {}): any {
    while(this.saved.length) {
      this.do(this.saved.shift(), extra);
    }
  }

  do(button: string,  extra: {},   pause?: number) :any {
    pause = pause || 100;
    // avoid repeat request to same button
    if (this.flashed.includes(button)) {
      return ;
    }
    const observable = this.observable[button];
    observable['interval'] = timer(pause, 800).pipe(takeUntil(observable['destroy']));
    //observable['interval'] = timer(pause, 1000).pipe(takeUntil(observable['destroy']));

    // console.log(`active flash are: ${this.flashed}`);
    const obj = this.objs[button];
    for (const key of Object.keys(extra)) {
      obj[key] = extra[key];
    }
    if ('flash' in extra) {
      return ;
    }
    this.flashed.push(button);
    observable['subscription'] =
      observable['interval']
        .subscribe(tick => {
          switch (obj['status']) {
            case START_FLASH: {
              obj['status'] = FADEOUT;
              break;
            }
            case FADEOUT: {
              obj['status'] = FADEIN;
              break;
            }
            case FADEIN: {
              obj['status'] = FADEOUT;
              break;
            }
          }
        });

  }

  undo(extra: {}, filter?: FilterType): any {
     let button = '';
    const list = [];
    while ( button = this.flashed.shift() ) {
      //  console.log(`undo button:${button}`);
      // change this to always list that needs be flashing
      if (button === 'Count') {
        continue;
      }
      if (filter ) {
        // console.log(`undo button:filter::${JSON.stringify(filter)}`);
        if ('only' in filter) {
            if (!filter['only'].includes(button)) {
               list.push(button);
               continue;
            }
        } else if ('except' in filter) {
          if (filter['except'].includes(button)) {
            list.push(button);
            continue;
          }
        }
      }
      const observable = this.observable[button];
      const obj = this.objs[button];
      for (const key of Object.keys(extra)) {
        obj[key] = extra[key];
      }
      obj['status'] = START_FLASH;
       observable['destroy'].next();
    }
    this.flashed = this.flashed.concat(list);
  }
  destroy() {
    for (const button of this.buttonList) {
       this.observable[button]['destory'] = null;
    }
  }
}

export class Flash {

    obj: any;
    stop = false;
    active = false;
    observable:  Subject<any>;
    subscription: Subscription;
    constructor() {
       this.observable = new  Subject<any>();
       this.subscription = new Subscription();

    }

    flash(obj: any, start=100, duration=800):any {
      if (this.active) return;
      this.active = true;
      const interval = timer(start, duration).pipe(takeUntil(this.observable));
      this.stop = false;
      obj['status']= START_FLASH;
      this.subscription =
       interval.subscribe(tick => {
         if (this.stop) {
            this.observable.next();
            this.active = false;
            this.subscription.unsubscribe();
            return;
         }
          switch (obj['status']) {
            case START_FLASH: {
              obj['status'] = FADEOUT;
              break;
            }
            case FADEOUT: {
              obj['status'] = FADEIN;
              break;
            }
            case FADEIN: {
              obj['status'] = FADEOUT;
              break;
            }
          }
        });
        this.obj = obj;
   }

   unflash() {
      this.stop = true;
   }

}

export class nFlash {

    stop = false;
    active = false;
    observable:  Subject<any>;
    subscription: Subscription;
    status='None';
    constructor() {
       this.observable = new  Subject<any>();
       this.subscription = new Subscription();

    }
    isActive() { return this.active;}
    flash( start=100, duration=800):any {
      if (this.active) {
        return;
      }
      this.active = true;
      const interval = timer(start, duration).pipe(takeUntil(this.observable));
      this.stop = false;
      this.status= START_FLASH;
      this.subscription =
       interval.subscribe(tick => {
         if (this.stop) {
            this.observable.next();
            this.active = false;
            this.subscription.unsubscribe();
            return;
         }
          switch (this.status) {
            case START_FLASH: {
              this.status = FADEOUT;
              break;
            }
            case FADEOUT: {
              this.status = FADEIN;
              break;
            }
            case FADEIN: {
              this.status = FADEOUT;
              break;
            }
          }
        });
   }

   unflash() {
      this.stop = true;
   }

}

export class n2Flash {

    stop = false;
    active = false;
    observable:  Subject<any>;
    subscription: Subscription;
    status='None';
    textObj={};
    values = [];
    textIndex = 0;
    colorsObj: any;
    colorsValue: any;
    constructor(textObj,  values, colorsObj, colorsValue) {
       this.observable = new  Subject<any>();
       this.subscription = new Subscription();
       this.textObj=textObj;
       this.values = values;
       this.colorsObj = colorsObj;
       this.colorsValue = colorsValue;

    }
    isActive() { return this.active;}
    flash( start=100, duration=1600):any {
      if (this.active) {
        return;
      }
      this.active = true;
      const interval = timer(start, duration).pipe(takeUntil(this.observable));
      this.stop = false;
      this.status= START_FLASH;
      this.subscription =
       interval.subscribe(tick => {
         if (this.stop) {
            this.observable.next();
            this.active = false;
            this.subscription.unsubscribe();
            return;
         }
          switch (this.status) {
            case START_FLASH: {
              this.status = FADEOUT;
              this.textObj['value'] = this.values[0];
              this.colorsObj['value'] = this.colorsValue[0];

              break;
            }
            case FADEOUT: {
              this.status = FADEIN;
              this.textObj['value'] = this.values[1];
              this.colorsObj['value'] = this.colorsValue[1];

              break;
            }
            case FADEIN: {
              this.status = FADEOUT;
              this.textObj['value'] = this.values[0];
              this.colorsObj['value'] = this.colorsValue[0];

              break;
            }
          }
        });
   }

   unflash() {
      this.stop = true;
   }

}

//test2(2,120)
export class BasicStrategy {
   hard(player, d1card) : any{
     const psum = player.sum;
     const clength= player.cards.length;
     switch(psum) {
       case 17: case 18 : case 19 : case 20 : case 21 : return STAND;
       case 13: case 14: case 15: case 16: {
          return [2,3,4,5,6].includes(d1card)? STAND: HIT;
       }
       case 12: return [4,5,6].includes(d1card)? STAND: HIT;
       case 11 : return clength === 2 ? DOUBLE: HIT;
       case 10 : if (clength==2) { return [10,1].includes(psum)? HIT: DOUBLE;}
                 else return HIT;
       case 9: if (clength==2) { return [3,4,5,6].includes(psum)? DOUBLE:HIT;}
                 else return HIT;
       default: return HIT;

     }
   }
   soft(player, d1card) :any {
     const cards=player.cards;
     if (cards.includes(9) || cards.includes(10)) return STAND;
     if (cards.includes(8)) return d1card===6 ? DOUBLE: STAND;
     if (cards.includes(7)) {
       if ([2,3,4,5,6].includes(d1card)) return DOUBLE;
       if ([7,8].includes(d1card)) return STAND;
       return HIT;
     }
     if (cards.includes(6)) {
       if ([3,4,5,6].includes(d1card)) return DOUBLE;
       if ([7,8,9,10,1,2].includes(d1card)) return HIT;
     }
     if (cards.includes(5) || cards.include(4)) {
       if ([4,5,6].includes(d1card)) return DOUBLE;
       if ([3,7,8,9,10,1,2].includes(d1card)) return HIT;
     }
     if (cards.includes(3) || cards.includes(2)) {
       if ([5,6].includes(d1card)) return DOUBLE;
       if ([4,3,7,8,9,10,1,2].includes(d1card)) return HIT;
     }
   }
   split(player, d1card) :any {
     const pcard = player.cards[0];
     switch(pcard) {
        case 8:
        case 1: return SPLIT;
        case 10: return STAND;
        case 9: return [7,10,1].includes(d1card) ? STAND: SPLIT;
        case 7: return [8,9,10,1].includes(d1card) ? this.hard(player, d1card): SPLIT;
        case 6: return [2,7,8,9,10,1].includes(d1card) ? this.hard(player, d1card): SPLIT;
        case 4:
        case 5: return this.hard(player, d1card);
        case 2:
        case 3: return [4,5,6,7].includes(d1card) ? SPLIT: HIT;
     }
   }
   rule(player, d1card): any {
     const cards = player.cards;
     const length = cards.length;
     if (length > 2) return this.hard(player,d1card);
     if (cards.includes(1)) return this.soft(player, d1card);
     if (cards[0] === cards[1]) return this.split(player,d1card);
     return this.hard(player, d1card);
   }
}


 export const  GetDays = (t) => {

    let days;
    days = Math.floor( t / 86400);
    return days;

  }

  export const GetHours = (t) =>{

    let hours, days;
    days = Math.floor( t / 86400 );
    t -= days * 86400;

    hours = Math.floor( t / 3600 ) % 24;
    return hours;

  }



  export const GetMinutes=  (t) => {

    let hours, days, minutes;
    days = Math.floor( t / 86400 );
    t -= days * 86400;

    hours = Math.floor( t / 3600 ) % 24;

    t -= hours * 3600;

    minutes = Math.floor ( t / 60 ) % 60 ;
    return minutes;

  }

  export const GetSeconds = (t) => {

    let hours, days, minutes, seconds;
    days = Math.floor( t / 86400 );
    t -= days * 86400;

    hours = Math.floor( t / 3600 ) % 24;

    t -= hours * 3600;

    minutes = Math.floor ( t / 60 ) % 60 ;

    t -= t * 60;

    seconds = Math.abs(t % 60) ;
    return (60 - seconds);

  }


  export const timeSplit = (t) => {
    let hours, days, minutes, seconds;

    days = Math.floor( t / 86400 );
    t -= days * 86400;

    hours = Math.floor( t / 3600 ) ;

    t -= hours * 3600;

    minutes = Math.floor ( t / 60 )  ;
    t -= minutes * 60;
    return {days: days, hours: hours, minutes: minutes, seconds: t}

  }
