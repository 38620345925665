import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
// import { DealerService } from '../../../services/deck.service';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { isJack,globalVars,MobileRouterService,SpeedInSec,getMethodDesc,getTheLevel,decksMapping, maxCardsOut, imageDims, setNumberMargin,
	pageDims,calcAverageTimeElapsed, gcardsDims, check_credit} from '../../../services/global.service';

import { DealerService } from '../../../services/deck.service';

import {ActivatedRoute} from "@angular/router";

import {KeyPressedDm, BottomQuesButtonsPressed} from '../../store/bottom.model';
import {MobileSendKeyRoute, BottomQuesButtonsSend,BottomResetMenuPressed, BottomAppleModify,BottomQuesButtonsLessonSend} from '../../store/bottom.action.reducer';


import { MobileDisplayDoubleCardsCount, MobileDrawCardResetAction} from '../../store/cards.action.reducer';
import {MobileCountCardDataUnit} from '../../store/cards.model';
import {Shuttle, GroupButtons, Button, GroupButtonsEnum,GetCount } from '../../shared/classes/common';
import { GenericTopTopAction,GenericSlideCountTrain,GenericSlideLicenseTrain,GenericSlideConceptTrain, GenericTimerAction,
	  GenericSingleCardTrain,GenericSingleSpeedTrain, GenericTimerElapsedAction, GenericIncrHands, GenericMultiDrawCards,  GenericMultiResetCards} from '../../store/generic.action.reducer';
import { GenericDataUnitEnum, GenericDataUnit } from '../../store/generic.model';
import {BottomNumbersComponent} from '../numbers/component';
import {ThumbsTimerComponent} from '../thumbs-timer/component';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';

const IDLE = 0;
enum SettingActiveEnum {
	active,
	idle
};

enum CardsStateEnum {
	drawn,
	clear
};


@Component({
  selector: 'anms-mobile-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss', '../../styles.scss'],
	animations: [
			trigger('cardFlip', [
				state('default', style({
					transform: 'none'
				})),
				state('flipped', style({
					transform: 'rotateY(180deg)'
				})),
				transition('default => flipped', [
					//animate('400ms')
					animate('1000ms')
				]),
				transition('flipped => default', [
					//animate('200ms')
					animate('500ms')
				])
			])
		]
})

export class MobileTestingComponent implements OnInit, OnDestroy {
  @ViewChild("number") number: BottomNumbersComponent;
	@ViewChild("thumbs_timer") thumbsTimer: ThumbsTimerComponent;

  subscriptions=[];
  unit='';
  gvars = {};
  cardCount='0';
  buttons: Array<Button>;
  reroute=false;
  buttonsEnabled=false;
  tbuttons : GroupButtons;
  userPossibleCount : {list:any, clist: any};
  shuttle800: Shuttle;
  dropShuffle=false;
  hcolor='accent';
  firstDraw=true;
  continuous=false;
  die=false;
  cardState=CardsStateEnum.clear;
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };
 settingState:SettingActiveEnum=SettingActiveEnum.idle;
 settingWasActive=false;
 netcount=false;
 showNumbers=true;
 numberMargin=0
 settingFunc=null;
 numberControlSetup=true;
 label='';
 settingKey='';
 marginTop='10px';
 topLeft=0;
 topPos=0;
 restore={
	 cards:false
 }
 go2top=false;
 cardsDrawn = {time:new Date().valueOf(), count:0, average:0.0};
 tpage={width:0, height:0};
 buttonsMarginLeft = 0;
 thumbsLeft = 0;
	nextEnabled = false;
///////
// constructor
///////
  constructor(
    private activatedRoute: ActivatedRoute,
    private dealerService: DealerService,
    private router: MobileRouterService,
    public store: Store<any>,
            ) {
          this.gvars = globalVars;
          globalVars['state']= 'train-testing';
          this.buttons=[];
          for (let i=0; i!=4; i++) {
            this.buttons.push( new Button(`${i}`));
          }
          const tbuttons =[];
          for (let i=0; i!=2; i++) {
            tbuttons.push(new Button(`0`));
            tbuttons[i].color = i?'warn':'accent';
            tbuttons[i].changed = false;
          }

          this.tbuttons = new GroupButtons(tbuttons, GroupButtonsEnum.one, ['correct', 'wrong'])
          this.tbuttons.setLength(2);
          this.shuttle800= new Shuttle(1000); //changed to 1000
          const modifyIcon = (button:any, icon:any) => {
            if (button.changed == false || button.name =='0') {
              return;
            }
            if (button.icon) {
              button.icon = false;
            } else {
              button.icon = true;
              button.iconValue=icon;
            }
          }
          this.shuttle800.add(()=> {
            modifyIcon(this.tbuttons.get('correct'), 'thumb_up');
          });
          this.shuttle800.add(()=> {
            modifyIcon(this.tbuttons.get('wrong'), 'thumb_down');
          });
   }
////////
  	afterChange(event) {}
  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
     this.shuttle800.destroy();
     globalVars['mode']='';
     globalVars['testing'] = false;
     globalVars['state'] = 'top';
     BottomResetMenuPressed(this.store, true);
		 this.dealerService.shuffle();
		 if (this.restore.cards)
		     globalVars['cardsOut']=3;
  }
/////////

/////////
// ngOninit task
/////////


setTrainingMode() {
	return globalVars['mode'].includes('practice')? GenericSlideLicenseTrain: GenericSlideCountTrain;
}

eventHandler(e:any):void {
	// alert(`rcvd event ${e}`)
  switch(e) {
    case 'removed': {
			if (this.go2top) {
					this.router.navigate('mobile/count-top');
					return;
			}
			if (this.settingWasActive) {
				globalVars['setting'] = {
				 func: GenericSlideCountTrain,
				 route:`mobile/tests/${this.unit}`,
				 speed:800,
				 mode:globalVars['mode']
			 };
				this.router.navigate('mobile/setting-train');
				return;
			}
			if (this.reroute) {
				globalVars['setting'] = {
					func: this.settingKey=='speed'?  GenericSingleSpeedTrain: GenericSingleCardTrain,
					route:`mobile/tests/${this.unit}`,
					speed:800,
					mode:globalVars['mode']
				}
				this.router.navigate('mobile/setting-train');
				return;
			}


			if (this.netcount) {
				this.dealerService.reduceit();
			}
			if(this.dealerService.isCountTooHigh()) {
				setTimeout(() => {
					alert("Shuffling the decks, reset the count.");
					this.dealerService.shuffle();
					this.draw();
				}, 1000);
			}
			//else this.draw();
			// BottomAppleModify(this.store, {disable:['forward'],flash:{forward: false}});
      this.showNumbers=true;
      break;
    } case 'drawn': {
			this.showNumbers=true;
      this.fillupCountButs(this.getCount());
      this.cardState=CardsStateEnum.drawn ;
			BottomAppleModify(this.store, { enable:['lastIcon']});
			GenericIncrHands(this.store);
			this.cardsDrawn.time=new Date().valueOf();
      break;
		} case 'badgeDrawn': {
			setTimeout(() => {
				this.nextEnabled = true;
			}, 400);
			break;
    } default: { // shuffle event
      // console.log(`received shuffle`)
      alert("Shuffling the decks, reset the count.");
      this.draw();
    }
  }
}

fillupCountButs(expected:any):void {
	// console.log(`expected-value:${expected}`);
   this.userPossibleCount = GetCount(expected, this.netcount);
	 if (globalVars['learning']) {
		 BottomQuesButtonsLessonSend(this.store, this.userPossibleCount.list, this.continuous, this.userPossibleCount.clist[0]);
		 return;
	 }
	 setTimeout(() => {
		 BottomQuesButtonsSend(this.store, this.userPossibleCount.list, this.continuous, this.userPossibleCount.clist[0]);
	 }, 100);
}

track(compared) :void{
    this.tbuttons.track(compared);
}

tevent(event) {
	  this.thumbsTimer.thumbsevent(event);
}

istate() {
    const msg=[getMethodDesc(), getTheLevel(), `${decksMapping()} ${this.label}`];
    GenericTopTopAction(this.store, msg);
    BottomAppleModify(this.store, {enable:['backward', 'lastIcon'], disable:['forward'],  lastIcon:'settings'});
  }

timeout():any {
  const cout = globalVars['cardsOut'];
  const timePerRow=1300; // 1second
  const npanels = globalVars['npanels']
  return npanels*timePerRow;
}

 reset_count() {
	 const reset=globalVars['method']=='ten'? 20*globalVars['ndecks']: 0;
	alert(`Resetting the count back to '${reset}'`);
 }

 reset() {
	 this.reset_count();
	 this.draw();
   this.tbuttons.reset();
  	this.dealerService.shuffle();
}

go2continueous() {
	this.reset();
	globalVars['cach'] = {
		unit: this.unit,
		vpath: globalVars['vpath'],
		label: this.label,
		netcount: this.netcount,
		continuous: false,
		mode: globalVars['mode']
	};
	this.continuous=true;
	globalVars['vpath']='count/tests/Continuous';
	this.unit="Continuous";
	this.label="Continuous test";
	this.istate();

	this.netcount=true;
	globalVars['mode']='Continuous';
}

cachRestore() {
	this.reset();
	const cach=globalVars['cach'];
	this.unit=cach['unit'];
	globalVars['vpath']=cach['vpath'];
	this.label=cach['label'];
	this.netcount=cach['netcount'];
	this.continuous=false;
	this.istate();
	globalVars['mode']=cach['mode'];
	globalVars['cach']=null;

}

  ngOnInit() :void {

		check_credit(this.router);

    BottomResetMenuPressed(this.store);

     globalVars['testing']=true;
		 const margins = setNumberMargin();
    this.tpage = {...pageDims()};
		this.tpage.height += 150;
		const cardsDims = {...gcardsDims()};
		this.thumbsLeft = this.tpage.width - (cardsDims.width*4+60);
		this.buttonsMarginLeft = this.tpage.width -(4*cardsDims.width+50) ;
    this.hcolor = globalVars['method'] == 'mit' ? 'warn': 'accent';
    this.marginTop=globalVars['mobileDevice']?'20px': '10px';
    this.subscriptions.push(
         this.activatedRoute.params.subscribe( ( params:any) => {
          this.unit = params['id'];
          globalVars['mode']=params['id'];
					const practice = params['id'].includes('practice');
					const continuous=params['id'].includes('Continuous');
					globalVars['vpath']=`count/tests/${this.unit}`;
					this.label=practice? 'Certification practice': params['id']+ ' test';
					if (this.label.includes('net')) {
						this.label=this.label.replace('net', 'running').replace('-1', '');
					} else if (continuous) {
						this.label="Continuous test";
					}
				  this.netcount=params['id'].includes("net") || continuous;
          this.continuous =  continuous|| practice;
					if (practice) {
						globalVars['adjustSpeed']=true;
						globalVars['mode']='practice';
						globalVars['cardsOut'] = maxCardsOut();
						const margins = setNumberMargin();
						this.restore.cards=true;
						this.netcount=true;
					}
					globalVars['dropCards']=continuous? false: true;
					this.istate();
       }));

     this.subscriptions.push(
             this.store
               .select('mobileTopBottom')
               .pipe(filter(event=> event.typeof=== 'BottomQuesButtonsPressed'))
               .subscribe((data: BottomQuesButtonsPressed) => {
                 const clist = this.userPossibleCount.clist;
                 switch(data.button) {
									 // alert(`data.button:${data.button}`)
									 case 'lesson': {
										 globalVars['testing']=false;
										 let net=false;
										 if (globalVars['cach']) {
											 net=globalVars['cach']['netcount'];
											 globalVars['cach']=null;
										 }
										 const route=net || this.unit.includes('net') ? 'mobile/lessons/net-count': `mobile/lessons/adding-count`
										 this.router.navigate(route);
										 return;
									 } case 'continuous': {
									   	this.go2continueous();
										  return;
										}case 'reset': {
											this.reset();
											return;
									 } case 'test' : {
										 this.cachRestore();
										 return;
									 } case 'go2top' : {
										 this.go2top=true;
										 this.draw();
										 return;
									 }
                   case 'speed':
									 case 'cards':{
										 // this.reroute=true;
						         // this.settingKey=data.button;
										  setTimeout(() => {

												 globalVars['setting'] = {
								 					func: data.button=='speed'?  GenericSingleSpeedTrain: GenericSingleCardTrain,
								 					route:`mobile/tests/${this.unit}`,
								 					speed:800,
								 					mode:globalVars['mode']
								 				}
								 				this.router.navigate('mobile/setting-train');
												 	 }, 200);

										 return;
									 }

									  default: {
											GenericTimerElapsedAction(this.store, calcAverageTimeElapsed(this.cardsDrawn));
											//alert(`cvalue:${clist[0]}, pressed-button:${data.button}`);
											if (clist[0] ==data.button) { // if pressed correct number
		                    this.track(true);
												this.draw();
		 								 } else {
		 	             	   MobileDisplayDoubleCardsCount(this.store); //
		                    this.track(false);
		 									 // BottomAppleModify(this.store, {enable:['forward'],flash:{forward: true}});
		                    this.showNumbers=false; // this causes the -> button to be active on the page
		                  }
										}
								 }

               })
             );
       this.subscriptions.push(
           this.store
             .select('mobileTopBottom')
             .pipe(filter(data => data.typeof=== 'KeyPressedDm'))
             .subscribe((data: KeyPressedDm) => {
							 if (data.target=='parent') {
								  this.die=true;
									return;
							 }
							switch(data.key) {
								case 'home' : {
										this.die=true;
										setTimeout(() => {
											this.router.navigate('mobile/home');
										}, 200);
									break;
								}
			           case 'forward': {
								       if(this.settingState==SettingActiveEnum.active) { return; }
                       this.draw();
								       break;
								   } case 'backward' : {
								       if(this.settingState==SettingActiveEnum.active) { return; }
                       this.die=true;
											 this.router.navigate('mobile/train');
											 globalVars['learning']=false;
								       break;
							     } case 'settings': {
											this.settingWasActive=true;
											  globalVars['setting'] = {
                            func:GenericSlideCountTrain ,
                            route:'mobile/tests/adding-count'
                          };

											 this.router.navigate('mobile/setting-train');
							     }
			    		}
						 })
         );

        this.subscriptions.push(
              this.store
                .select('genericReducer')
                .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                .subscribe((data: GenericDataUnit) => {
	               	if (data.action==GenericDataUnitEnum.die) {
	                    this.die=true;
	                    return;
	                  }
										if (data.action==GenericDataUnitEnum.timerReset) {
											    this.tbuttons.reset();
		                    return;
		                  }
                  if (data.action==GenericDataUnitEnum.settingDone) {
                    this.istate();
                    // this.slickModal.slickPrev();
                    this.settingState=SettingActiveEnum.idle;
										 this.settingWasActive=false;
									    if (this.cardState==CardsStateEnum.drawn) {
									       // BottomAppleModify(this.store, {enable:['forward']});
									    }
                  }
                }
              )
         );
		 setTimeout(() => {
			 this.draw();
		 },100);
  }

  getCount() :any {
		return this.netcount?  this.dealerService.getReducedCount(): this.dealerService.getCount();
  }


  draw() :void {
		if (this.firstDraw) {
			this.dealerService.shuffle();
		}
		GenericMultiDrawCards(this.store);
    this.firstDraw=this.continuous? false : true;
  }

}
