import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ANIMATE_ON_ROUTE_ENTER } from '@app/core';
import { timer } from 'rxjs';
import {MobileSendKeyRoute, BottomAppleModify} from '../store/bottom.action.reducer';
// import {GenericResetAction, GenericConfigAction,GenericTopTopAction, GenericDieSlaveAction} from '../store/generic.action.reducer';
import {GenericResetAction, GenericConfigActionEnable, GenericConfigActionDisable, GenericTopTopAction, GenericDieSlaveAction,GenericLowUpArrowPressed} from '../store/generic.action.reducer';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import {imageDims, MobileRouterService, globalVars} from '../../services/global.service';
import {mainBottomSwitchActionNone} from '../store/bottom.action.reducer';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import {  map, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';



@Component({
  selector: 'anms-mobile-about',
  templateUrl: './about.component.html',
  animations: [
    CommonListAnimations.blist
  ]
})
export class AboutComponent implements OnInit, OnDestroy {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  count: number;
  subscriptions= [];
  //['auto', 'video-base', 'video-cardcount', 'video-scale']
  mode='manual';
  // mode="auto";

   mouseclass=[];

  size={auto:5, manual:8};

  demoPage=6;
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };
 top=300;
 // video=false;
 images={
   num: {
     low:'Five',
     high: 'Five',
     ignored: 'Three'
   },
   low:[
   '/assets/cards/2C',
   '/assets/cards/3D',
   '/assets/cards/4H',
   '/assets/cards/5S',
   '/assets/cards/6C'
  ],
  high:['/assets/cards/10C',
        '/assets/cards/JD',
        '/assets/cards/QH',
        '/assets/cards/KS',
        '/assets/cards/AC'],
  ignored:['/assets/cards/7C',
              '/assets/cards/8D',
              '/assets/cards/9S'],
      };
 hidden_card='/assets/cards/Gray_back_80x100.png';
 vimages={
    1: ['/assets/cards/10H_100x120.png', '/assets/cards/KH_100x120.png'],
    2: ['/assets/cards/6H_100x120.png', '/assets/cards/3S_100x120.png'],
    3: ['/assets/cards/10H_100x120.png', '/assets/cards/6H_100x120.png'],
    4: ['/assets/cards/4H_100x120.png', '/assets/cards/JH_100x120.png'],

 };

 disabled={next:false, backward:true};
  mobile='';
  current={page:0, dir:'up'};
  classes=[];
  die=false;
  slaveDie=false;
  landing_index = 0;
  state="None"
  // toptopEnable={1:true, 2: true, 3: true, 4: true};
  button='start';
  delay=1200;
  page=0;
  interval:any;
  columns=['first-card', 'second-card', 'likelihood'];
  pageEnabled = [true, false, false, false, false, false, false, false, false, false];
  sources=[
    {'first-card': 'high-card', 'second-card': 'high-card', likelihood: '25%'},
    {'first-card': 'low-card', 'second-card': 'low-card', likelihood: '25%'},
    {'first-card': 'high-card', 'second-card': 'low-card*', likelihood: '25%'},
    {'first-card': 'low-card', 'second-card': 'high-card*', likelihood: '25%'},

  ];
  scale={enable:false, img:'', left:0};
  // list=['Card counting concept', 'Three variations of card counting','Train yourself at your own pace','Test yourself','Certification', "online-assist"];
  // list=['Three cards counting courses','Train yourself at your own pace','Get certified','Sign up to get a one day free pass'];
  list=['Two cards counting courses','Train yourself at your own pace','Get certified'];


  gvars=globalVars;

  constructor(private store:Store<any>,private router: MobileRouterService ) {}

  buttonDisabled=false;
  cells=false;
  demoDelay=20000;
  factors=[
          "Most player's betting strategy is unreasonable",
          "Dealer's hand are not exposed",
          "Players are first to make a mistake",
          "Players are not properly trained to make a right decision",
          "The emotional toll caused by losses",
          "The house has an unlimited chips, unlike players"
  ];
  fheader=['Blackjack challenge', '25 vs 50 percent odds', 'Lets Make it fair'];
  flaw=['First rule of blackjack','High cards and low cards are balanced', 'Five on each side'];
  slaw=['Second rule of blackjack','Dont panic when dealer face card is a 10', 'Hidden card high/low 50%'];
  cmsg = { // card messages
    0: ['First rule:','High cards and low cards are balanced', 'Five on each side'],
    1: ['Only high and low cards'],
    2: ['2 unique combinations', ['2 non-unique combinations']],
    3: ['3 unique combinations', 'Mix cards 50%'],
    4: ['Prediction overview', "Balanced low and high cards"]
  };

  headers_1 = [
     "Your odds of winning is at 25%",
     "Guessing the dealer's hidden card",
     "You are the first to make a decision",
     "Majoirty of the first 2 cards dealt are mixture of high and low cards(12-16)",
     "Running out of money before the house"
  ];
  headers = [
     "Player odds at 25% versus the house at 75%",
     "Not understanding the cards",
     "Guessing game, too many decisions to be made",
     "Too many distractions"
  ];

  highCards=5;
  lowCards=5;
  v2clicked=0;

  GenericConfigAction(store, index) {
    const start = this.mode == "auto"? 1: 5;
    for(let i=start; i!=start+4;i++) { // disable others except the index
      if (index != i) GenericConfigActionDisable(store, `${i}`);
    }
    GenericConfigActionEnable(store, `${index}`);

  }

  istart(delay=10000) {// initial start
       this.GenericConfigAction(this.store, '1');
        setTimeout(() => {
              this.pageEnabled[this.page] = false;
              this.start();
          }, delay);
  }

  mstart( key) {
    if (key == 'forward') {
      this.page += 1;
      this.pageEnabled[this.page] = true;
      this.pageEnabled[this.page-1] = false;
    } else {
      this.page -= 1;
      this.pageEnabled[this.page] = true;
      this.pageEnabled[this.page+1] = false;
    }

    if ( this.page == this.size['manual']) {
           this.page = 0;
           this.pageEnabled = [true, false, false, true, false, false, false, false, false, false];
           return;
    }

    setTimeout(() => {
      if (this.page > 2) {
          this.GenericConfigAction(this.store, `${this.page-3}`);
        }
      this.slickModal.slickGoTo(this.page);
    }, 1000);

  }

  start() {
   this.interval= setInterval( ()=> {
     this.page+=1;
     if ( this.page==(this.size['auto'])) {
          this.page = 0;
          clearInterval(this.interval);
          return;
     }
     this.GenericConfigAction(this.store, `${this.page+1}`);
     if (this.slickModal) {
       this.slickModal.slickGoTo(this.page);
     }
   },
    this.demoDelay);
  }
  afterChange(e) {}
  bclicked(key) {
    if (key=='demo') {
       this.GenericConfigAction(this.store, '1');
      this.slickModal.slickGoTo(0);
      GenericResetAction(this.store);
      setTimeout(() => {this.start();}, 15000);
      return;
    }
      this.router.navigate('signin');
  }

  clicked(button) {

    switch( this.mode) {
      case 'auto': {
        this.istart();
        return;
      }
      case 'manual': {
        this.mstart(button);
        return;
      }
    }

  }

  mouse(i,event) {
    this.mouseclass[i]=event;
  }

  ngOnInit():void {
      globalVars['page']='AboutComponent';
      // MobileSendKeyRoute(this.store,[
      //   {key:"backward", disabled: true},
      //   {key:"forward", disabled: true},
      //   {key:"up", disabled: true},
      //   {key:"down", disabled: true}
      // ]);
      imageDims();
      for (const e of this.factors) {
        this.mouseclass.push('leave');
      }
      this.top=globalVars['desktop']? 300: 250;
      mainBottomSwitchActionNone(this.store);
      const image=globalVars['image'];
      for (const key in this.images) {
        if (key=='num') continue;
        this.images[key] = this.images[key].map(img=> `${img}${image.dims}.png`);
      }
      this.mobile=globalVars['mobileDevice'];
      // this._class=this.mobile?"use-all-space":"flex-row margin-side-10p aws-margin-top aws-container-height";
      this.classes.push(this.mobile?"use-all-space":"flex-row margin-side-10p  aws-container-height");
      this.classes.push(this.mobile?"":"margin-side-10p");
      if (this.mode == 'manual') {
        BottomAppleModify(this.store, {switch:'apple', enable:['forward', 'backward']});
      }

       this.subscriptions.push(
             this.store
               .select('genericReducer')
               .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
               .subscribe((data: GenericDataUnit) => {
                 switch(data.action) {
                   case GenericDataUnitEnum.greset : {
                     GenericResetAction(this.store);
                     break;
                   }
                   case GenericDataUnitEnum.die : {
                     this.die=true;
                     break;
                   }
                 }
               }
             )
        );
         if (this.mode=='video-first') {
           GenericTopTopAction(this.store, this.fheader);
         }
         if (this.mode=='auto') {
           this.istart();
         }
         // else {
         //   this.mstart();
         // }
         this.subscriptions.push(
             this.store
               .select('mobileTopBottom')
               .pipe(filter(data => data.typeof=== 'KeyPressedDm' ))
               .subscribe((data: any) => {
                 if (data.target=='parent') {
                    this.die=true;
                    return;
                 }
                 // if (this.mode=='auto') {
                 //   this.manual = true;
                 // }
                 this.clicked(data.key);
               })
           );


         // enable the landingpage-0
  }

  ngOnDestroy():void {
     for(const subscript of this.subscriptions) {
        subscript.unsubscribe();
     }
     clearInterval(this.interval);

  }
}
