
<div class="scene">
   <div class="flip"  [@cardFlip]="state">
        <div class="face back" style="cursor:pointer">
          <a (click)="flipit()" >
            <mat-card  class="red-bg center ">
               {{data.back.stmt[1]}}
            </mat-card>
          </a>
        </div>
        <div class="face front" >
          <a (click)="flipit()" style="cursor:pointer">
                  <mat-card  class="{{fclass}} center" >  {{data.front.title}} </mat-card>
          </a>
        </div>
   </div>
</div>
